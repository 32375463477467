<template>
    <div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="layout">
        <div class="layout_content">
            <div class="chatIcon_list">
                <div class="chatIcon_list_item">
                    <div class="chatIcon_list_item_head">
                        <img :src="info?.user?.avatar" alt="avatar" />
                        <span class="head_name">{{ info?.user?.nickname }}</span>
                        <span class="head_time">{{ info?.create_time }}</span>
                    </div>
                    <div class="chatIcon_list_item_info">
                        <span>{{ info?.title }}</span>
                        <span>{{ info?.description }}</span>
                    </div>
                    <div class="chatIcon_list_item_video">
<!--                        <video :src="info?.video_path" controls></video>-->
                        <div style="padding: 10px;" v-if="isAudioFile">
                            <audio controls style="width: 100%;">
                                <source :src="AudioUrl" type="audio/mp3">
                            </audio>
                        </div>
                        <div id="mse"></div>
                    </div>
                    <div class="chatIcon_list_item_pj" v-if="commentList && commentList.length > 0">
                        <div class="chatIcon_list_item_pj_item" v-for="(item, index) in commentList" :key="index">
                            <div v-if="item.active" style="background-color: #fff;padding: 6px;">
                                <div class="chatIcon_list_item_pj_item_pl" @click.stop>
                                    <div class="item_pl_user" style="display: flex;justify-content: space-between;color:#000">
                                        <span>@{{item.user.nickname}}</span>
                                        <span style="cursor: pointer" @click="closeComment(item)">x</span>
                                    </div>
                                    <div class="item_pl_content">
                                        <textarea class="item_pl_content_textarea pltextarea" v-model="item.text" rows="1" placeholder="请输入内容" @input="adjustTextareaHeight('aaaaaa'+item.id)" :id="'aaaaaa'+item.id"></textarea>
                                    </div>
                                    <div class="item_pl_caozuo" style="cursor: pointer">
                                        <el-popover
                                            placement="bottom"
                                            title="请选择表情"
                                            :width="300"
                                            trigger="click">
                                            <template #reference>
                                                <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                                            </template>
                                            <chatIcon @changeText="getIconChild"/>
                                        </el-popover>
                                        <imageContent :content="item.text" :obj="item.id" :type="index" @result="imageContentResult" @change="handleImageSelected"></imageContent>
                                        <div class="item_pl_caozuo_btn" @click="sendChildComment(item,1)">发送</div>
                                    </div>
                                </div>
                                <div v-if="item.comment_image && item.comment_image.length>0" class="comment-image-box">
                                    <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in item.comment_image">
                                        <img style="width: 80px;height: 80px;" :src="imgitem">
                                        <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg1(index,item.comment_image)">
                                    </div>
                                </div>
                            </div>
                            <div class="chatIcon_list_item_pj_item_top">
                                <div class="pj_item_top_user">
                                    <img :src="item.user.avatar" alt="" />
                                    <span>{{ item.user.nickname }}的评论</span>
                                </div>
                                <div class="pj_item_top_time">
                                    <div class="pj_item_top_time_a" @click="handleMouseEnter(index)">
                                        <img src="@/assets/pl@2x.png" alt="" />
                                        <span>评论</span>
                                    </div>
                                    <div class="pj_item_top_time_a" v-if="item.is_author === 1" @click.stop="deleteComment(item.id)">
                                        <span>删除</span>
                                    </div>
                                    <div class="pj_item_top_time_a" v-if="item.is_author === 1" @click.stop="updaComment(item)">
                                        <span>修改</span>
                                    </div>
                                    <div class="pj_item_top_time_b">{{ item.createtime }}</div>
                                </div>
                            </div>
                            <div class="chatIcon_list_item_pj_item_bottom">
                                <div v-html="handleMktoHtml(item.content)" style="font-size: 14px" class="comment-box"></div>
                                <span></span>
                            </div>
                            <div class="chatIcon_list_item_pj_item_list" v-if="pidnum===item.id">
                                <div class="chatIcon_list_item_pj_item_list_item" v-for="(child, s) in childernComment" :key="s">
                                    <!-- 二级评论回复弹窗 -->
                                    <div style="background-color: #fff" v-if="child.active">
                                        <div class="chatIcon_list_item_pj_item_pl">
                                            <div class="item_pl_user" style="padding: 6px;color:#000;display: flex;justify-content: space-between;">
                                                <span>@{{ item.user.nickname }}</span>
                                                <span style="cursor: pointer" @click="child.active = false">x</span>
                                            </div>
                                            <div class="item_pl_content">
                                                <textarea class="item_pl_content_textarea pltextarea" v-model="child.text" rows="1" placeholder="请输入内容" :id="'bbbbbb'+child.id" @input="adjustTextareaHeight('bbbbbb'+child.id)"></textarea>
                                            </div>
                                            <div class="item_pl_caozuo" style="cursor: pointer;margin-top: 5px;">
                                                <el-popover
                                                    placement="bottom"
                                                    title="请选择表情"
                                                    :width="300"
                                                    trigger="click">
                                                    <template #reference>
                                                        <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                                                    </template>
                                                    <chatIcon @changeText="e => getIconChild1(e, s)"/>
                                                </el-popover>
                                                <imageContent :content="child.text" :obj="s" :type="index" @result="imageTwoContentResult" @change="handleImageSelected"></imageContent>
                                                <div class="item_pl_caozuo_btn" @click="sendChildComment(child,2)">发送</div>
                                            </div>
                                        </div>
                                        <div v-if="child.comment_image && child.comment_image.length>0" class="comment-image-box">
                                            <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index1) in child.comment_image">
                                                <img style="width: 80px;height: 80px;" :src="imgitem">
                                                <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg1(index1,child.comment_image)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chatIcon_list_item_pj_item_top" style="margin-left: 10px;">
                                        <div class="pj_item_top_user">
                                            <img :src="child?.user?.avatar" alt=""/>
                                            <span>{{ child?.user?.nickname }}的评论</span>
                                        </div>
                                        <div class="pj_item_top_time" style="margin-right: 20px;">
                                            <div class="pj_item_top_time_a" @click.stop="handleMouseEnter1(s)">
                                                <img src="@/assets/pl@2x.png" alt=""/>
                                                <span>评论</span>
                                            </div>
                                            <div class="pj_item_top_time_a" v-if="child.is_author === 1" @click.stop="deleteComment(child.id)">
                                                <span>删除</span>
                                            </div>
                                            <div class="pj_item_top_time_a" v-if="item.is_author === 1" @click.stop="updaComment(child)">
                                                <span>修改</span>
                                            </div>
                                            <div class="pj_item_top_time_b">{{ child?.createtime }}</div>
                                        </div>
                                    </div>
                                    <div class="chatIcon_list_item_pj_item_bottom" style="margin-left: 20px;">
                                        <div v-html="handleMktoHtml(child?.content)" style="font-size: 14px" class="comment-box"></div>
                                    </div>
                                    <div style="margin-left: 30px;" v-if="child.is_children > 0" v-for="(childs, ss) in child.children" :key="ss">
                                        <div class="chatIcon_list_item_pj_item_top" style="margin-top: 10px;margin-bottom: 10px;">
                                            <div class="pj_item_top_user">
                                                <img :src="childs?.user?.avatar" alt=""/>
                                                <span>{{ childs?.user?.nickname }}的评论</span>
                                            </div>
                                            <div class="pj_item_top_time" style="margin-right: 20px;">
                                                <div class="pj_item_top_time_a" @click.stop="handleMouseEnter1(s)">
                                                    <img src="@/assets/pl@2x.png" alt=""/>
                                                    <span>评论</span>
                                                </div>
                                                <div class="pj_item_top_time_a" v-if="childs.is_author === 1" @click.stop="deleteComment(childs.id)">
                                                    <span>删除</span>
                                                </div>
                                                <div class="pj_item_top_time_a" v-if="childs.is_author === 1" @click.stop="updaComment(childs)">
                                                    <span>修改</span>
                                                </div>
                                                <div class="pj_item_top_time_b">{{ childs?.createtime }}</div>
                                            </div>
                                        </div>
                                        <div class="chatIcon_list_item_pj_item_bottom">
                                            <div v-html="handleMktoHtml(childs?.content)" style="font-size: 14px" class="comment-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chatIcon_list_item_pj_more" style="cursor: pointer"
                                 @click="getCommentList(item.aid, item.id, item.id)" v-if="item.children_count>0">
                                展开查看更多 >
                            </div>
                        </div>
                        <div style="cursor: pointer" @click="getCommentList_top(info?.id, 0, 0)" v-if="commentList.length > 9 ">查看更多评论</div>
                    </div>
                    <div class="chatIcon_list_item_footer">
                        <div class="chatIcon_list_item_footer_left">
<!--                            <div class="chatIcon_list_item_footer_left_item" @click="Like()">-->
<!--                                <img :src="info?.is_like == 1 ? require('@/assets/dianz.png') : require('@/assets/zz.png')" alt="" />-->
<!--                                <span>{{ info?.post_like }}</span>-->
<!--                            </div>-->
                            <div class="chatIcon_list_item_footer_left_item" @click="collect()">
<!--                                <img :src="info?.is_favorite == 1 ? require('@/assets/shouc.png') : require('@/assets/sc.png')" alt="" />-->
                                <img :src="info?.is_favorite == 1 ? require('@/assets/dianz.png') : require('@/assets/zz.png')" alt="" />
                                <span>点赞</span>
                            </div>
                            <el-popover
                                :width="150"
                                trigger="hover">
                                <template #reference>
                                    <div class="chatIcon_list_item_footer_left_item">
                                        <img src="@/assets/zf.png" alt="" />
                                        <span>转发</span>
                                    </div>
                                </template>
                                <div style="width: 100%; display: flex; align-items: center;">
                                    <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;" @click="shareWeChat">
                                        <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt="" />
                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>
                                    </div>
                                    <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;" @click="shareQQ">
                                        <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt="" />
                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>
                                    </div>
                                    <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;" @click="shareMicroBlog">
                                        <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt="" />
                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>
                                    </div>
                                </div>
                            </el-popover>
                            <div class="chatIcon_list_item_footer_left_item">
                                <img src="@/assets/pl.png" alt="" />
                                <span>评论({{info?.comment_count}})</span>
                            </div>
                        </div>
                    </div>
                    <div class="chatIcon_list_item_pj_item_pl" @click.stop style="margin-top: 10px;">
                        <div class="item_pl_content">
                              <textarea class="item_pl_content_textarea pltextarea" v-model="from.text" rows="1" placeholder="欢迎评论" @input="adjustTextareaHeight('aaaaaa')" id='aaaaaa'></textarea>
                        </div>
                        <div class="item_pl_caozuo" style="cursor: pointer">
                            <el-popover
                                placement="bottom"
                                title="请选择表情"
                                :width="300"
                                trigger="click">
                                <template #reference>
                                    <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                                </template>
                                <chatIcon @changeText="getIcon"/>
                            </el-popover>
                            <imageContent :content="from.text" @result="imageContentResult1" @change="handleImageSelected"></imageContent>
                            <div class="item_pl_caozuo_btn" @click="sendCommentVal(info?.videoInfo?.id,from.text,info?.videoInfo)">发送</div>
                        </div>
                    </div>
                    <div v-if="comment_image && comment_image.length>0" class="comment-image-box">
                        <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in comment_image">
                            <img style="width: 80px;height: 80px;" :src="imgitem">
                            <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg(index)">
                        </div>
                    </div>
                </div>
            </div>
            <div style="background-color: #fff;position: fixed;top:50%;left:50%;transform: translate(-50%, -50%);width: 900px;" v-if="aaa">
                <div class="item_pl_user" style="display: flex;justify-content: space-between;color: #000;padding: 10px;">
                    <span> 修改内容 </span>
                    <span style="cursor: pointer" @click.stop="closeComment1()">X</span>
                </div>
                <div class="chatIcon_list_item_pj_item_pl" @click.stop style="margin-left:8px;">
                    <div class="item_pl_content">
                    <textarea class="item_pl_content_textarea pltextarea"
                              v-model="update_text"
                              rows="1" placeholder="请输入内容" @input="adjustTextareaHeight('cccccc')"
                              id='cccccc'></textarea>
                    </div>
                    <div class="item_pl_caozuo" style="cursor: pointer">
                        <el-popover
                            placement="bottom"
                            title="请选择表情"
                            :width="300"
                            trigger="click">
                            <template #reference>
                                <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                            </template>
                            <chatIcon @changeText="getIcon1"/>
                        </el-popover>
                        <imageContent :content="from.text" @result="imageContentResult2" @change="handleImageSelected"></imageContent>
                        <div class="item_pl_caozuo_btn" @click="updateCommentVal()">修改</div>
                    </div>
                </div>
                <div v-if="update_image && update_image.length>0" class="comment-image-box">
                    <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in update_image">
                        <img style="width: 80px;height: 80px;" :src="imgitem">
                        <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg1(update_image)">
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router';
import { ref, reactive, onMounted } from 'vue';
import {
    show,
    addComment,
    addLive,
    delLive,
    delFavorite,
    addFavorite,
    getComment,
    delComment,
    updateComment
} from '@/api'
import chatIcon from '@/components/chatIcon.vue'
import { ElMessage } from 'element-plus'
import Player from 'xgplayer';
import imageContent from '@/components/imageContent.vue'
import {marked} from "marked";

let loading = ref(false)
let aaa = ref(false)
let update_text = ref('')
let update_image = ref([])
let update_info = ref(0)
let comment_image = ref([])
let childernComment = ref([])
let pages1 = ref(2)
let pages = ref(0)
let pidnum = ref(0)
const router = useRouter()
let isAudioFile = ref(false)
let AudioUrl = ref('')
const adjustTextareaHeight = (id) => {
    const textarea = document.getElementById(id)
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
}
const closeComment1 = (item) => {
    aaa.value = false
}
const imageContentResult1 = (e) => {
    loading.value = false;
    let str = e.type;
    if(comment_image.value.length < 3){
        comment_image.value.push(e.response)
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const imageContentResult = (e) => {
    loading.value = false;
    let str = e.type;
    commentList.value[str].comment_image = commentList.value[str].comment_image ?? [];
    if(commentList.value[str].comment_image.length < 3){
        commentList.value[str].comment_image.push(e.response);
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const imageTwoContentResult = (e) => {
    loading.value = false;
    let str = e.type;
    childernComment.value[e.item].comment_image = childernComment.value[e.item].comment_image ?? [];
    if(childernComment.value[e.item].comment_image.length < 3){
        childernComment.value[e.item].comment_image.push(e.response);
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const handleMktoHtml = (e) => {
    return e !== undefined ? marked(e) : ''
}
const delimg = (e) => {
    comment_image.value.splice(e, 1);
}
const delimg1 = (e,item) => {
    item.splice(e, 1);
}
const imageContentResult2 = (e) => {
    loading.value = false;
    if(update_image.value.length < 3){
        update_image.value.push(e.response)
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const handleImageSelected = (e) => {
    loading.value = true;
}
const getIconChild1 = (e,index) => {
    childernComment.value[index].text += e
}
const deleteComment = (id) => {
    delComment({id:id}).then(res => {
        if(res.code === 1){
            ElMessage({message: res.msg, type: 'success'})
            commentList.value = []
            childernComment.value = []
            data_show()
        }
    })
}
const updateCommentVal = () => {
    let content = update_text.value
    if (update_image.value && update_image.value.length > 0) {
        for (let i = 0; i < update_image.value.length; i++) {
            content += `![Image Description](${update_image.value[i]})`
        }
    }
    updateComment({id:update_info.value,content:content,con:update_text.value,image:update_image.value}).then(res => {
        if(res.code === 1){
            ElMessage({message: res.msg, type: 'success'})
            aaa.value = false
            update_text.value = ''
            update_image.value = []
            update_info.value = 0
            router.go(0);
        }
    })
}
const updaComment = (index) => {
    update_info.value = index.id
    if(index.content_files == ''){
        update_image.value = []
    }else{
        update_image.value = index.content_files.split(',')
    }
    update_text.value = index.content_text

    aaa.value = true
}
const getCommentList = (aid, pid ,top_pid) => {
    let page = 1 + pages.value
    getComment({type:'video', aid, pid, orderway: 'desc', page, limit: 5, top_pid})
        .then(res => {
            if (res.code === 1) {
                pidnum.value = res.data.pid
                if (pid === res.data.pid){
                    pages.value += res.data.current_page
                    const newData = res.data.list;
                    childernComment.value.push(...newData);
                }else{
                    pages.value = 0
                    childernComment.value = res.data.list
                }
            }
        })
}
const getCommentList_top = (aid, pid ,top_pid) => {
    let page = pages1.value ++
    getComment({type:'video', aid, pid, orderway: 'desc', page, limit: 10, top_pid})
        .then(res => {
            if (res.code === 1) {
                // pages.value += 1
                pidnum.value = res.data.pid
                if (page === 1){
                    commentList.value = res.data.list
                }else{
                    const newData = res.data.list;
                    commentList.value.push(...newData)
                }
            }
        })
}
// 分享到QQ
const shareQQ = () => {
    var url = window.location.href;
    var encodedUrl = encodeURIComponent(url);
    var shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?url=' + encodedUrl;
    window.open(shareUrl);
}
// 分享到微博
const shareMicroBlog = () => {
    let message = String(location.href)
    var url = 'https://service.weibo.com/share/share.php?url=' + encodeURIComponent(message) + '&appkey=MrShi&title=测试标题&language=zh_cn'
    window.open(url)
}
//分享到微信
const shareWeChat = () => {
    var url = window.location.href;
    var encodedUrl = encodeURIComponent(url);
    var qrcodeUrl = 'https://login.weixin.qq.com?url=' + encodedUrl;
    window.open(qrcodeUrl)
};

const route = useRoute()

let from = reactive({
    index: 0,
    text: ''
})

let info = ref({})
let commentList = ref([])

let player = ref({});

const handleMouseEnter = (index) => {
    commentList.value.forEach((row, i) => {
        row.text = ''
        row.active = i === index
    })
}
const handleMouseEnter1 = (index) => {
    childernComment.value.forEach((row, i) => {
        row.text = ''
        row.active = i === index
    })
}

// 子级评论选择表情
const getIconChild = (e) => {
    commentList.value[from.index].text += e
}

// 子级评论选择表情
const beforeIconChild = (a) => {
    from.index = a
}

const closeComment = (item) => {
    item.active = false
    item.text = ''
}

// 点赞
const Like = () => {
    if (info.value.is_like == 1) {
        delLive({ table_name: 'video', object_id: info.value.id })
            .then(res => {
                if (res.code === 1) {
                    info.value.is_like = 0
                    info.value.post_like -= 1
                }
            })
    } else {
        addLive({ table_name: 'video', object_id: info.value.id })
            .then(res => {
                if (res.code === 1) {
                    info.value.is_like = 1
                    info.value.post_like += 1
                }
            })
    }
}

// 收藏
const collect = () => {
    if (info.value.is_favorite == 1) {
        delFavorite({ table_name: 'video', object_id: info.value.id })
            .then(res => {
                if (res.code === 1) {
                    info.value.is_favorite = 0
                }
            })
    } else {
        addFavorite({ table_name: 'video', object_id: info.value.id })
            .then(res => {
                if (res.code === 1) {
                    info.value.is_favorite = 1
                }
            })
    }
}

// 父级发送评论
const sendCommentVal = () => {
    let content = from.text
    if(content.length > 140){
        ElMessage({message: '字数不能超过140', type: 'error'})
        return
    }
    if (comment_image.value && comment_image.value.length > 0) {
        for (let i = 0; i < comment_image.value.length; i++) {
            content += `![Image Description](${comment_image.value[i]})`
        }
    }
    if (!from.text) return ElMessage({
        message: '请先输入评论内容',
        grouping: true,
        type: 'warning',
    })
    addComment({
        type: 'video',
        aid: info.value.id,
        pid: 0,
        content: content,
        con: from.text,
        image: comment_image.value,
    }).then(res => {
        if (res.code === 1) {
            from.text = ''
            comment_image.value = []
            commentList.value.unshift(res.data)
            data_show()
        }
    })
}

// 父级评论选择表情
const getIcon = (e) => {
    from.text += e
}

// 发送子级评论
const sendChildComment = (item,type) => {
    let content = item.text
    if(content.length > 140){
        ElMessage({message: '字数不能超过140', type: 'error'})
        return
    }
    if (item.comment_image && item.comment_image.length > 0) {
        for (let i = 0; i < item.comment_image.length; i++) {
            content += `![Image Description](${item.comment_image[i]})`
        }
    }
    if (!item.text) return ElMessage({
        message: '请先输入评论内容',
        grouping: true,
        type: 'warning',
    })
    addComment({
        type: 'video',
        aid: item.aid,
        pid: item.id,
        content: content,
        top_pid: item.id,
        con: item.text,
        image: item.comment_image,
    }).then(res => {
        if (res.code === 1) {
            item.text = ''
            item.active = false
            item.comment_image = []
            if(type === 1){
                commentList.value.unshift(res.data)
            }else{
                childernComment.value.unshift(res.data)
            }
            router.go(0);
        }
    })
}

const data_show = (item) => {
    show({ category_type: "video", id: route.query.id})
        .then(res => {
            if (res.code === 1) {
                const val = res.data.videoInfo.video_path
                AudioUrl.value = val
                const audioExtensions = ['.mp3', '.wav', '.ogg', '.flac'];
                const fileExtension = val.slice(val.lastIndexOf('.')).toLowerCase();
                isAudioFile.value = audioExtensions.includes(fileExtension);
                if(!isAudioFile.value){
                    document.getElementById('mse').style.display = 'block';
                    player.value = new Player({
                        id: 'mse',
                        autoplay: true,
                        fluid: true,
                        url: val,
                    })
                }else{
                    document.getElementById('mse').style.display = 'none';
                }
                console.log(isAudioFile.value)
                info.value = res.data.videoInfo
                commentList.value = res.data.commentList
                commentList.value.forEach(item => {
                    item.text = ''
                })
            }
        })
}

const updateTitleAndFavicon = () => {
    document.title = '复盘';
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
        favicon.href = 'https://video-source.obs.cn-north-4.myhuaweicloud.com/img/default_user.jpg';
    }
}
const updateTitleAndFavicon1 = () => {
    document.title = '复盘 - 视频详情';
}

onMounted(() => {
    data_show()
    updateTitleAndFavicon()
    updateTitleAndFavicon1()
})
</script>

<style lang="scss" scoped>
    .layout {
        width: 100%;
        min-width: 975px;
        min-height: 100vh;
        background-color: #0d2e64;
        display: flex;
        justify-content: center;
        position: relative;
        .layout_content {
            width: 975px;
            padding: 20px 0;
            box-sizing: border-box;
            height: auto;
            color: azure;
            .chatIcon_list {
                width: 100%;
                display: flex;
                flex-direction: column;
                background: rgb(11 37 79);
                border-radius: 6px;
                .chatIcon_list_item {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    .chatIcon_list_item_head {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                        .head_name {
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            padding-right: 9px;
                            margin-right: 9px;
                            border-right: 1px solid rgba(255, 255, 255, 0.5);
                        }
                        .head_time {
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: rgba(255, 255, 255, 0.5);
                        }
                    }
                    .chatIcon_list_item_video {
                        width: 100%;
                        //height: 350px;
                        margin-top: 20px;
                        video {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .chatIcon_list_item_info {
                        width: 100%;
                        margin-top: 15px;
                        span {
                            width: 100%;
                            overflow:hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            font-size: 18px;
                            font-family: Source Han Sans CN;
                            // font-weight: bold;
                            &:first-child {
                                color: #FFFFFF;
                            }
                            &:last-child {
                                cursor: pointer;
                                font-size: 13px;
                                margin-top: 10px;
                            }
                        }
                    }
                    .chatIcon_list_item_pj {
                        width: 100%;
                        margin-top: 15px;
                        .chatIcon_list_item_pj_item {
                            width: 100%;
                            padding: 12px 14px;
                            box-sizing: border-box;
                            background: #094074;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 9px;
                            position: relative;
                            &:last-child {
                                margin-bottom: 0 !important;
                            }
                            .chatIcon_list_item_pj_item_top {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .pj_item_top_user {
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        margin-right: 6px;
                                    }
                                    span {
                                        font-size: 13px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 500;
                                        color: #FFFFFF;
                                    }
                                }
                                .pj_item_top_time {
                                    display: flex;
                                    align-items: center;
                                    .pj_item_top_time_a {
                                        display: flex;
                                        align-items: center;
                                        padding-right: 6px;
                                        margin-right: 6px;
                                        cursor: pointer;
                                        border-right: 2px solid rgba(255, 255, 255, 0.3);
                                        img {
                                            width: 13px;
                                            height: 13px;
                                            margin-right: 3px;
                                        }
                                        span {
                                            font-size: 13px;
                                            font-family: Source Han Sans CN;
                                            font-weight: 400;
                                            color: #FCB407;
                                        }
                                    }
                                    .pj_item_top_time_b {
                                        font-size: 13px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 500;
                                        color: #FFFFFF;
                                    }
                                }
                            }
                            .chatIcon_list_item_pj_item_bottom {
                                margin-top: 15px;
                                span {
                                    font-size: 14px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    line-height: 16px;
                                    &:first-child {
                                        color: #FFFFFF;
                                    }
                                    &:last-child {
                                        cursor: pointer;
                                        color: rgba(7, 243, 252, 1);
                                    }
                                }
                            }
                        }
                    }
                    .chatIcon_list_item_footer {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 17px;
                        cursor: auto;
                        .chatIcon_list_item_footer_left {
                            width: 280px;
                            display: flex;
                            align-items: center;
                            .chatIcon_list_item_footer_left_item {
                                flex: 1;
                                display: flex;
                                align-items: center;
                                vertical-align: middle;
                                cursor: pointer;
                                img {
                                    width: 15px;
                                    height: 14px;
                                    margin-right: 4px;
                                }
                                span {
                                    font-size: 13px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                }
                            }
                        }
                        .chatIcon_list_item_footer_right {
                            width: 350px;
                            height: 30px;
                            padding: 0 13px 0 13px;
                            box-sizing: border-box;
                            background: #EAF5FF;
                            border-radius: 11px;
                            margin-left: 21px;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            .inputText {
                                flex: 1;
                                height: 100%;
                                input::-webkit-input-placeholder {
                                    font-size: 14px;
                                    font-family: Source Han Sans CN;
                                    font-weight: bold;
                                    color: #0361BA;
                                }
                                input {
                                    font-size: 14px;
                                    color: #222222;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(0,0,0,0);
                                    border: none;
                                    outline: none;
                                }
                            }
                            .caozuo {
                                flex-shrink: 0;
                                display: flex;
                                align-items: center;
                                img {
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 8px;
                                    cursor: pointer;
                                }
                                .caozuo_btn {
                                    width: 45px;
                                    height: 22px;
                                    line-height: 20px;
                                    text-align: center;
                                    background: linear-gradient(0deg, #085EAF, #2791F4);
                                    border-radius: 10px;
                                    font-size: 9px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .chatIcon_list_item_pj_item_pl {
        width: 100%;
        position: relative;

        .item_pl_content {
            width: 100%;

            textarea {
                width: 95%;
                border-radius: 30px;
                height: 15px;
                //margin-top: 20px;
                padding: 6px 10px;
                resize: none;
                background: #ebf4fd;
                color: #709cd2;
            }

            textarea:focus {
                outline: none;
            }
        }

        .item_pl_caozuo {
            display: flex;
            position: absolute;
            bottom: 15%;
            right: 3%;

            .item_pl_caozuo_img {
                width: 21px;
                height: 21px;
            }

            .item_pl_caozuo_btn {
                width: 42px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                background: linear-gradient(0deg, #085EAF, #2791F4);
                border-radius: 10px;
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
</style>
