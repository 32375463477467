<template>
  <div class="chatIcon">
    <!-- 刷新 -->
    <div class="chatIcon_head">
      <div class="chatIcon_head_left">
        <img src="@/assets/jian@2x.png" alt=""/>
          <span v-if="title_show === 2" style="cursor: pointer" @click="title_fun">{{title_text}}</span>
          <span v-else>
            <span style="cursor: pointer;" v-if="props.channel == 4" @click="title_fun">{{ props.title }}</span>
            <span v-else>
                <span style="cursor: pointer;" @click="title_fun">{{ props.title.slice(0, 4) }}</span>
                -
                <span style="cursor: pointer;" @click="getVideoList('rem')">{{ props.title1 }}</span>
            </span>
          </span>
      </div>
      <div class="chatIcon_head_right">
        <span><el-countdown :value="props.timeVal" @finish="finish"/>后自动更新</span>
        <div class="chatIcon_head_right_btn" @click="getVideoList('rem')">
          <img src="@/assets/update@2x.png" alt=""/>
          <span>手动更新</span>
        </div>
      </div>
    </div>

      <div v-if="postLists.length == 0" style="height: 260px;width: 100%;background: rgb(13, 46, 100);margin-top: 10px;overflow-y: auto;font-size: 16px;text-align: center;line-height: 260px;">
          暂无说明
      </div>
      <div ref="scrollContainer" v-else style="height: 500px;width: 100%;overflow-y: auto;">
        <div class="gundong" style="width: 100%;background: rgb(13, 46, 100);margin-top: 10px;" v-for="(item,index) in postLists" :key="index">
            <div style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;">{{ item.createtime }}</div>
            <div :ref="el => setPostContentRef(el, index)" :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }" style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;" v-html="item.content"></div>

            <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
                <text v-if="expandStates[index]" style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;"
                      @click="expand(index, $event.target)">展开
                </text>
            </div>

            <div v-if="item.file_img.length > 0" style="height: 150px;overflow: hidden;position: relative;padding: 10px;">
                <div v-for="(img, i) in item.file_img" :key="i" style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;">
                    <img :src="img" :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'cover' }" alt="" @click="openPreview1(i,item.file_img)">
                </div>
                <img v-if="item.file_img.length >= 3" @click="jumpDetails(item)" alt="" src="@/assets/jia.png" style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;">
                <div v-if="showPreview1" class="modal" @click="closePreview1">
                    <div class="modal-content">
                        <span class="close" @click="closePreview1">&times;</span>
                        <img :src="images11" alt="Preview" style="object-fit: contain;"/>
                    </div>
                </div>
            </div>

            <div v-if="item.file_video.length > 0" style="width: 75%;margin: auto;">
                <div v-for="(img, i) in item.file_video" :key="i">
                    <video :src="img" controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 300px;"></video>
                </div>
            </div>

            <div v-if="item.file_data.length > 0" style="padding: 10px;color: #26c3dd">
                <div v-for="(img, i) in item.file_data" :key="i">
                    <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                </div>
            </div>

            <div v-if="item.file_audio.length > 0" style="margin: 10px;">
                <div v-for="(img, i) in item.file_audio" :key="i">
                    <audio controls style="width: 100%;">
                        <source :src="img" type="audio/mp3">
                    </audio>
                </div>
            </div>

        </div>
      </div>

    <div v-if="searchtype !== 2" style="margin-top: 10px;">
      <div style="width: 100%;float: left">
        <div class="aaa"
             style="width: 46%;height: 180px;background-color: #023C67;display: inline-block;margin: 5px 2%;border-radius: 10px;cursor: pointer"
             v-for="(item, index) in from.listinfo" :key="index">
          <img class="image" style="width: 95%;height: 130px;margin-left: 2.5%;margin-top: 5px;border-radius: 10px;object-fit:cover"
               :src="item.cover_image" alt="" @click="switchs(item.id,item.is_member_expire)"/>
          <div style="display: flex;align-items: center;padding-left: 15px;padding-top: 10px;">
            <img class="play" style="width: 20px;height: 20px;" src="@/assets/sharpicons_present.png" alt=""/>
            <span style="padding-left: 10px;">{{ item.name }}</span>
          </div>
        </div>

        <div id="bbb" style="display: none;">
          <div style="width: 98%;background-color: #023C67;display: inline-block;margin: 5px 2%;border-radius: 10px;">
            <img id="image_mse" class="image"
                 style="width: 95%;height: 253px;margin-left: 2.5%;margin-top: 10px;border-radius: 10px;"
                 :src="introducedata.cover_image" alt=""/>

              <div style="padding: 10px;" v-if="isAudioFile">
                  <audio controls style="width: 100%;">
                      <source :src="AudioUrl" type="audio/mp3">
                  </audio>
              </div>
              <div id="mse" style="height: 320px;"></div>

            <div style="display: flex;align-items: center;justify-content: space-between;padding-left: 15px;padding-top: 10px;">
                <div style="display: flex;align-items: center;">
                    <img class="play" style="width: 20px;height: 20px;" src="@/assets/sharpicons_present.png" alt=""/>
                    <text style="padding-left: 10px;width: 150px;">{{ introducedata.name }}</text>
                </div>
                <div style="display: flex;justify-content: flex-end;align-items: center;padding-right: 30px;">
                    <div>
                        <div style="width: 65px;height: 30px;border-radius: 5px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-left: 18%;"
                             @click="del_collection(introducedata.id,1,introducedata,'album')" v-if="is_collection===1">
                            <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/dianz.png" alt=""/>
                            <div style="padding-left: 5px;width: 60px;"> 点赞</div>
                        </div>
                        <div style="width: 65px;height: 30px;border-radius: 5px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-left: 18%;"
                             @click="album_collection(introducedata.id,1,introducedata,'album')" v-else>
                            <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/zz.png" alt=""/>
                            <div style="padding-left: 5px;width: 60px;"> 点赞</div>
                        </div>
                    </div>

<!--                    <div style="width: 2px;height: 20px;background-color: #628FB1;color:#023C67;margin-left: 15px;">1 </div>-->
<!--                    <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-left: 7px;">-->
<!--                        <el-popover-->
<!--                                :width="150"-->
<!--                                trigger="hover">-->
<!--                            <template #reference>-->
<!--                                <div class="chatIcon_list_item_footer_left_item" style="display: flex;align-items: center;">-->
<!--                                    <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/share.png"-->
<!--                                         alt=""/>-->
<!--                                    <div style="padding-left: 5px;width: 30px;">分享</div>-->
<!--                                </div>-->
<!--                            </template>-->
<!--                            <div @click="shareWeChat" style="width: 100%; display: flex; align-items: center;">-->
<!--                                <div-->
<!--                                        style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                    <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt=""/>-->
<!--                                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>-->
<!--                                </div>-->
<!--                                <div @click="shareQQ"-->
<!--                                     style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                    <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt=""/>-->
<!--                                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>-->
<!--                                </div>-->
<!--                                <div @click="shareMicroBlog"-->
<!--                                     style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                    <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt=""/>-->
<!--                                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </el-popover>-->
<!--                    </div>-->
                </div>
            </div>
          </div>

          <div style="width: 80%;height: 50px;margin: 5px 2%;display: flex;justify-content:space-between;">
            <div id="mulu"
                 style="width: 80px;height: 30px;background-color: #0D67BB;border-radius: 5px;text-align: center;line-height:30px;cursor: pointer"
                 @click="directory">
                <text> 课程目录</text>
            </div>
            <div id="jieshao"
                 style="width: 80px;height: 30px;background-color: #0B3D6C;border-radius: 5px;text-align: center;line-height:30px;cursor: pointer"
                 @click="introduce">
              <text> 课程介绍</text>
            </div>
            <div id="pinglun"
                 style="width: 120px;height: 30px;background-color: #0B3D6C;border-radius: 5px;text-align: center;line-height:30px;cursor: pointer"
                 @click="comment1">
              <text> 用户评论({{ commentnum }})</text>
            </div>
          </div>

          <div id="directory" style="width: 98%;">
                <div style="width: 100%;height: 40px;background-color: #082352;border-radius: 8px;display: flex;padding: 5px;margin-top: 5px;justify-content: space-between;cursor: pointer;"
                     v-for="(item, index) in directorydata" :key="index">
                    <div style="display: flex;justify-content:space-between;align-items: center;"
                         @click="video_info(item.video_path_obs,item.is_member_expire,item.id)">
                        <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/play2.png" alt=""/>
                        <text style="padding-left: 5px;"> {{ item.title }}</text>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: center">
                        <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;" @click="handleMouseEnter(item.id)" v-if="item.rating === 0">
                            <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/evaluate.png" alt=""/>
                            <text style="padding-left: 5px;"> 评价</text>
                        </div>
                        <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;" v-else>
                            <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/evaluate1.png" alt=""/>
                            <text style="padding-left: 5px;color:#5e5b5b"> 评价</text>
                        </div>
                        <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;"
                             @click="del_collection(item.id,2,item,'video')" v-if="item.is_favorite===1">
                            <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/dianz.png" alt=""/>
                            <div style="padding-left: 5px;width: 50px;"> 点赞</div>
                        </div>
                        <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;"
                             @click="album_collection(item.id,2,item,'video')" v-else>
                            <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/zz.png" alt=""/>
                            <div style="padding-left: 5px;width: 50px;"> 点赞</div>
                        </div>
                    </div>
                </div>
                <div
                        style="margin-top: 60px;margin-bottom: 30px;display: flex;justify-content: center; align-items: center;">
                    <el-pagination
                            small
                            background
                            layout="prev, pager, next"
                            :total="videowhichpage"
                            class="mt-4"
                            @currentChange="testfun1"
                    />
                </div>
            </div>
          <div id="introduce" style="width: 98%;margin: 5px 2%;background-color: #0D2E64;border-radius: 10px;display: none;">
            <div
                style="width: 90%;height: 50px;margin: auto;text-align: center;line-height:50px;display: flex;justify-content: space-between;border-bottom: 2px #1E4173 solid">
              <text style="padding: 5px 0 15px;color:#00D5D8;font-weight: bold;font-size: 18px;"> 课程平均评分</text>
              <div>
                <el-rate disabled v-model="introducedata.average_score" size="large"></el-rate>
                <text style="padding: 5px 0 15px;color:#FFD100;font-weight: bold;font-size: 21px;">
                  {{ introducedata.average_score }} 分
                </text>
              </div>
            </div>
            <div style="width: 90%;margin: auto;border-bottom: 2px #1E4173 solid;margin-top: 15px;">
              <text style="padding: 5px 0 15px;color:#00D5D8;font-weight: bold;"> 课程介绍</text>
              <div style="padding: 5px 0 15px;font-weight: bold;">
                <div class="html-box" v-html="introducedata.introduction"></div>
              </div>
            </div>
            <div style="width: 90%;margin: auto;margin-top: 15px;">
              <text style="padding: 5px 0 15px;color:#00D5D8;font-weight: bold;"> 讲师介绍</text>
              <div style="padding: 5px 0 15px;font-weight: bold;">
                <div class="html-box" v-html="introducedata.lecturer_introduction"></div>
              </div>
            </div>
          </div>
          <div id="comment" style="width: 98%;margin: 5px 2%;border-radius: 10px;display: none;">

            <div style="margin-top: 5px;background-color: #0D2E64;border-radius: 5px;" v-for="(item, index) in commentdata" :key="index">
              <div style="width: 90%;height: 20px;padding: 5px;margin-top: 5px;display: flex;align-items: center;justify-content: space-between;">
                <div style="line-height: 20px;display: flex;align-items: center;">
                  <img class="play" style="width: 20px;height: 20px;border-radius: 100px;margin-left: 8px;"
                       :src="item.user.avatar" alt=""/>
                  <text style="padding-left: 5px;"> {{ item.user.username }}</text>
                  <text style="padding-left: 5px;"> {{ item.createtime }}</text>
                </div>
              </div>
              <div style="padding-left: 15px;margin-top: 5px;">
                  <div v-html="handleMktoHtml(item.content)" style="font-size: 14px" class="comment-box"></div>
              </div>
            </div>

              <div class="chatIcon_list_item_pj_item_pl" @click.stop>
                  <div class="item_pl_content">
                    <textarea class="item_pl_content_textarea pltextarea"
                              v-model="comment"
                              rows="1" placeholder="请输入内容" @input="adjustTextareaHeight1"
                              id='aaaaaa'></textarea>
                  </div>
                  <div class="item_pl_caozuo" style="cursor: pointer">
                      <el-popover
                              placement="bottom"
                              title="请选择表情"
                              :width="300"
                              trigger="click">
                          <template #reference>
                              <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                          </template>
                          <chatIcon @changeText="getIcon"/>
                      </el-popover>
                      <imageContent :content="comment" @result="imageContentResult1" @change="handleImageSelected"></imageContent>
                      <div class="item_pl_caozuo_btn" @click="sendChildComment(introducedata.id, comment)">发送</div>
                  </div>
              </div>
              <div v-if="comment_image && comment_image.length>0" class="comment-image-box">
                  <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in comment_image">
                      <img style="width: 80px;height: 80px;" :src="imgitem">
                      <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg(index)">
                  </div>
              </div>
            <div
                style="margin-top: 60px;margin-bottom: 30px;display: flex;justify-content: center; align-items: center;">
              <el-pagination
                  small
                  background
                  layout="prev, pager, next"
                  :total="commentnum"
                  class="mt-4"
                  @currentChange="testfun"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--        <div style="">-->
    <!--            <div class="chatIcon_live">-->
    <!--                <img class="image" src="@/assets/bg.png" alt="" />-->
    <!--                <img class="play" src="@/assets/bf.png" alt="" />-->
    <!--                <div class="chatIcon_live_text">-->
    <!--                    <span>{{ item.title }}</span>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!-- 直播预告 -->

    <!-- 视频列表   v-loading="from.loading" element-loading-background="rgba(0, 0, 0, 0.8)" -->
    <!--        <div class="chatIcon_list">-->
    <!--            <div class="chatIcon_list_left">-->
    <!--                <div class="chatIcon_list_left_item" v-for="(item, index) in from.listinfo" :key="index" @click="jump(item.id)">-->
    <!--                    <div class="chatIcon_list_left_item_img">-->
    <!--                        <img :src="item.thumbnail" alt="" />-->
    <!--                    </div>-->
    <!--                    <div class="chatIcon_list_left_item_wz">-->
    <!--                        {{ item.title }}-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <div v-if="searchtype !== 2">
      <Loading v-if="from.loading"/>
      <notFind v-if="!from.loading && from.listLeft.length === 0"/>
      <div class="info">
        <el-text class="mx-1" type="info" style="color: #02183F">.</el-text>
      </div>
    </div>

  <el-dialog v-model="dialogFormVisible" title="开通会员" width="650">
    <div style="height: 200px;display: flex;z-index: 10;margin-top: 15px;margin-right: 20px;" v-if="paytype==1">
      <div style="width: 20%;height: 200px;border: 2px #0B3D6C solid;background-color: #d3dce6;margin-left: 10px;"
           v-for="(item, index) in vipinfo" :key="index">
        <div style="font-size: 22px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">{{
            item.name
          }}
        </div>
        <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;"
             v-if="pay_status === 1">
          <text style="color: red;font-size: 22px">{{ item.membership_price }}</text>
          ￥
        </div>
        <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-else>
          <text style="color: red;font-size: 22px">{{ item.membership_score }}</text>
        </div>
        <div style="text-align: center;padding-top: 15px">
          <el-button type="primary" v-if="pay_status === 1" @click="dredge(item.id)">开通</el-button>
          <el-button type="primary" v-else @click="dredge1(item.id)">积分开通</el-button>
        </div>
      </div>
    </div>
    <div style="height: 200px;text-align: center;display: flex" v-else>
      <img :src="payimg" style="width: 200px;height: 200px" alt=""/>
    </div>

    <template #footer>
      <span class="dialog-footer" style="margin-right: 10px;">
          <text style="color: #000000;padding-right: 20px;" v-if="pay_status !== 1">现有积分:{{ userinfo.score }}</text>
        <el-button @click="cancellation()">取消</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogFormVisible1" title="开通会员" width="500">
    <div style="margin-top: 15px;margin-left: 20px;">暂未开通会员,开通会员观看!</div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取消</el-button>
        <el-button type="primary" @click="confirm">
          确认
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogFormVisible3" title="开通会员" width="500">
      <div style="margin-top: 15px;margin-left: 20px;">
        <el-button @click="payment">付费</el-button>
        <el-button @click="exchange">积分兑换</el-button>
        <el-button @click="apply_for">审核申请</el-button>
      </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取消</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogFormVisible2" title="评价" width="300">
    <div style="display: flex;justify-content: center; align-items: center;">
      <el-rate size="large" @change="comment_star"/>
    </div>
  </el-dialog>

  <el-dialog title="提示" v-model="dialogFormVisible4" width="30%" draggable>
        <div>确定要评价{{many_stars}}星吗?</div>
        <div slot="footer" class="dialog-footer"
             style="padding-top: 20px;display: flex;justify-content: center;align-items: center;">
            <el-button @click="dialogFormVisible4 = false">取消</el-button>
            <el-button type="primary" @click="confirmCheck()">确定</el-button>
        </div>
    </el-dialog>

  </div>
</template>

<script setup>
import chatIcon from '@/components/chatIcon.vue'
import imageContent from '@/components/imageContent.vue'
import {
    defineProps,
    defineExpose,
    defineEmits,
    reactive,
    ref,
    onMounted,
    onUnmounted,
    watch,
    nextTick,
    watchEffect
} from 'vue'
import {useRouter} from 'vue-router'
import {
    videoList,
    plaza_post_index,
    getVipList,
    createVip,
    payVip,
    Vipquery,
    getAlbumVideoList,
    getComment,
    addFavorite,
    delFavorite,
    addComment,
    addRating, getHomeList, searchList, scorePay, goodsShow, getUserDetails, postGetList
} from '@/api'
import Loading from '@/components/Loading.vue'
import {downloadobj, timestampToTime} from '@/utils/utils.js'
import notFind from '@/components/notFind.vue'
import Player from 'xgplayer';
import {marked} from "marked";
import {ElMessage} from "element-plus";

const scrollContainer = ref(null);
const dialogFormVisible = ref(false)
const dialogFormVisible1 = ref(false)
const dialogFormVisible3 = ref(false)
const dialogFormVisible4 = ref(false)
const many_stars = ref(0)
const props = defineProps({
  title: String,
  title1: String,
  channel: Number,     // 栏目id
  timeVal: Number,
  create_time: String
})
let title = ref('')
let title1 = ref('')
let titletype = ref(0)
let payimg = ref('')
let paytype = ref(1)
let video_id = ref(1)
let vipinfo = ref([])
let comment_image = ref([])
let introducedata = ref([])
let directorydata = ref([])
let commentdata = ref([])
let commentnum = ref([])
let pay_alid = ref(0)
let pay_al_type = ref(1)
let whichpage = ref([])
let is_collection = ref(1)
let dialogFormVisible2 = ref(false)
let comment = ref('')
let videowhichpage = ref('')
let videopage = ref('')
let order_exchange = ref(0)
let player = ref({})
let userinfo = ref([])
let postLists = ref([])

let investmentList = ref([])
let albumList = ref([])
let liveList = ref([])
let plateList = ref([])
// let videoList = ref([])
let searchtype = ref(1)
let pay_status = ref(1)

let title_show = ref(1)
let title_text = ref('')
let isAudioFile = ref(false)
let AudioUrl = ref('')

let images11 = ref([])
let showPreview1 = ref(false)
const router = useRouter()
const expandStates = reactive(new Array(postLists.value.length).fill(false));
const postContents = ref([]); // 存储 DOM 引用的数组
const emit = defineEmits(['openPreview', 'finish'])

const title_fun = () => {
    updateTitleAndFavicon1('复盘 - 成长计划')
    getVideoList1('rem')
    title_text.value = '成长计划'
    title_show.value = 2
}

const updateTitleAndFavicon1 = (text) => {
    document.title = text;
}

//文件下载
const download = (e) => {
    downloadobj(e)
}
const getOpacity = (index) => {
    return index === 2 ? 0.2 : 1;
}
const openPreview1 = (index,ima) => {
    // console.log(ima)
    //
    // images11.value = ima
    // showPreview1.value = true;
    emit('openPreview', {index,ima})
}
const closePreview1 = () => {
    showPreview1.value = false;
}
// 跳转详情
const jumpDetails = (item) => {
    localStorage.setItem('txt_item', JSON.stringify(item));
    const routeUrl = router.resolve({
        path: "/home/BackendText",
    });
    window.open(routeUrl.href, "_blank");
}

const setPostContentRef = (el, index) => {
    if (el) {
        // 确保数组大小与 items 一致
        postContents.value[index] = el;
    }
};
const expand = (index, el) => {
    if (postContents.value[index].classList.contains('textinfo')) {
        postContents.value[index].classList.remove('textinfo')
        el.textContent = "收起";
    } else {
        postContents.value[index].classList.add('textinfo')
        el.textContent = "展开";
    }
}
const showTextInfo = async (index) => {
    await nextTick();
    let el = postContents.value[index]
    if (el) {
        expandStates[index] = el.scrollHeight > el.offsetHeight
    }
}
watchEffect(() => {
    // 每次 items 改变时，重新设置引用
    if (postLists.value.length > 0) {
        // if(from.list.table_name == 'plate_post'){
        //
        // }
        for (let i = 0; i < postLists.value.length; i++) {
            showTextInfo(i)
        }
    }
});



// const emit = defineEmits(['finish'])
let noMore = ref(false)
let from = reactive({
  listLeft: [],
  listRight: [],
  page: 1,
  title: '',
  create_time: '',
  total: 0,
  loading: true,
  keyword: '',
  sortId: 'desc',
  dateType: ''
})

// 关键字搜索
const getKeyword = (val) => {
  from.title = val
  // getVideoList('rem')
  getNewList3('rem')
}

const adjustTextareaHeight = (id) => {
  // 动态调整textarea高度的方法，可以根据实际需求进行调整
  // 例如，可以根据内容的行数来动态设置高度
  const textarea = document.getElementById('aaaaaa')
  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
}

const getNewList3 = (type) => {
    isAudioFile.value = false
  from.loading = true
  if (type === 'rem') {
    from.list = []
    from.page = 1
    noMore.value = false
  }
  if (noMore.value) return
  searchList({
    page: from.page,
    limit: 20,
    keyword: from.title,
    time: props.create_time ? props.create_time : 0,
  }).then(res => {
    if (res.code === 1) {
      albumList.value = res.data.albumList
      investmentList.value = res.data.investmentList
      liveList.value = res.data.liveList
      // plateList.value = res.data.plateList
      // videoList.value = res.data.videoList
      searchtype.value = 2
      let data = res.data.plateList
      const regex = new RegExp('img', 'gi')
      for (var i = 0; i < res.data.plateList.length; i++) {
        data[i].content = res.data.plateList[i].content
            .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
        data[i].content = res.data.plateList[i].content
            .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
      }
      plateList.value = data

    }
  }).finally(() => {
    from.loading = false
  })
}

// 分享到微博
const shareMicroBlog = () => {
  let message = String(location.href)
  var url = 'https://service.weibo.com/share/share.php?url=' + encodeURIComponent(message) + '&appkey=MrShi&title=测试标题&language=zh_cn'
  window.open(url)
  // count=表示是否显示当前页面被分享数量(1显示)(可选，允许为空)
  // &url=将页面地址转成短域名，并显示在内容文字后面。(可选，允许为空)
  // &appkey=用于发布微博的来源显示，为空则分享的内容来源会显示来自互联网。(可选，允许为空)
  // &title=分享时所示的文字内容，为空则自动抓取分享页面的title值(可选，允许为空)
  // &pic=自定义图片地址，作为微博配图(可选，允许为空)
  // &ralateUid=转发时会@相关的微博账号(可选，允许为空)
  // &language=语言设置(zh_cn|zh_tw)(可选)
}

// 分享到QQ
const shareQQ = () => {
  var url = window.location.href;
  var encodedUrl = encodeURIComponent(url);
  var shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?url=' + encodedUrl;
  window.open(shareUrl);
}

//分享到微信
const shareWeChat = () => {
  var url = window.location.href;
  var encodedUrl = encodeURIComponent(url);
  var qrcodeUrl = 'https://login.weixin.qq.com?url=' + encodedUrl;
  window.open(qrcodeUrl)
};


//视频详情
const video_info = (val,is_member_expire,id) => {
    const audioExtensions = ['.mp3', '.wav', '.ogg', '.flac'];
    const fileExtension = val.slice(val.lastIndexOf('.')).toLowerCase();
    isAudioFile.value = audioExtensions.includes(fileExtension);
    AudioUrl.value = val
  if(is_member_expire == 1){
      dialogFormVisible3.value = true
      pay_alid.value = id
      pay_al_type.value = 3
      return
  }
  document.getElementById('image_mse').style.display = 'none';
    console.log(isAudioFile.value)
  if(!isAudioFile.value){
      document.getElementById('mse').style.display = 'block';
      player.value = new Player({
          id: 'mse',
          autoplay: true,
          fluid: true,
          url: val,
      })
  }else{
      document.getElementById('mse').style.display = 'none';
  }
}

const switchs = (val, is_member_expire) => {
  if (is_member_expire !== 1) {
    var elements = document.getElementsByClassName('aaa');
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
    // document.getElementsByClassName('aaa').style.display = 'none';
    document.getElementById('bbb').style.display = 'block';
    getAlbumVideoList({
      album_id: val,
      page: 1,
      limit: 10,
    }).then(res => {
      if (res.code === 1) {
        is_collection.value = res.data.album.is_favorite
        introducedata.value = res.data.album
        directorydata.value = res.data.video_list
        videowhichpage.value = res.data.total
        videopage.value = res.data.current_page
      }
    })
    fun_getComment(val, 10, 1)
  } else {
    dialogFormVisible3.value = true
    pay_alid.value = val
    pay_al_type.value = 2
  }
}

const introduce = (val) => {
  document.getElementById('introduce').style.display = 'block';
  document.getElementById('directory').style.display = 'none';
  document.getElementById('comment').style.display = 'none';
  document.getElementById('jieshao').style.background = '#0D67BB';
  document.getElementById('mulu').style.background = '#0B3D6C';
  document.getElementById('pinglun').style.background = '#0B3D6C';
}
const directory = (val) => {
  document.getElementById('introduce').style.display = 'none';
  document.getElementById('directory').style.display = 'block';
  document.getElementById('comment').style.display = 'none';
  document.getElementById('jieshao').style.background = '#0B3D6C';
  document.getElementById('mulu').style.background = '#0D67BB';
  document.getElementById('pinglun').style.background = '#0B3D6C';
}
const comment1 = (val) => {
  document.getElementById('introduce').style.display = 'none';
  document.getElementById('directory').style.display = 'none';
  document.getElementById('comment').style.display = 'block';
  document.getElementById('jieshao').style.background = '#0B3D6C';
  document.getElementById('mulu').style.background = '#0B3D6C';
  document.getElementById('pinglun').style.background = '#0D67BB';
}


const getVideoList = (type) => {
    isAudioFile.value = false
    title_show.value = 1
  console.log(document.getElementById('bbb').style.display = 'none')
  from.loading = true
  if (type === 'rem') {
    from.listLeft = []
    from.listRight = []
    from.listinfo = []
    from.page = 1
    noMore.value = false
  }
  if (noMore.value) return
    postGetLists(props.channel)
  plaza_post_index({
    category_id: props.channel,
    page: from.page,
    limit: 20,
    type: props.create_time ? '' : from.dateType,
    orderway: from.sortId,
    keyword: from.keyword,
    time: props.create_time ? props.create_time : 0,
    category_type: 'video'
  }).then(res => {
    if (res.code === 1) {
      from.page += 1
      from.listRight = []
      from.listLeft = []
      from.listinfo = []
      let isPush = true
      res.data.videoList.forEach(item => {
        if (!isPush) {
          from.listRight.push(item)
          isPush = true
        } else {
          from.listLeft.push(item)
          isPush = false
        }
      })
      res.data.albumList.forEach(item => {
        from.listinfo.push(item)
      })
      from.total = res.data.total
      let num = from.listRight.length + from.listLeft.length
      if ((num === res.data.total) || res.data.videoList.length === 0) {
        noMore.value = true
      }
    } else if (res.msg === '尚未开通会员') {
      dialogFormVisible3.value = true
    }
  }).finally(() => {
    from.loading = false
  })
  document.getElementById('image_mse').style.display = 'block';
  document.getElementById('mse').style.display = 'none';
}

const payment = (val) => {
  pay_status.value = 1
  dialogFormVisible3.value = false
  dialogFormVisible1.value = true
}

const apply_for = () => {
  if (pay_al_type.value === 2) {
    createVip({
      goods_type: 'album',
      table_name: 'album',
      goods_id: pay_alid.value,
      pay_type: 3,
    }).then(res => {
      if (res.code === 1) {
        ElMessage({message: '提交成功,等待审核', type: 'success'})
        dialogFormVisible3.value = false
      }
    })
  } else if(pay_al_type.value === 3){
      createVip({
          goods_type: 'articles',
          table_name: 'video',
          goods_id: pay_alid.value,
          pay_type: 3,
      }).then(res => {
          if (res.code === 1) {
              ElMessage({message: '提交成功,等待审核', type: 'success'})
              dialogFormVisible3.value = false
          }
      })
  } else {
    createVip({
      goods_type: 'categories',
      table_name: 'video_category',
      goods_id: props.channel,
      pay_type: 3,
    }).then(res => {
      if (res.code === 1) {
        ElMessage({message: '提交成功,等待审核', type: 'success'})
        dialogFormVisible3.value = false
      }
    })
  }
}

const confirm = (val) => {
  if (pay_status.value === 1) {
    // getVipList().then(res => {
    //   if (res.code === 1) {
    //     vipinfo.value = res.data
    //   }
    // })
      if (pay_al_type.value === 2) {
          goodsShow({
              goods_type: 'album',
              table_name: 'album',
              goods_id: pay_alid.value,
          }).then(res => {
              if (res.code === 1) {
                  vipinfo.value = res.data
              }
          })
      } else if(pay_al_type.value === 3){
          goodsShow({
              goods_type: 'articles',
              table_name: 'video',
              goods_id: pay_alid.value,
          }).then(res => {
              if (res.code === 1) {
                  vipinfo.value = res.data
              }
          })
      } else {
          goodsShow({
              goods_type: 'categories',
              table_name: 'video_category',
              goods_id: props.channel,
          }).then(res => {
              if (res.code === 1) {
                  vipinfo.value = res.data
              }
          })
      }

  }
  dialogFormVisible.value = true
  dialogFormVisible1.value = false
}

const exchange = (val) => {
  pay_status.value = 2
  dialogFormVisible3.value = false
  dialogFormVisible1.value = true
    getUserDetails().then(res => {
        userinfo.value = res.data
    });
  if (pay_al_type.value === 2) {
    goodsShow({
      goods_type: 'album',
      table_name: 'album',
      goods_id: pay_alid.value,
    }).then(res => {
      if (res.code === 1) {
        vipinfo.value = res.data
      }
    })
  } else if(pay_al_type.value === 3){
      goodsShow({
          goods_type: 'articles',
          table_name: 'video',
          goods_id: pay_alid.value,
      }).then(res => {
          if (res.code === 1) {
              vipinfo.value = res.data
          }
      })
  } else {
    goodsShow({
      goods_type: 'categories',
      table_name: 'video_category',
      goods_id: props.channel,
    }).then(res => {
      if (res.code === 1) {
        vipinfo.value = res.data
      }
    })
  }
}

const dredge1 = (id) => {
  //差一个积分支付的接口
  let aaaaa = '';
  if (pay_al_type.value === 2) {
    createVip({
      goods_type: 'album',
      table_name: 'album',
      goods_id: pay_alid.value,
      pay_type: 2,
    }).then(res => {
      if (res.code === 1) {
        order_exchange.value = res.data.order_id
        aaaaa = res.data.order_id
        dialogFormVisible3.value = false
        dialogFormVisible.value = false
        scorePay({order_id: order_exchange.value}).then(res1 => {
          if (res1.code === 1) {
            ElMessage({message: res1.msg, type: 'success'})
          }
        })
      }
    })
  } else if(pay_al_type.value === 3){
      createVip({
          goods_type: 'articles',
          table_name: 'video',
          goods_id: pay_alid.value,
          pay_type: 2,
      }).then(res => {
          if (res.code === 1) {
              order_exchange.value = res.data.order_id
              aaaaa = res.data.order_id
              dialogFormVisible3.value = false
              dialogFormVisible.value = false
              scorePay({order_id: order_exchange.value}).then(res1 => {
                  if (res1.code === 1) {
                      ElMessage({message: res1.msg, type: 'success'})
                  }
              })
          }
      })
  } else {
    createVip({
      goods_type: 'categories',
      table_name: 'video_category',
      goods_id: props.channel,
      pay_type: 2,
    }).then(res => {
      if (res.code === 1) {
        order_exchange.value = res.data.order_id
        aaaaa = res.data.order_id
        dialogFormVisible3.value = false
        dialogFormVisible.value = true
        scorePay({order_id: order_exchange.value}).then(res1 => {
          if (res1.code === 1) {
            ElMessage({message: res1.msg, type: 'success'})
          } else {
            ElMessage({message: res1.msg, type: 'error'})
          }
        })
      }
    })
  }
}


const getVideoList1 = (type) => {
    isAudioFile.value = false
    console.log(document.getElementById('bbb').style.display = 'none')
  from.loading = true
  if (type === 'rem') {
    from.listLeft = []
    from.listRight = []
    from.listinfo = []
    from.page = 1
    from.time = ''
    noMore.value = false
  }
  if (noMore.value) return
    postGetLists(4)
  getHomeList({
    category_id: props.channel,
    channels_id: 4,
    page: from.page,
    limit: 20,
    time: from.time,
    keyword: from.keyword,
    category_type: 'video'
  }).then(res => {
    if (res.code === 1) {
      from.page += 1
      from.listRight = []
      from.listLeft = []
      from.listinfo = []
      let isPush = true
      res.data.videoList.forEach(item => {
        if (!isPush) {
          from.listRight.push(item)
          isPush = true
        } else {
          from.listLeft.push(item)
          isPush = false
        }
      })
      res.data.albumList.forEach(item => {
        from.listinfo.push(item)
      })
      from.total = res.data.total
      let num = from.listRight.length + from.listLeft.length
      if ((num === res.data.total) || res.data.videoList.length === 0) {
        noMore.value = true
      }
    } else if (res.msg === '尚未开通会员') {
      dialogFormVisible1.value = true
    }
  }).finally(() => {
    from.loading = false
  })
  document.getElementById('image_mse').style.display = 'block';
  document.getElementById('mse').style.display = 'none';
}
const album_collection = (val,type,item,typestatus) => {
  addFavorite({
    table_name: typestatus,
    object_id: val
  }).then(res => {
    if (res.code === 1) {
        if(type === 2){
            item.is_favorite = 1
        }else{
            is_collection.value = 1
        }
    } else {
        if(type === 2){
            item.is_favorite = 0
        }else{
            is_collection.value = 2
        }
    }
  })
}

const del_collection = (val,type,item,typestatus) => {
  delFavorite({
    table_name: typestatus,
    object_id: val
  }).then(res => {
    if (res.code === 1) {
        if(type === 2){
            item.is_favorite = 0
        }else{
            is_collection.value = 2
        }
    } else {
        if(type === 2){
            item.is_favorite = 1
        }else{
            is_collection.value = 1
        }
    }
  })
}

const handleMouseEnter = (id) => {
  dialogFormVisible2.value = true
  video_id.value = id
}
// 关闭评论
const closeComment = () => {
  dialogFormVisible2.value = false
}
const beforeIconChild = (a, b) => {
  from.index = a
  from.indexChild = b
}
const getIcon = (e) => {
  comment.value += e
}

const getIcon1 = (e) => {
  text.value += e
}
const adjustTextareaHeight1 = (id) => {
    // 动态调整textarea高度的方法，可以根据实际需求进行调整
    // 例如，可以根据内容的行数来动态设置高度
    const textarea = document.getElementById('aaaaaa')
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
}
const imageContentResult1 = (e) => {
    let str = e.type;
    from.loading = false
    if(comment_image.value.length < 3){
        comment_image.value.push(e.response)
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const handleImageSelected = (e) => {
    from.loading = true;
}
const delimg = (e) => {
    comment_image.value.splice(e, 1);
}
const sendChildComment = (obj, data) => {
    let content = data
    // if(content.length > 140){
    //     ElMessage({message: '字数不能超过140', type: 'error'})
    //     return
    // }
    if (comment_image.value && comment_image.value.length > 0) {
        for (let i = 0; i < comment_image.value.length; i++) {
            content += `![Image Description](${comment_image.value[i]})`
        }
    }
  addComment({
    type: 'album',
    aid: obj,
    pid: 0,
    content: content,
    top_pid: 0
  })
      .then(res => {
        if (res.code === 1) {
          fun_getComment(obj, 10, 1)
            comment_image.value = []
          comment.value = ''
        }
      })
}
const handleMktoHtml = (e) => {
    return e !== undefined ? marked(e) : ''
}
const fun_getComment = (aaa, bbb, ccc) => {
  getComment({
    aid: aaa,
    type: 'album',
    limit: bbb,
    page: ccc
  }).then(res => {
    if (res.code === 1) {
      commentdata.value = res.data.list
      // let data = res.data.list
      // const regex = new RegExp('img', 'gi')
      // for (var i = 0; i < res.data.list.length; i++) {
      //     data[i].content = res.data.list[i].content
      //         .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
      //     data[i].content = res.data.list[i].content
      //         .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
      // }
      // commentdata.value = data
      commentnum.value = res.data.total
      whichpage.value = res.data.current_page
    }
  })
}
const fun_getAlbumVideoList = (aaa, bbb, ccc) => {
  getAlbumVideoList({
    album_id: aaa,
    page: bbb,
    limit: ccc,
  }).then(res => {
    if (res.code === 1) {
      is_collection.value = res.data.album.is_favorite
      introducedata.value = res.data.album
      directorydata.value = res.data.video_list
      videowhichpage.value = res.data.total
      videopage.value = res.data.current_page
    }
  })
}

// const prev_click = () => {
//     if(whichpage.value !== 1){
//         fun_getComment(introducedata.value.id,10,whichpage.value - 1)
//     }else{
//         fun_getComment(introducedata.value.id,10,whichpage.value)
//     }
// }
// const next_click = () => {
//     fun_getComment(introducedata.value.id,10,whichpage.value + 1)
// }
const testfun = (e) => {
  fun_getComment(introducedata.value.id, 10, e)
}
// const prev_click1 = (e) => {
//     if(videopage.value !== 1){
//         fun_getAlbumVideoList(introducedata.value.id,videopage.value - 1,10)
//     }else{
//         fun_getAlbumVideoList(introducedata.value.id,videopage.value,10)
//     }
// }
// const next_click1 = () => {
//     fun_getAlbumVideoList(introducedata.value.id,videopage.value + 1,10)
// }
const testfun1 = (e) => {
  fun_getAlbumVideoList(introducedata.value.id, e, 10)
}

const imageContentResult = (e) => {
  let str = e.type;
  let obj = eval(str);
  for (let i = 0; i < obj.length; i++) {
    if (obj[i].category_type === e.item.category_type && obj[i].id === e.item.id) {
      obj[i].text = e.content
    }
  }
}


const confirmCheck = () => {
    addRating({
        table_name: 'video',
        object_id: video_id.value,
        score: many_stars.value
    }).then(res => {
        if (res.code === 1) {
            ElMessage({message: '感谢您的评价', type: 'success'})
            fun_getComment(introducedata.value.id, 10, 1)
            dialogFormVisible4.value = false
            fun_getAlbumVideoList(introducedata.value.id, 1, 10)
        }
    })
}

const comment_star = (e) => {
    dialogFormVisible4.value = true
    dialogFormVisible2.value = false
    many_stars.value = e
}


const jump = (id) => {
  const routeUrl = router.resolve({
    path: "/home/videoDetails",
    query: {id}
  });
  window.open(routeUrl.href, "_blank");
}

const finish = () => {
  getVideoList('rem')
  emit('finish')
}

const cancellation = () => {
  dialogFormVisible.value = false
  paytype.value = 1
}
const dredge = (id) => {
    let goods_types = ''
    let table_names = ''
    let goods_ids = ''
    if(pay_al_type.value === 2){
        goods_types = 'articles'
        table_names = 'video'
        goods_ids = pay_alid.value
    }else{
        goods_types = 'categories'
        table_names = 'video_category'
        goods_ids = props.channel
    }
  createVip({
    goods_type: goods_types,
    table_name: table_names,
    goods_id: goods_ids,
  }).then(res => {
    if (res.code === 1) {
      payVip({order_id: res.data.order_id}).then(res1 => {
        if (res1.code === 1) {
          payimg.value = res1.data.code_url
          paytype.value = 2
          const intervalId = setInterval(() => {
            Vipquery({order_id: res.data.order_id}).then(res2 => {
              if (res2.code === 1) {
                if (res2.data.pay_status === 1) {
                  clearInterval(intervalId); // 停止轮询
                  location.reload();
                }
              }
            });
          }, 2000);
        }
      })
    }
  })
}



const scrollFn = (e) => {
  //可视区域
  let clientHeight = document.documentElement.clientHeight
  // 滚动文档高度
  let scrollHeight = document.body.scrollHeight
  // 已滚动的高度
  let scrollTop = parseInt(document.documentElement.scrollTop)
  if ((scrollTop + clientHeight) === scrollHeight) {
    if (!noMore.value) {
      getVideoList()
    }
  }
}

const postGetLists = (channels_id) => {
    postGetList({
        channels_id:channels_id,
        page: 1,
        limit: 200,
    }).then(res => {
        if (res.code === 1){
            postLists.value = res.data.data
        }
    })
}

defineExpose({getVideoList, getKeyword, getVideoList1, getNewList3})

onMounted(() => {
  window.addEventListener("scroll", scrollFn)
})

onUnmounted(() => {
  window.removeEventListener("scroll", scrollFn);
})

watch('whichpage', (newValue, oldValue) => {
})
</script>

<style lang="scss" scoped>
.gundong::-webkit-scrollbar {
  display: none;
}

.chatIcon_list_item_pj_item_pl {
  width: 100%;
  position: relative;

  .item_pl_content {
    width: 100%;

    textarea {
      width: 95%;
      border-radius: 30px;
      height: 15px;
      margin-top: 20px;
      padding: 6px 10px;
      resize: none;
      background: #ebf4fd;
      color: #709cd2;
    }

    textarea:focus {
      outline: none;
    }
  }

  .item_pl_caozuo {
    display: flex;
    position: absolute;
    bottom: 5%;
    right: 3%;

    .item_pl_caozuo_img {
      width: 21px;
      height: 21px;
    }

    .item_pl_caozuo_btn {
      width: 42px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 10px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.item_pl_caozuo_btn {
  width: 42px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(0deg, #085EAF, #2791F4);
  border-radius: 10px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
}

.chatIcon_list_item {
  padding: 14px;
  background: rgba(13, 46, 100, 1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0 !important;
  }

  .chatIcon_list_item_head {
    width: 100%;
    display: flex;
    align-items: center;

    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    .head_name {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      padding-right: 9px;
      margin-right: 9px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    .head_time {
      font-size: 11px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .chatIcon_list_item_info::v-deep {
    width: 100%;
    margin-top: 10px;

    img {
      width: 100%;
    }

    video {
      width: 100%;
    }
  }

  .chatIcon_list_item_pj {
    width: 100%;
    margin-top: 10px;
    cursor: auto;

    .chatIcon_list_item_pj_item {
      width: 100%;
      padding: 8px 14px;
      box-sizing: border-box;
      background: #094074;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 9px;
      position: relative;

      &:last-child {
        margin-bottom: 0 !important;
      }
      .chatIcon_list_item_pj_item_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pj_item_top_user {
          display: flex;
          align-items: center;

          img {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            margin-right: 6px;
          }

          span {
            font-size: 9px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }

        .pj_item_top_time {
          display: flex;
          align-items: center;

          .pj_item_top_time_a {
            display: flex;
            align-items: center;
            padding-right: 6px;
            margin-right: 6px;
            cursor: pointer;
            border-right: 2px solid rgba(255, 255, 255, 0.3);

            img {
              width: 9px;
              height: 8px;
              margin-right: 3px;
            }

            span {
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FCB407;
            }
          }

          .pj_item_top_time_b {
            font-size: 9px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }

      .chatIcon_list_item_pj_item_bottom {
        margin-top: 6px;

        span {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 16px;

          &:first-child {
            color: #FFFFFF;
          }
        }
      }

      .chatIcon_list_item_pj_more {
        margin-top: 10px;
        font-size: 9px;
        color: #fff;
        cursor: pointer;
      }

      .chatIcon_list_item_pj_item_list {
        width: 100%;
        margin-top: 10px;
        padding-left: 20px;
        box-sizing: border-box;

        .chatIcon_list_item_pj_item_list_item {
          width: 100%;
          padding: 8px 0;
          box-sizing: border-box;
          background: #094074;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          position: relative;

          &:last-child {
            margin-bottom: 0 !important;
          }
          .chatIcon_list_item_pj_item_top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .pj_item_top_user {
              display: flex;
              align-items: center;

              img {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                margin-right: 6px;
              }

              span {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }

            .pj_item_top_time {
              display: flex;
              align-items: center;

              .pj_item_top_time_a {
                display: flex;
                align-items: center;
                padding-right: 6px;
                margin-right: 6px;
                cursor: pointer;
                border-right: 2px solid rgba(255, 255, 255, 0.3);

                img {
                  width: 9px;
                  height: 8px;
                  margin-right: 3px;
                }

                span {
                  font-size: 9px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FCB407;
                }
              }

              .pj_item_top_time_b {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }
          }

          .chatIcon_list_item_pj_item_bottom {
            margin-top: 6px;

            span {
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 16px;

              &:first-child {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }

  .chatIcon_list_item_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 17px;
    cursor: auto;

    .chatIcon_list_item_footer_left {
      flex: 1;
      display: flex;
      align-items: center;

      .chatIcon_list_item_footer_left_item {
        flex: 1;
        display: flex;
        cursor: pointer;

        img {
          width: 15px;
          height: 14px;
          margin-right: 4px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }

    .chatIcon_list_item_footer_right {
      width: 195px;
      height: 23px;
      padding: 0 1px 0 13px;
      box-sizing: border-box;
      background: #EAF5FF;
      border-radius: 11px;
      margin-left: 21px;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .inputText {
        flex: 1;
        height: 100%;

        input::-webkit-input-placeholder {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #0361BA;
        }

        input {
          font-size: 9px;
          color: #222222;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0);
          border: none;
          outline: none;
        }
      }

      .caozuo {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        img {
          width: 13px;
          height: 13px;
          margin-right: 8px;
          cursor: pointer;
        }

        .caozuo_btn {
          width: 42px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background: linear-gradient(0deg, #085EAF, #2791F4);
          border-radius: 10px;
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }
}

.zzc {
  z-index: 9999 !important;
}

.chatIcon {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  padding: 18px;
  box-sizing: border-box;

  .info {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .chatIcon_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chatIcon_head_left {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }

    .chatIcon_head_right {
      display: flex;
      align-items: center;

      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;

            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }

      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .chatIcon_live {
    width: 48%;
    height: 213px;
    border-radius: 6px;
    //margin-top: 13px;
    overflow: hidden;
    display: inline-block;
    //align-items: center;
    //justify-content: center;
    position: relative;
    //margin-bottom: 21px;
    margin: 13px 2% 21px;

    .image {
      width: 100%;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 31px;
      height: 31px;
      cursor: pointer;
      transform: translate(-50%, -50%);
    }

    .chatIcon_live_text {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 23px 12px 23px;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .chatIcon_list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .chatIcon_list_left {
      width: 100%;
      height: auto;

      .chatIcon_list_left_item {
        width: 100%;
        height: 256px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        position: relative;
        cursor: pointer;

        &:nth-child(even) {
          height: 355px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .chatIcon_list_left_item_img {
          width: 100%;
          height: 100%;

          img {
            height: 100%;
          }
        }

        .chatIcon_list_left_item_wz {
          width: 100%;
          position: absolute;
          bottom: 14px;
          left: 0;
          padding: 0 15px;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .chatIcon_list_right {
      width: 242px;
      height: auto;

      .chatIcon_list_right_item {
        width: 100%;
        height: 355px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        position: relative;
        cursor: pointer;

        &:nth-child(even) {
          height: 256px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        img {
          height: 100%;
        }

        .chatIcon_list_right_item_wz {
          width: 100%;
          position: absolute;
          bottom: 14px;
          left: 0;
          padding: 0 15px;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}

.chatIcon_list_item_footer_right {
  width: 195px;
  height: 23px;
  padding: 0 1px 0 13px;
  box-sizing: border-box;
  background: #EAF5FF;
  border-radius: 11px;
  margin-left: 21px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: right;

  .inputText {
    flex: 1;
    height: 100%;

    input::-webkit-input-placeholder {
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0361BA;
    }

    input {
      font-size: 9px;
      color: #222222;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      outline: none;
    }
  }

  .caozuo {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    img {
      width: 13px;
      height: 13px;
      margin-right: 8px;
      cursor: pointer;
    }

    .caozuo_btn {
      width: 42px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 10px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.html-box {
  img {
    width: 100% !important;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.textinfo {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
