<template>
  <div class="chatIcon">
    <!-- 刷新 -->
    <div class="chatIcon_head">
      <div class="chatIcon_head_left">
        <img src="@/assets/jian@2x.png" alt=""/>
          <span v-if="title_show === 2" style="cursor: pointer" @click="title_fun">{{title_text}}</span>
          <span v-else>
            <span style="cursor: pointer;" v-if="props.channel == 2" @click="title_fun">{{ props.title }}</span>
            <span v-else>
                <span style="cursor: pointer;" @click="title_fun">{{ props.title.slice(0, 4) }}</span>
                -
                <span style="cursor: pointer;" @click="getData('rem')">{{ props.title1 }}</span>
            </span>
          </span>
      </div>
      <div class="chatIcon_head_right">
        <span><el-countdown :value="props.timeVal" @finish="finish"/>后自动更新</span>
        <div class="chatIcon_head_right_btn" @click="getData">
          <img src="@/assets/update@2x.png" alt=""/>
          <span>手动更新</span>
        </div>
      </div>
    </div>
      <div v-if="postLists.length == 0" style="height: 260px;width: 100%;background: rgb(13, 46, 100);margin-top: 10px;overflow-y: auto;font-size: 16px;text-align: center;line-height: 260px;">
          暂无说明
      </div>
      <div v-else style="width: 100%;">
        <div class="gundong" style="width: 100%;background: rgb(13, 46, 100);margin-top: 10px;" v-for="(item,index) in postLists" :key="index">
            <div style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;">{{ item.createtime }}</div>


            <div :ref="el => setPostContentRef(el, index)" :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }" style="padding:0 8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;" v-html="item.content" id="rich_text"></div>
            <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
                <text v-if="expandStates[index]" style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;"
                      @click="expand(index, $event.target)">展开
                </text>
            </div>

            <div v-if="item.file_img.length > 0" style="height: 150px;overflow: hidden;position: relative;padding: 10px;">
                <div v-for="(img, i) in item.file_img" :key="i" style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;">
                    <img :src="img" :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'cover' }" alt="" @click="openPreview1(i,item.file_img)">
                </div>
                <img v-if="item.file_img.length >= 3" @click="jumpDetails(item)" alt="" src="@/assets/jia.png" style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;">
                <div v-if="showPreview1" class="modal" @click="closePreview1">
                    <div class="modal-content">
                        <span class="close" @click="closePreview1">&times;</span>
                        <img :src="images11" alt="Preview" style="object-fit: contain;"/>
                    </div>
                </div>
            </div>

            <div v-if="item.file_video.length > 0">
                <div v-for="(img, i) in item.file_video" :key="i">
                    <video :src="img" controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 300px;"></video>
                </div>
            </div>

            <div v-if="item.file_data.length > 0" style="padding: 10px;color: #26c3dd">
                <div v-for="(img, i) in item.file_data" :key="i">
                    <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                </div>
            </div>

            <div v-if="item.file_audio.length > 0" style="margin: 10px;">
                <div v-for="(img, i) in item.file_audio" :key="i">
                    <audio controls style="width: 100%;">
                        <source :src="img" type="audio/mp3">
                    </audio>
                </div>
            </div>

        </div>
      </div>

    <!-- 列表 -->
<!--    <div class="chatIcon_list">-->
<!--      <div class="chatIcon_list_item" v-for="(item, index) in list" :key="index">-->
<!--        <div class="chatIcon_list_item_info">-->
<!--          <div class="chatIcon_list_item_info_head">-->
<!--            <img src="@/assets/t@2x.png" alt=""/>-->
<!--            <span>{{ item.name }}</span>-->
<!--          </div>-->
<!--          <div class="chatIcon_list_item_info_nr">-->
<!--            {{ item.title }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="chatIcon_list_item_chart">-->
<!--          <lineElement :id="'list' + index" :list="item.data"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <Loading v-if="loading"/>
    <notFind v-if="!loading && list.length === 0"/>
    <div class="info" v-if="!loading && list.length > 0 && noMore">
      <el-text class="mx-1" type="info">没有更多了~</el-text>
    </div>

      <el-dialog v-model="dialogFormVisible" title="开通会员" width="650">
          <div style="height: 200px;display: flex;z-index: 10;margin-top: 15px;margin-right: 20px;" v-if="paytype==1">
              <div style="width: 20%;height: 200px;border: 2px #0B3D6C solid;background-color: #d3dce6;margin-left: 10px;"
                   v-for="(item, index) in vipinfo" :key="index">
                  <div style="font-size: 22px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">{{
                      item.name
                      }}
                  </div>
                  <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;"
                       v-if="pay_status === 1">
                      <text style="color: red;font-size: 22px">{{ item.membership_price }}</text>
                      ￥
                  </div>
                  <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-else>
                      <text style="color: red;font-size: 22px">{{ item.membership_score }}</text>
                  </div>
                  <div style="text-align: center;padding-top: 15px">
                      <el-button type="primary" v-if="pay_status === 1" @click="dredge(item.id)">开通</el-button>
                      <el-button type="primary" v-else @click="dredge1(item.id)">积分开通</el-button>
                  </div>
              </div>
          </div>
          <div style="height: 200px;text-align: center;display: flex" v-else>
              <img :src="payimg" style="width: 200px;height: 200px" alt=""/>
          </div>

          <template #footer>
      <span class="dialog-footer" style="margin-right: 10px;">
          <text style="color: #000000;padding-right: 20px;" v-if="pay_status !== 1">现有积分:{{ userinfo.score }}</text>
        <el-button @click="cancellation()">取消</el-button>
      </span>
          </template>
      </el-dialog>

      <el-dialog v-model="dialogFormVisible1" title="开通会员" width="500">
          <div style="margin-top: 15px;margin-left: 20px;">暂未开通会员,开通会员观看!</div>
          <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取消</el-button>
        <el-button type="primary" @click="confirm">
          确认
        </el-button>
      </span>
          </template>
      </el-dialog>

      <el-dialog v-model="dialogFormVisible3" title="开通会员" width="500">
          <div style="margin-top: 15px;margin-left: 20px;">
            <el-button @click="payment">付费</el-button>
            <el-button @click="exchange">积分兑换</el-button>
            <el-button @click="apply_for">审核申请</el-button>
          </div>
          <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取消</el-button>
      </span>
          </template>
      </el-dialog>
  </div>
</template>

<script setup>
import {defineProps, ref, defineEmits, defineExpose, reactive, nextTick, watchEffect, onMounted, onUnmounted} from 'vue'
import {
    createVip,
    getHomeList,
    getList,
    getUserDetails,
    getVipList,
    goodsShow,
    payVip, postGetList,
    scorePay,
    Vipquery
} from '@/api/index'
import notFind from '@/components/notFind.vue'
import Loading from '@/components/Loading.vue'
import lineElement from '@/components/lineList.vue'
import {ElMessage} from "element-plus";
import {downloadobj, toBase64} from "@/utils/utils";
import {useRouter} from "vue-router";
import Video from "@/components/video.vue";

const props = defineProps({
    title: String,
    title1: String,
  timeVal: Number,   // 刷新时间
  channel: Number,   // 子级栏目id
})

const emit = defineEmits(['finish','openPreview'])

const value = ref([])

let dialogFormVisible = ref(false)
let dialogFormVisible1 = ref(false)
let dialogFormVisible3 = ref(false)
let pay_al_type = ref(1)
let paytype = ref(1)
let pay_status = ref(1)
let vipinfo = ref([])
let postLists = ref([])
let payimg = ref('')
let goods_type = ref('')
let table_name = ref('')
let goods_ids = ref('')
let list = ref([])  // 列表数据
let loading = ref(true)    // 加载状态
let noMore = ref(false)     // 是否加载完所有数据
let userinfo = ref([])

let images11 = ref([])
let showPreview1 = ref(false)
const router = useRouter()
const expandStates = reactive(new Array(postLists.value.length).fill(false));
const postContents = ref([]); // 存储 DOM 引用的数组

let title_show = ref(1)
let title_text = ref('')
let fwb_page = ref(1)
let isScrolledToBottom = ref(false)

const title_fun = () => {
    updateTitleAndFavicon1('复盘 - 量化辅助')
    getData1('rem')
    title_text.value = '量化辅助'
    title_show.value = 2
}

const updateTitleAndFavicon1 = (text) => {
    document.title = text;
}

//文件下载
const download = (e) => {
    downloadobj(e)
}
const getOpacity = (index) => {
    return index === 2 ? 0.2 : 1;
}
const openPreview1 = (index,ima) => {
    // console.log(ima)
    // images11.value = ima
    // showPreview1.value = true;
    emit('openPreview', {index,ima})
}
const closePreview1 = () => {
    showPreview1.value = false;
}
// 跳转详情
const jumpDetails = (item) => {
    localStorage.setItem('txt_item', JSON.stringify(item));
     const routeUrl = router.resolve({
         path: "/home/BackendText",
     });
     window.open(routeUrl.href, "_blank");
}

const setPostContentRef = (el, index) => {
    if (el) {
        // 确保数组大小与 items 一致
        postContents.value[index] = el;
    }
};
const expand = (index, el) => {
    if (postContents.value[index].classList.contains('textinfo')) {
        postContents.value[index].classList.remove('textinfo')
        el.textContent = "收起";
    } else {
        postContents.value[index].classList.add('textinfo')
        el.textContent = "展开";
    }
}
const showTextInfo = async (index) => {
    await nextTick();
    let el = postContents.value[index]
    if (el) {
        expandStates[index] = el.scrollHeight > el.offsetHeight
    }
}
watchEffect(() => {
    // 每次 items 改变时，重新设置引用
    if (postLists.value.length > 0) {
        // if(from.list.table_name == 'plate_post'){
        //
        // }
        for (let i = 0; i < postLists.value.length; i++) {
            showTextInfo(i)
        }
    }
});


const dredge = (id) => {

    if (pay_al_type.value === 2) {
        goods_type.value = 'articles';
        table_name.value = 'pro_diyform';
        goods_ids.value = val
    } else {
        goods_type.value = 'categories';
        table_name.value = 'chart_parent';
        goods_ids.value = props.channel
    }

    createVip({
        goods_type: goods_type.value,
        table_name: table_name.value,
        goods_id: goods_ids.value,
    }).then(res => {
        if (res.code === 1) {
            payVip({order_id: res.data.order_id}).then(res1 => {
                if (res1.code === 1) {
                    payimg.value = res1.data.code_url
                    paytype.value = 2
                    const intervalId = setInterval(() => {
                        Vipquery({order_id: res.data.order_id}).then(res2 => {
                            if (res2.code === 1) {
                                if (res2.data.pay_status === 1) {
                                    clearInterval(intervalId); // 停止轮询
                                    location.reload();
                                }
                            }
                        });
                    }, 2000);
                }
            })
        }
    })
}
const cancellation = () => {
    dialogFormVisible.value = false
    paytype.value = 1
}
const payment = (val) => {
    pay_status.value = 1
    dialogFormVisible3.value = false
    dialogFormVisible1.value = true
}

const apply_for = () => {
    if (pay_al_type.value === 2) {
        goods_type.value = 'articles';
        table_name.value = 'pro_diyform';
        goods_ids.value = val
    } else {
        goods_type.value = 'categories';
        table_name.value = 'chart_parent';
        goods_ids.value = props.channel
    }
    createVip({
        goods_type: goods_type.value,
        table_name: table_name.value,
        goods_id: goods_ids.value,
        pay_type: 3,
    }).then(res => {
        if (res.code === 1) {
            ElMessage({message: '提交成功,等待审核', type: 'success'})
            dialogFormVisible3.value = false
        }
    })
}

const confirm = (val) => {
    if (pay_status.value === 1) {
        // getVipList().then(res => {
        //     if (res.code === 1) {
        //         vipinfo.value = res.data
        //     }
        // })
        if (pay_al_type.value === 2) {
            goods_type.value = 'articles';
            table_name.value = 'pro_diyform';
            goods_ids.value = val
        } else {
            goods_type.value = 'categories';
            table_name.value = 'chart_parent';
            goods_ids.value = props.channel
        }
        goodsShow({
            goods_type: goods_type.value,
            table_name: table_name.value,
            goods_id: goods_ids.value,
        }).then(res => {
            if (res.code === 1) {
                vipinfo.value = res.data
            }
        })
    }
    dialogFormVisible.value = true
    dialogFormVisible1.value = false
}

const exchange = () => {
    pay_status.value = 2
    dialogFormVisible3.value = false
    dialogFormVisible1.value = true
    if (pay_al_type.value === 2) {
        goods_type.value = 'articles';
        table_name.value = 'pro_diyform';
        goods_ids.value = val
    } else {
        goods_type.value = 'categories';
        table_name.value = 'chart_parent';
        goods_ids.value = props.channel
    }
    goodsShow({
        goods_type: goods_type.value,
        table_name: table_name.value,
        goods_id: goods_ids.value,
    }).then(res => {
        if (res.code === 1) {
            vipinfo.value = res.data
        }
    })
    getUserDetails().then(res => {
        userinfo.value = res.data
    });
}

const dredge1 = (id) => {
    //差一个积分支付的接口
    let aaaaa = '';
    if (pay_al_type.value === 2) {
        goods_type.value = 'articles';
        table_name.value = 'pro_diyform';
        goods_ids.value = id
    } else {
        goods_type.value = 'categories';
        table_name.value = 'chart_parent';
        goods_ids.value = props.channel
    }
    createVip({
        goods_type: goods_type.value,
        table_name: table_name.value,
        goods_id: goods_ids.value,
        pay_type: 2,
    }).then(res => {
        if (res.code === 1) {
            aaaaa = res.data.order_id
            dialogFormVisible3.value = false
            dialogFormVisible.value = false
            scorePay({order_id: aaaaa}).then(res1 => {
                if (res1.code === 1) {
                    ElMessage({message: res1.msg, type: 'success'})
                }
            })
        }
    })
}




// 倒计时结束
const finish = () => {
  getData()
  emit('finish')
}

// 请求数据
const getData = () => {
    window.scrollTo(0, 0);
    fwb_page.value = 1
    title_show.value = 1
  list.value = []
  loading.value = true
    postGetLists(props.channel)
  getList({
    startTime: value.value ? value[0] : null,
    endTime: value.value ? value[1] : null,
    category_id: props.channel,
    category_type: 'chart'
  }).then(res => {
    if (res.code === 1) {
      list.value = res.data.chartList
    } else if (res.msg === '尚未开通会员') {
        dialogFormVisible3.value = true
        pay_al_type.value = 1
    }
  }).finally(() => {
    loading.value = false
  })
}
const getData1 = (type) => {
    window.scrollTo(0, 0);
    fwb_page.value = 1
    list.value = []
    loading.value = true
    postGetLists(2)
    getHomeList({
        category_id: props.channel,
        channels_id: 2,
        page: 1,
        limit: 20,
        time: '',
        keyword: '',
        category_type: 'chart'
    }).then(res => {
        if (res.code === 1) {
            list.value = res.data.chartList
        }
    }).finally(() => {
        loading.value = false
    })
}

const postGetLists = (channels_id) => {
    postGetList({
        channels_id:channels_id,
        limit: 10,
        page: fwb_page.value,
    }).then(res => {
        if (res.code === 1){
            if(fwb_page.value === 1){
                postLists.value = res.data.data
            }else{
                postLists.value.push(...res.data.data)
            }
            if(Math.ceil(res.data.total / 10) < fwb_page.value){
                ElMessage({message: '没有更多了', type: 'success'})
                return
            }
        }
    })
}

const loadData = () => {
    if (props.channel == 2) {
        fwb_page.value += 1
        postGetLists(2)
    }else {
        fwb_page.value += 1
        postGetLists(props.channel)
    }
    setTimeout(() => {
        isScrolledToBottom.value = false;
    }, 1000);
}

const scrollFn = (e) => {
    //可视区域
    let clientHeight = document.documentElement.clientHeight
    // 滚动文档高度
    let scrollHeight = document.body.scrollHeight
    // 已滚动的高度
    let scrollTop = parseInt(document.documentElement.scrollTop)
    if ((scrollTop + clientHeight) === scrollHeight && scrollTop >= clientHeight) {
        if (!noMore.value) {
            loadData()
            console.log(clientHeight, scrollHeight, scrollTop, noMore.value);
        }
    }
}

onMounted(() => {
    window.addEventListener("scroll", scrollFn)
})

onUnmounted(() => {
    window.removeEventListener("scroll", scrollFn);
})


defineExpose({getData,getData1})
</script>

<style lang="scss" scoped>
.gundong::-webkit-scrollbar {
  display: none;
}
.chatIcon {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  padding: 18px;
  box-sizing: border-box;

  .info {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .chatIcon_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chatIcon_head_left {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }

    .chatIcon_head_right {
      display: flex;
      align-items: center;

      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;

            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }

      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .chatIcon_operate {
    width: 100%;
    margin: 17px 0;
    display: flex;
    align-items: center;

    .chatIcon_operate_date {
      width: 200px;
    }
  }

  .chatIcon_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .chatIcon_list_item {
      width: 100%;
      // height: 209px;
      margin-bottom: 19px;
      display: flex;
      flex-direction: column;
      padding: 18px 0 30px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(55, 73, 105, 1);

      &:last-child {
        margin-bottom: 0 !important;
        border: none !important;
      }

      .chatIcon_list_item_chart {
        width: 100%;
        height: 200px;
        margin-top: 10px;
      }

      .chatIcon_list_item_info {
        flex: 1;
        display: flex;
        flex-direction: column;

        .chatIcon_list_item_info_head {
          display: flex;

          img {
            width: 15px;
            height: 9px;
            margin-right: 6px;
            margin-top: 3px;
          }

          span {
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #0CFFFC;
          }
        }

        .chatIcon_list_item_info_nr {
          width: 100%;
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          margin-top: 8px;
        }
      }
    }
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.textinfo {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#rich_text {
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.1em;
}
.file_style {
  text-decoration: none;
  color: #26c3dd;
  cursor: pointer;
}
</style>
