<template>
  <div class="login">
    <div class="login_info">
      <div class="login_info_logo">
        <img src="../assets/login_logo.png" alt=""/>
      </div>
      <div class="login_info_info">
        <img src="../assets/login_bg.png" alt="" style="width: 30%"/>
      </div>
    </div>
    <div class="login_box" v-if="!isZH">
      <div class="login_box_hz">
        <img class="d1" src="../assets/one.png" alt=""/>
        <img class="d2" src="../assets/two.png" alt=""/>
        <img class="d3" src="../assets/three.png" alt=""/>

        <div style="display: flex;" v-if="!active">
          <div class="login_box_head" @click="fangshi_pass">
            <span :style="{color:loginWay ? '#33e2ff' : 'white'}">密码登录</span>
          </div>
          <div class="login_box_head" @click="fangshi_code">
            <span :style="{color:!loginWay ? '#33e2ff' : 'white'}">验证码登录</span>
          </div>
        </div>
        <div class="login_box_head" v-else>
            <span style="color: #33e2ff;">注册账号</span>
        </div>
<!--        <div class="login_box_head">-->
<!--            <span>{{ active ? '注册账号' : '账号登陆' }}</span>-->
<!--&lt;!&ndash;            <span>账号登录/注册</span>&ndash;&gt;-->
<!--        </div>-->


        <div class="login_box_content">
          <!-- 账号 -->
          <div class="login_box_content_child">
            <div class="login_box_content_item">
              <img src="../assets/logo.png" alt=""/>
              <input v-model="form.mobile" placeholder="请输入手机号"/>
            </div>
          </div>
          <!-- 验证码 -->
          <div class="login_box_content_child" v-if="!loginWay">
            <div class="login_box_content_item">
              <img src="../assets/logo.png" alt=""/>
              <input v-model="form.captcha" type="text" placeholder="请输入验证码"/>
            </div>
            <div class="login_box_content_child_r">
              <span v-if="num > 0">{{ num }}</span>
              <span v-else @click="sendInfo">发送验证码</span>
            </div>
          </div>
          <!-- 密码 -->
          <div class="login_box_content_child" v-else>
            <div class="login_box_content_item">
              <img src="../assets/logo.png" alt=""/>
              <input v-model="form.password" type="password" placeholder="请输入密码"/>
            </div>
          </div>
          <!-- 操作按钮 -->
          <div class="login_box_content_edit">
            <span @click="isZH = true" v-if="loginWay">忘记密码</span>
            <span v-else></span>
            <div>
<!--              <span v-if="!active" @click="fangshi_code">验证码登录</span>-->
<!--              <span v-if="!active" @click="fangshi_pass" style="margin-left: 10px;">密码登录</span>-->
              <span v-if="!active" @click="register" style="margin-left: 10px;">注册</span>
              <span v-if="active" @click="fangshi" style="margin-left: 10px;">登录</span>
            </div>
          </div>
          <!-- 操作按钮 -->
          <!--          <div class="login_box_content_edit" v-if="!active && loginWay">-->
          <!--            <span @click="isZH = true">忘记密码</span>-->
          <!--            <span></span>-->
          <!--          </div>-->
          <!-- 记住密码 -->
          <div class="login_box_content_jz">
            <div class="login_box_content_jz_i" @click.stop="checked1 = !checked1"></div>
            <el-checkbox v-model="checked1"></el-checkbox>
            <span>我已阅读并同意<a style="color: #2080f7;text-decoration: none;" @click="serviceagreement = true">《服务协议》</a></span>
          </div>
          <div class="login_box_content_jz">
            <div class="login_box_content_jz_i" @click.stop="checked = !checked"></div>
            <el-checkbox v-model="checked"></el-checkbox>
            <span>记住我</span>
          </div>
        </div>
        <el-button class="login_box_footer" color="#26c2dc" :disabled="loading" :loading="loading" @click="login">
          {{ active ? '注册' : '登录' }}
        </el-button>
      </div>
    </div>
    <div class="login_box" v-if="isZH">
      <div class="login_box_hz">
        <img class="d1" src="../assets/one.png" alt=""/>
        <img class="d2" src="../assets/two.png" alt=""/>
        <img class="d3" src="../assets/three.png" alt=""/>
        <div class="login_box_head">
          <img src="../assets/logo@2x.png" alt=""/>
          <span style="color: #33e2ff;">找回密码</span>
        </div>
        <div class="login_box_content">
          <!-- 账号 -->
          <div class="login_box_content_child">
            <div class="login_box_content_item">
              <img src="../assets/logo.png" alt=""/>
              <input v-model="form.mobile" placeholder="请输入手机号"/>
            </div>
          </div>
          <!-- 验证码 -->
          <div class="login_box_content_child">
            <div class="login_box_content_item">
              <img src="../assets/logo.png" alt=""/>
              <input v-model="form.captcha" type="text" placeholder="请输入验证码"/>
            </div>
            <div class="login_box_content_child_r">
              <span v-if="num > 0">{{ num }}</span>
              <span v-else @click="sendInfo">发送验证码</span>
            </div>
          </div>
          <!-- 新密码 -->
          <div class="login_box_content_child">
            <div class="login_box_content_item">
              <img src="../assets/logo.png" alt=""/>
              <input v-model="form.password" placeholder="请输入新密码"/>
            </div>
          </div>
        </div>

          <div style="margin-top: 10px;cursor: pointer;color: #33e2ff;" @click="isZH = false">返回注册登录</div>

        <el-button class="login_box_footer" color="#26c2dc" :disabled="loading" :loading="loading" @click="submit">
          提交
        </el-button>
      </div>
    </div>
  </div>
  <el-dialog v-model="dialogVisible" title="温馨提示" width="30%" draggable>
    <span>尊敬的用户您好，复盘网站已使用您填写的手机号自动为您开通的账号，因为您是首次登录，需要完善个人信息，完善信息可解锁更多权益！</span>
    <template #footer>
      <span class="dialog-footer">
<!--        <el-button @click="goPerfect(0)">跳过完善</el-button>-->
        <el-button type="primary" @click="goPerfect(1,keyValue,0)">
          继续完善
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog title="提示" v-model="centerDialogVisible" width="30%" draggable>
    <div>您需要同意服务协议方能使用</div>
    <div slot="footer" class="dialog-footer"
         style="padding-top: 20px;display: flex;justify-content: center;align-items: center;">
      <el-button @click="centerDialogVisible = false">不同意</el-button>
      <el-button type="primary" @click="confirmCheck()">同意并登录</el-button>
    </div>
  </el-dialog>

  <div class="bbbbb">
    <el-dialog title="申请结果" v-model="auditNotification" width="25%" draggable>
      <div style="display: flex;justify-content: center;margin-bottom: 30px;">
          <img src="../assets/complete.png" alt="" style="width: 30%;height: 170px;"/>
      </div>
      <div style="text-align: center;font-size: 16px;">申请已提交</div>
      <div style="text-align: center;font-size: 16px;">请等待管理员审核</div>
      <div slot="footer" class="dialog-footer"
           style="padding-top: 20px;display: flex;justify-content: center;align-items: center;">
        <el-button style="width: 150px;margin-bottom: 20px;background: linear-gradient(0deg, #085EAF, #2791F4);color: #fff" @click="auditNotification = false">确定</el-button>
      </div>
    </el-dialog>
  </div>

  <div class="aaaaa">
      <el-dialog v-model="serviceagreement" title="服务协议" width="80%" draggable style="background-color: #061732" class="dialog-custom-style">
          <div :class="{ 'chatIcon_list_item_info': true}" v-html="configinfo_text" style="word-wrap: break-word;padding: 10px;color:#FFFFFF;height:600px;overflow-y:scroll;"></div>
          <div slot="footer" class="dialog-footer" style="padding: 20px 0;display: flex;justify-content: center;align-items: center;">
              <el-button style="background-color: #0d2e64;color:#ffffff;" @click="serviceagreement = false">取消</el-button>
              <a style="margin-left: 10px;" :href="configinfo" v-if="configinfo !== ''"><el-button style="background-color: #0d2e64;color:#ffffff;" >下载协议</el-button></a>
          </div>
      </el-dialog>
  </div>


</template>

<script setup>
import {ElMessage} from 'element-plus'
import {onMounted, onUnmounted, reactive, ref} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {getCaptca, Login, mobilelogin, resetpwd, send, systemConfig} from '@/api/index'

const store = useStore()
const router = useRouter()
let timer = ref(null)
let img = ref('')
let serviceagreement = ref(false)
let loading = ref(false)
let configinfo = ref('')
let configinfo_text = ref('')
let centerDialogVisible = ref(false)
let auditNotification = ref(true)
let active = ref(false)
let num = ref(0)
let keyValue = ref(0)
let loginWay = ref(false)
let checked = ref(false)
let checked1 = ref(false)
let isZH = ref(false)
let dialogVisible = ref(false)
let form = reactive({
  username: '',
  password: '',
  email: '',
  mobile: '',
  code: '',
  token: '',
  captcha: ''
})
const goPerfect = (e, key, type) => {
  dialogVisible.value = false
  if (e) {
    const routeUrl = router.resolve({
      path: "/perfect",
      query: {key,type}
    });
    window.open(routeUrl.href, "_blank");
  } else {
    router.push({path: '/index'})
  }
}
// 重置密码
const submit = () => {
  resetpwd({
    type: 'mobile',
    mobile: form.mobile,
    newpassword: form.password,
    captcha: form.captcha
  }).then(res => {
    if (res.code === 1) {
      ElMessage.success({message: '更新成功'})
      isZH.value = false
      loginWay.value = true
      active.value = false
    }
  })
}

// 发送短信验证码
const sendInfo = () => {
  if (!form.mobile) return ElMessage.warning({message: '请先输入手机号'})
  send({mobile: form.mobile, event: isZH.value ? 'resetpwd' : 'mobilelogin'})
      .then(res => {
        if (res.code === 1) {
          num.value = 60
          timeOut()
        }
      })
}

// 注册
const register = () => {
  active.value = true
  loginWay.value = false
}

// 验证码倒计时
const timeOut = () => {
  timer.value = setInterval(() => {
    num.value--
    if (num.value === 0) {
      clearInterval(timer.value)
      timer.value = null
    }
  }, 1000)
}
const initForm = () => {
  form.mobile = ''
  form.captcha = ''
  form.password = ''
  num.value = 0
}

const fangshi = () => {
  active.value = false
}

const fangshi_code = () => {
  loginWay.value = false
  form.captcha = ''
  form.password = ''
  num.value = 0
  clearInterval(timer.value)
}

const fangshi_pass = () => {
  loginWay.value = true
  form.captcha = ''
  form.password = ''
  num.value = 0
  clearInterval(timer.value)
}

const confirmCheck = () => {
  checked1.value = true
  window.localStorage.setItem('checked1', true)
  centerDialogVisible.value = false

  setTimeout(()=>{
    login();
  },2000)
}

const login = () => {
  if (loading.value) return
  if (!checked1.value) {
    centerDialogVisible.value = true
    return
  }
  // 登录
  // if (!active.value) {
    // 手机号验证码登录
    if (!loginWay.value) {
      if (!form.mobile) {
        return ElMessage({message: '账号不能为空', type: 'warning'})
      }
      if (!form.captcha) {
        return ElMessage({message: '验证码不能为空', type: 'warning'})
      }
      loading.value = true
      mobilelogin({mobile: form.mobile, captcha: form.captcha})
          .then(async (res) => {
            if (res.code === 1) {
              ElMessage({message: '登录成功', type: 'success', duration: 2000})
              store.commit('setToken', res.data.userinfo.token)
              await store.dispatch('getUserInfo')
              localStorage.setItem('is_exist_user', res.data.is_exist_user);
              localStorage.setItem('userId', res.data.userinfo.id);
              if (res.data.userinfo.is_perfect === '0') {
                  if (res.data.is_required_userinfo == 1){
                    if (res.data.is_exist_user == 0){
                      dialogVisible.value = true
                    }else{
                      router.push({path: '/index'})
                    }
                  }else{
                      router.push({path: '/index'})
                  }
              } else {
                router.push({path: '/index'})
              }
              initForm()
            }else{
              auditNotification.value = true
            }
          })
          .finally(() => {
            loading.value = false
          })
      // 手机号密码登录
    } else {
      if (!form.mobile) {
        return ElMessage({message: '账号不能为空', type: 'warning'})
      }
      if (!form.password) {
        return ElMessage({message: '密码不能为空', type: 'warning'})
      }
      loading.value = true
      Login({
        account: form.mobile,
        password: form.password
      }).then(async (res) => {
        if (res.code === 1) {
          ElMessage({message: '登录成功', type: 'success', duration: 2000})
          store.commit('setToken', res.data.userinfo.token)
          await store.dispatch('getUserInfo')
          localStorage.setItem('is_exist_user', 1);
          if (res.data.userinfo.is_perfect === '0') {
              // if (res.data.is_required_userinfo == 1){
              //     router.push({path: '/index'})
              // }else{
                  router.push({path: '/index'})
              // }
          } else {
            router.push({path: '/index'})
          }
          initForm()
        }else{
          auditNotification.value = true
        }
      }).finally(() => {
        loading.value = false
      })
    }
  // } else {
  //   if (!form.mobile) {
  //     return ElMessage({message: '手机号不能为空', type: 'warning'})
  //   }
  //   if (!form.captcha) {
  //     return ElMessage({message: '验证码不能为空', type: 'warning'})
  //   }
  //   mobilelogin({mobile: form.mobile, captcha: form.captcha})
  //       .then(res => {
  //         if (res.code === 1) {
  //           ElMessage({message: '注册成功', type: 'success', duration: 2000})
  //           store.commit('setUserInfo', res.data.userinfo)
  //           router.push({name: 'perfect'})
  //         }
  //       }).finally(() => {
  //     loading.value = false
  //   })
  // }
}

// 获取图形验证码
const getCode = () => {
  getCaptca({})
      .then(res => {
        img.value = res.data.img
        form.token = res.data.token
      })

  systemConfig({}).then(res => {
      configinfo.value = res.data.web_service_agreement
      configinfo_text.value = res.data.web_service_agreement_text
  })

}
//获取邀请码key
const invitationKey = () => {
  const url = window.location.href;
  const params = new URLSearchParams(url.split('?')[1]); // 获取问号后面的参数部分
  keyValue.value = params.get('key');
}

const updateTitleAndFavicon = () => {
    document.title = '复盘';
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
        favicon.href = 'https://video-source.obs.cn-north-4.myhuaweicloud.com/img/default_user.jpg';
    }
}


onMounted(() => {
  var value = localStorage.getItem('checked1');
  checked1.value = (value === 'true');
  if (window.localStorage.getItem('UserInfo')) {
    router.push({name: 'home'})
  }
  getCode()
  invitationKey()
  updateTitleAndFavicon()
})

onUnmounted(() => {
  clearInterval(timer.value)
})
</script>
<style>
.aaaaa .el-dialog__header{
    background-color: #061732 !important;
}
.bbbbb .el-dialog__header{
  background-color: white !important;
}
.bbbbb .el-dialog__title{
  color: #000 !important;
}
</style>

<style lang="scss" scoped>
.login {
  width: 100vw;
  min-width: 1000px;
  height: 100vh;
  background-image: url('@/assets/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .login_info {
    position: absolute;
    top: 50px;
    left: 50px;
    display: flex;
    flex-direction: column;
    width: 90%;

    .login_info_logo {
      width: 108px;
      height: 66px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .login_info_title {
      font-size: 35px;
      color: #fff;
      margin-top: 5%;
      margin-left: 10%;
      width: 100%;
      letter-spacing: 20px;
    }

    .login_info_info {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
      margin-left: 15%;
      letter-spacing: 20px;

      span {
        font-size: 18px;
        color: #fff;
        margin-bottom: 5px;

        &:last-child {
          margin: 0 !important;
        }
      }
    }
  }

  .login_box {
    position: absolute;
    right: 230px;
    top: 25%;
    padding: 25px;
    width: 400px;
    // height: 370px;
    box-sizing: border-box;
    background-image: url('@/assets/a@3x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .login_box_hz {
      width: 100%;
      height: 100%;
      position: relative;

      .d1 {
        position: absolute;
        top: -5px;
        left: -23px;
        width: 6px;
        height: 18px;
      }

      .d2 {
        position: absolute;
        top: 13px;
        left: -23px;
        width: 6px;
        height: 18px;
      }

      .d3 {
        position: absolute;
        top: 30px;
        left: -23px;
        width: 6px;
        height: 18px;
      }

      .login_box_head {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 55px;
          height: 35px;
          margin-right: 5px;
        }

        span {
          font-size: 18px;
        }
      }

      .login_box_content {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;

        span {
          font-size: 13px;
          color: #33e2ff;
          cursor: pointer;
        }

        .login_box_content_edit {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-size: 13px;
            color: #33e2ff;
            cursor: pointer;
          }
        }

        .login_box_content_jz {
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
          margin-top: 10px;

          .login_box_content_jz_i {
            width: 70px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
            cursor: pointer;
          }

          span {
            font-size: 12px;
            color: #fff;
            margin-left: 4px;
          }
        }

        .login_box_content_child {
          width: 100%;
          height: 43px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .login_box_content_item {
            flex: 1;
            height: 100%;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid #105078;

            img {
              flex-shrink: 0;
              width: 15px;
              height: 15px;
              margin-left: 15px;
            }

            input {
              flex: 1;
              height: 100%;
              border: none;
              outline: none;
              color: #fff;
              font-size: 14px;
              background-color: rgba(0, 0, 0, 0);

              &:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
                -webkit-text-fill-color: #fff !important;
                -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
                background-color: transparent;
                background-image: none;
                transition: background-color 50000s ease-in-out 0s; //背景色透明 生效时长 过渡效果 启用时延迟的时间
              }
            }

            input::-webkit-input-placeholder {
              color: #576e84;
              font-size: 14px;
            }
          }

          .login_box_content_child_r {
            // flex-shrink: 0;
            width: 150px;
            height: 100%;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              font-size: 13px;
              cursor: pointer;
            }

            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }

      .login_box_footer {
        width: 100%;
        height: 40px;
        margin-top: 20px;
        color: #fff;
      }
    }
  }
}</style>
