<template>
  <div class="chatIcons">
    <!-- 刷新 -->
    <div class="chatIcon_head">
      <div class="chatIcon_head_left">
        <img src="@/assets/jian@2x.png" alt=""/>

          <span v-if="title_show === 2" style="cursor: pointer" @click="title_fun">{{title_text}}</span>
          <span v-else>
            <span style="cursor: pointer;" v-if="props.channel == 7" @click="title_fun">{{ props.title }}</span>
            <span v-else>
                <span style="cursor: pointer;" @click="title_fun">{{ props.title.slice(0, 4) }}</span>
                -
                <span style="cursor: pointer;" @click="getDate('rem')">{{ props.title1 }}</span>
            </span>
          </span>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="chatIcons_sou">
      <div class="chatIcons_sou_item">
        <input type="text" v-model="from.keyword" placeholder="请输入"/>
        <el-button type="primary" :icon="Search" size="small" @click="butkey()">搜索</el-button>
      </div>
        <div class="block" style="margin-left: 10px">
            <el-date-picker
                    style="width: 240px;"
                    @change="date_range"
                    size="small"
                    v-model="value1"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
        </div>
    </div>

      <div v-if="postLists.length == 0" style="height: 260px;width: 100%;background: rgb(13, 46, 100);margin-top: 10px;overflow-y: auto;font-size: 16px;text-align: center;line-height: 260px;">
          暂无说明
      </div>
      <div v-else style="width: 100%;">
        <div class="gundong" style="width: 100%;background: rgb(13, 46, 100);margin-top: 10px;" v-for="(item,index) in postLists" :key="index">
            <div style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;">{{ item.createtime }}</div>

            <div id="rich_text" :ref="el => setPostContentRef_one(el, index)" :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }" style="padding:0 8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;" v-html="item.content"></div>
            <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
                <text v-if="expandStates_one[index]" style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;"
                      @click="expand_one(index, $event.target)">展开
                </text>
            </div>
            <div v-if="item.file_img.length > 0" style="height: 150px;overflow: hidden;position: relative;padding: 10px;">
                <div v-for="(img, i) in item.file_img" :key="i" style="margin: 10px 0 10px 2px;width: 33%;height: 150px;display: inline-block;float: left;position: relative;">
                    <img :src="img" :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'contain' }" alt="" @click="openPreview1(i,item.file_img)">
                </div>
                <img v-if="item.file_img.length >= 3" @click="jumpDetails(item)" alt="" src="@/assets/jia.png" style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;">
                <div v-if="showPreview1" class="modal" @click="closePreview1">
                    <div class="modal-content">
                        <span class="close" @click="closePreview1">&times;</span>
                        <img :src="images11" alt="Preview" style="object-fit: contain;"/>
                    </div>
                </div>
            </div>

            <div v-if="item.file_video.length > 0">
                <div v-for="(img, i) in item.file_video" :key="i">
                    <video :src="img" controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 300px;"></video>
                </div>
            </div>

            <div v-if="item.file_data.length > 0" style="padding: 10px;color: #26c3dd;">
                <div v-for="(img, i) in item.file_data" :key="i">
                    <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                </div>
            </div>

            <div v-if="item.file_audio.length > 0" style="margin: 10px;">
                <div v-for="(img, i) in item.file_audio" :key="i">
                    <audio controls style="width: 100%;">
                        <source :src="img" type="audio/mp3">
                    </audio>
                </div>
            </div>
        </div>
      </div>

    <!-- 列表 -->
<!--    <div class="chatIcons_list">-->
<!--      <div style="width: 96%;background-color: #0D2E64;border-radius: 10px;margin-bottom: 10px;padding: 10px;" v-for="(item, index) in sjklist" :key="index">-->
<!--          <div v-if="item.is_show_file == 0" :ref="el => setPostContentRef(el, index)" :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }">-->
<!--              <div style="padding-bottom: 10px;">发布时间:{{item.content_time}}</div>-->
<!--              政策面:<span v-html="item.policy_content"></span>-->
<!--              市场新闻:<span v-html="item.news_content"></span>-->
<!--              宏观面:<span v-html="item.macro_content"></span>-->
<!--              资金面及预期:<span v-html="item.fund_content"></span>-->
<!--              市场预期/关注:<span v-html="item.expect_content"></span>-->
<!--              情绪面:<span v-html="item.emotion_content"></span>-->
<!--          </div>-->
<!--          <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">-->
<!--              <text v-if="expandStates[index]" style="cursor: pointer"-->
<!--                    @click="expand(index, $event.target)">展开-->
<!--              </text>-->
<!--          </div>-->


<!--          <div v-if="item.is_show_file == 1">-->
<!--              <div v-for="(item1, index1) in item.file_list" :key="index1">-->
<!--                  <div v-if="filetype(item1.url) == 1" style="margin-top: 10px;">-->
<!--                      <audio controls style="width: 100%;">-->
<!--                          <source :src="item1.url" type="audio/mp3">-->
<!--                      </audio>-->
<!--                  </div>-->
<!--                  <div v-if="filetype(item1.url) == 2" style="margin-top: 10px;">-->
<!--                      <video controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 400px;">-->
<!--                          <source :src="item1.url" type="video/mp4">-->
<!--                      </video>-->
<!--                  </div>-->
<!--                  <div v-if="filetype(item1.url) == 3" style="margin-top: 10px;">-->
<!--                      <div style="height: 300px;">-->
<!--                          <img :src="item1.url" :style="{ width: '100%', height: '300px', opacity: getOpacity(i),objectFit: 'cover' }" alt="" @click="openPreview(item1.url)">-->
<!--                      </div>-->
<!--                      <div v-if="showPreview" class="modal" @click="closePreview">-->
<!--                          <div class="modal-content">-->
<!--                              <span class="close" @click="closePreview">&times;</span>-->
<!--                              <img :src="images1" alt="Preview" style="max-width: 80%; max-height: 80%;"/>-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </div>-->
<!--                  <div v-if="filetype(item1.url) == 4" style="margin-top: 10px;">-->
<!--                      <a class="file_style" @click="download(item1.url)">{{ item1.name }}</a>-->
<!--                  </div>-->
<!--              </div>-->
<!--          </div>-->
<!--      </div>-->
<!--    </div>-->

      <el-dialog v-model="dialogFormVisible" title="开通会员" width="650">
          <div style="height: 200px;display: flex;z-index: 10;margin-top: 15px;margin-right: 20px;" v-if="paytype==1">
              <div style="width: 20%;height: 200px;border: 2px #0B3D6C solid;background-color: #d3dce6;margin-left: 10px;"
                   v-for="(item, index) in vipinfo" :key="index">
                  <div style="font-size: 22px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">{{
                      item.name
                      }}
                  </div>
                  <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;"
                       v-if="pay_status === 1">
                      <text style="color: red;font-size: 22px">{{ item.membership_price }}</text>
                      ￥
                  </div>
                  <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-else>
                      <text style="color: red;font-size: 22px">{{ item.membership_score }}</text>
                  </div>
                  <div style="text-align: center;padding-top: 15px">
                      <el-button type="primary" v-if="pay_status === 1" @click="dredge(item.id)">开通</el-button>
                      <el-button type="primary" v-else @click="dredge1(item.id)">积分开通</el-button>
                  </div>
              </div>
          </div>
          <div style="height: 200px;text-align: center;display: flex" v-else>
              <img :src="payimg" style="width: 200px;height: 200px" alt=""/>
          </div>

          <template #footer>
      <span class="dialog-footer" style="margin-right: 10px;">
          <text style="color: #000000;padding-right: 20px;" v-if="pay_status !== 1">现有积分:{{ userinfo.score }}</text>
        <el-button @click="cancellation()">取消</el-button>
      </span>
          </template>
      </el-dialog>

      <el-dialog v-model="dialogFormVisible1" title="开通会员" width="500">
          <div style="margin-top: 15px;margin-left: 20px;">暂未开通会员,开通会员观看!</div>
          <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取消</el-button>
        <el-button type="primary" @click="confirm">
          确认
        </el-button>
      </span>
          </template>
      </el-dialog>

      <el-dialog v-model="dialogFormVisible3" title="开通会员" width="500">
          <div style="margin-top: 15px;margin-left: 20px;">
              <el-button @click="payment">付费</el-button>
              <el-button @click="exchange">积分兑换</el-button>
              <el-button @click="apply_for">审核申请</el-button>
          </div>
          <template #footer>
              <span class="dialog-footer">
                <el-button @click="dialogFormVisible3 = false">取消</el-button>
              </span>
          </template>
      </el-dialog>

      <Loading v-if="loading"/>
  </div>
</template>

<script setup>
import Loading from '@/components/Loading.vue'
import {defineProps, defineExpose, ref, reactive, defineEmits, watchEffect, nextTick, onMounted, onUnmounted} from 'vue'
import {Search} from '@element-plus/icons-vue'
import {
    plaza_post_index,
    plaza_post_sjk,
    getHomeList,
    createVip,
    payVip,
    Vipquery,
    getVipList,
    goodsShow,
    scorePay, getUserDetails, postGetList
} from '@/api'
import {ElMessage} from "element-plus";
import {downloadobj} from "@/utils/utils";
import {useRouter} from "vue-router";
import Video from "@/components/video.vue";

const props = defineProps({
    title: String,
    title1: String,
  channel: Number,
  create_time: String
})
let showPreview = ref(false)
let dialogFormVisible = ref(false)
let dialogFormVisible1 = ref(false)
let dialogFormVisible3 = ref(false)
let images1 = ref([])
let currentImageIndex = ref(0)
let pay_al_type = ref(1)
let paytype = ref(1)
let pay_status = ref(1)
let vipinfo = ref([])
let postLists = ref([])
let payimg = ref('')
let goods_type = ref('')
let table_name = ref('')
let goods_ids = ref('')
let list = ref([])  // 列表数据
let sjklist = ref([])  // 列表数据
let loading = ref(true)    // 加载状态
let noMore = ref(false)     // 是否加载完所有数据
let userinfo = ref([])     // 用户数据
let value1 = ref('')
let start_time = ref('')
let end_time = ref('')
let time = ref('')
let time_type = ref(1)
let fwb_page = ref(1)
let isScrolledToBottom = ref(false)
const postContents = ref([]); // 存储 DOM 引用的数组
const postContents_one = ref([]); // 存储 DOM 引用的数组
const expandStates = reactive(new Array(sjklist.value.length).fill(false));
const expandStates_one = reactive(new Array(postLists.value.length).fill(false));
const emit = defineEmits(['openPreview'])
let images11 = ref([])
let showPreview1 = ref(false)
const router = useRouter()

let title_show = ref(1)
let title_text = ref('')

const title_fun = () => {
    updateTitleAndFavicon1('复盘 - 数据库')
    getDate1('rem')
    title_text.value = '数据库'
    title_show.value = 2
}

const updateTitleAndFavicon1 = (text) => {
    document.title = text;
}


const menu_title = () => {
    getDate1('rem')
}

const openPreview1 = (index,ima) => {
    // console.log(ima)
    // images11.value = ima
    // showPreview1.value = true;
    emit('openPreview', {index, ima})
}
const closePreview1 = () => {
    showPreview1.value = false;
}
// 跳转详情
const jumpDetails = (item) => {
    localStorage.setItem('txt_item', JSON.stringify(item));
    const routeUrl = router.resolve({
        path: "/home/BackendText",
    });
    window.open(routeUrl.href, "_blank");
}

const setPostContentRef_one = (el, index) => {
    if (el) {
        // 确保数组大小与 items 一致
        postContents_one.value[index] = el;
    }
};
const expand_one = (index, el) => {
    if (postContents_one.value[index].classList.contains('textinfo')) {
        postContents_one.value[index].classList.remove('textinfo')
        el.textContent = "收起";
    } else {
        postContents_one.value[index].classList.add('textinfo')
        el.textContent = "展开";
    }
}
const showTextInfo_one = async (index) => {
    await nextTick();
    let el = postContents_one.value[index]
    if (el) {
        expandStates_one[index] = el.scrollHeight > el.offsetHeight
    }
}
watchEffect(() => {
    if (postLists.value.length > 0) {
        for (let i = 0; i < postLists.value.length; i++) {
            showTextInfo_one(i)
        }
    }
});


let from = reactive({
  page: 1,
  keyword: '',
  time: props.create_time
})

// 日期范围
const date_range = (e) => {
    if (value1.value){
        start_time.value = formatDate(value1.value[0])
        time.value = formatDate(value1.value[0])
        end_time.value = formatDate(value1.value[1])
        time_type.value = 2
        if (props.channel === 7){
            getDate1('rem')
        }else{
            getDate('rem')
        }
    }
}

const setPostContentRef = (el, index) => {
    if (el) {
        // 确保数组大小与 items 一致
        postContents.value[index] = el;
    }
};

const butkey = () => {
    if (from.keyword){
        if (props.channel === 7){
            getDate1('rem')
        }else{
            getDate('rem')
        }
    }
}

const expand = (index, el) => {

    if (postContents.value[index].classList.contains('textinfo')) {
        postContents.value[index].classList.remove('textinfo')
        el.textContent = "收起";
    } else {
        postContents.value[index].classList.add('textinfo')
        el.textContent = "展开";
    }
}

const showTextInfo = async (index) => {
    await nextTick();
    let el = postContents.value[index]
    if (el) {
        expandStates[index] = el.scrollHeight > el.offsetHeight
    }
}

watchEffect(() => {
    // 每次 items 改变时，重新设置引用
    if (sjklist.value.length > 0) {
        for (let i = 0; i < sjklist.value.length; i++) {
            showTextInfo(i)
        }
    }
});

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

const dredge = (id) => {
    if (pay_al_type.value === 2) {
        goods_type.value = 'articles';
        table_name.value = 'pro_diyform';
        goods_ids.value = val
    } else {
        goods_type.value = 'categories';
        table_name.value = 'investment_category';
        goods_ids.value = props.channel
    }
    createVip({
        goods_type: goods_type.value,
        table_name: table_name.value,
        goods_id: goods_ids.value,
    }).then(res => {
        if (res.code === 1) {
            payVip({order_id: res.data.order_id}).then(res1 => {
                if (res1.code === 1) {
                    payimg.value = res1.data.code_url
                    paytype.value = 2
                    const intervalId = setInterval(() => {
                        Vipquery({order_id: res.data.order_id}).then(res2 => {
                            if (res2.code === 1) {
                                if (res2.data.pay_status === 1) {
                                    clearInterval(intervalId); // 停止轮询
                                    location.reload();
                                }
                            }
                        });
                    }, 2000);
                }
            })
        }
    })
}
const cancellation = () => {
    dialogFormVisible.value = false
    paytype.value = 1
}
const payment = (val) => {
    pay_status.value = 1
    dialogFormVisible3.value = false
    dialogFormVisible1.value = true
}

const apply_for = () => {
    if (pay_al_type.value === 2) {
        goods_type.value = 'articles';
        table_name.value = 'pro_diyform';
        goods_ids.value = val
    } else {
        goods_type.value = 'categories';
        table_name.value = 'investment_category';
        goods_ids.value = props.channel
    }
    createVip({
        goods_type: goods_type.value,
        table_name: table_name.value,
        goods_id: goods_ids.value,
        pay_type: 3,
    }).then(res => {
        if (res.code === 1) {
            ElMessage({message: '提交成功,等待审核', type: 'success'})
            dialogFormVisible3.value = false
        }
    })
}

const confirm = (val) => {
    if (pay_status.value === 1) {
        // getVipList().then(res => {
        //     if (res.code === 1) {
        //         vipinfo.value = res.data
        //     }
        // })
        if (pay_al_type.value === 2) {
            goods_type.value = 'articles';
            table_name.value = 'pro_diyform';
            goods_ids.value = val
        } else {
            goods_type.value = 'categories';
            table_name.value = 'investment_category';
            goods_ids.value = props.channel
        }
        goodsShow({
            goods_type: goods_type.value,
            table_name: table_name.value,
            goods_id: goods_ids.value,
        }).then(res => {
            if (res.code === 1) {
                vipinfo.value = res.data
            }
        })
    }
    dialogFormVisible.value = true
    dialogFormVisible1.value = false
}

const exchange = () => {
    pay_status.value = 2
    dialogFormVisible3.value = false
    dialogFormVisible1.value = true
    if (pay_al_type.value === 2) {
        goods_type.value = 'articles';
        table_name.value = 'pro_diyform';
        goods_ids.value = val
    } else {
        goods_type.value = 'categories';
        table_name.value = 'investment_category';
        goods_ids.value = props.channel
    }
    goodsShow({
        goods_type: goods_type.value,
        table_name: table_name.value,
        goods_id: goods_ids.value,
    }).then(res => {
        if (res.code === 1) {
            vipinfo.value = res.data
        }
    })
    getUserDetails().then(res => {
        userinfo.value = res.data
    });
}

const dredge1 = (id) => {
    //差一个积分支付的接口
    let aaaaa = '';
    if (pay_al_type.value === 2) {
        goods_type.value = 'articles';
        table_name.value = 'pro_diyform';
        goods_ids.value = id
    } else {
        goods_type.value = 'categories';
        table_name.value = 'investment_category';
        goods_ids.value = props.channel
    }
    createVip({
        goods_type: goods_type.value,
        table_name: table_name.value,
        goods_id: goods_ids.value,
        pay_type: 2,
    }).then(res => {
        if (res.code === 1) {
            aaaaa = res.data.order_id
            dialogFormVisible3.value = false
            dialogFormVisible.value = false
            scorePay({order_id: aaaaa}).then(res1 => {
                if (res1.code === 1) {
                    ElMessage({message: res1.msg, type: 'success'})
                }
            })
        }
    })
}


const getDate = (type) => {
    window.scrollTo(0, 0);
    fwb_page.value = 1
    title_show.value = 1
  if (type === 'rem') {
    list.value = []
    from.page = 1
    from.time = ''
  }
  loading.value = true
    postGetLists(props.channel,from.keyword)
    plaza_post_sjk({
    category_id: props.channel,
    page: from.page,
    limit: 20,
    time: from.time,
    keyword: from.keyword,
    category_type: 'investment',
    start_time: start_time.value,
    end_time: end_time.value,
  }).then(res => {
    if (res.code === 1) {
        sjklist.value = res.data.investmentList
        //
        // console.log(sjklist.value,888)
        // let datafile = []
        // for (let i = 0; i < sjklist.value.length; i++) {
        //     datafile.push(sjklist.value[i].file_list)
        // }
        // console.log(sjklist.value,datafile,999)
        //
        //

    } else if (res.msg === '尚未开通会员') {
        dialogFormVisible3.value = true
        pay_al_type.value = 1
        start_time.value = ""
        end_time.value = ""
    }
  })
      .finally(() => {
        loading.value = false
      })
}

const getOpacity = (index) => {
    return index === 2 ? 0.2 : 1;
}

const download = (e) => {
    downloadobj(e)
}

const openPreview = (ima) => {
    images1.value = ima
    showPreview.value = true;
}

const closePreview = () => {
    showPreview.value = false;
}

const filetype = (fileName) => {
    const extension = getFileExtension(fileName);
    switch (extension.toLowerCase()) {
        case 'mp3':
            return 1;
        case 'mp4':
            return 2;
        case 'jpg':
            return 3;
        case 'png':
            return 3;
        case 'webp':
            return 3;
        case 'jpeg':
            return 3;
        case 'gif':
            return 3;
        default:
            return 4;
    }
}

const getFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex !== -1) {
        return fileName.slice(lastDotIndex + 1);
    } else {
        return '';
    }
}


const getDate1 = (type) => {
    window.scrollTo(0, 0);
    fwb_page.value = 1
    if (type === 'rem') {
        list.value = []
        from.page = 1
        from.time = ''
    }
    loading.value = true
    postGetLists(7,from.keyword)
    getHomeList({
        category_id: props.channel,
        channels_id: 7,
        page: from.page,
        limit: 20,
        time: from.time,
        keyword: from.keyword,
        category_type: 'investment',
        start_time: start_time.value,
        end_time: end_time.value,
    }).then(res => {
        if (res.code === 1) {
            sjklist.value = res.data.investmentList
            start_time.value = ""
            end_time.value = ""
        }
    })
        .finally(() => {
            loading.value = false
        })
}

const postGetLists = (channels_id,keyword) => {
    postGetList({
        channels_id: channels_id,
        keyword: keyword,
        limit: 10,
        page: fwb_page.value,
    }).then(res => {
        if (res.code === 1){
            if(fwb_page.value === 1){
                postLists.value = res.data.data
            }else{
                postLists.value.push(...res.data.data)
            }
            if(Math.ceil(res.data.total / 10) < fwb_page.value){
                ElMessage({message: '没有更多了', type: 'success'})
                return
            }
        }
    })
}

const loadData = () => {
    if (props.channel == 7) {
        fwb_page.value += 1
        postGetLists(7,from.keyword)
    }else {
        fwb_page.value += 1
        postGetLists(props.channel,from.keyword)
    }
    setTimeout(() => {
        isScrolledToBottom.value = false;
    }, 1000);
}

const scrollFn = (e) => {
    //可视区域
    let clientHeight = document.documentElement.clientHeight
    // 滚动文档高度
    let scrollHeight = document.body.scrollHeight
    // 已滚动的高度
    let scrollTop = parseInt(document.documentElement.scrollTop)
    if ((scrollTop + clientHeight) === scrollHeight && scrollTop >= clientHeight) {
        if (!noMore.value) {
            loadData()
            console.log(clientHeight, scrollHeight, scrollTop, noMore.value);
        }
    }
}

onMounted(() => {
    window.addEventListener("scroll", scrollFn)
})

onUnmounted(() => {
    window.removeEventListener("scroll", scrollFn);
})

defineExpose({getDate,getDate1})
</script>

<style lang="scss" scoped>
.gundong::-webkit-scrollbar {
  display: none;
}
.chatIcon_list_item_info::v-deep {
  width: 100%;
  margin-top: 10px;

  img {
    width: 100%;
  }

  video {
    width: 100%;
  }
}

.textinfo {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.1);
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.file_style {
  text-decoration: none;
  color: #26c3dd;
  cursor: pointer;
}
.chatIcons_list{
  width: 100%;
  margin-top: 10px;
}


.chatIcons {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  padding: 18px;
  box-sizing: border-box;

  .chatIcon_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chatIcon_head_left {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }

    .chatIcon_head_right {
      display: flex;
      align-items: center;

      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;

            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }

      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .chatIcons_sou {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;

    .chatIcons_sou_item {
      display: flex;
      align-items: center;

      input {
        width: 200px;
        height: 25px;
        border: none;
        font-size: 13px;
        color: #FFFFFF;
        padding: 0 5px;
        outline: none;
        box-sizing: border-box;
        background: rgba(50, 78, 125, 0.3);
        margin-right: 10px;

        &::-webkit-input-placeholder {
          color: #ffffff7c;
          font-size: 13px;
        }
      }
    }
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

#rich_text {
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.1em;
}
</style>
