<template>
    <div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="layout">
    <div class="layout_content">
      <!-- v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)" -->
      <div class="chatIcon_list">
        <div class="chatIcon_list_item">
          <div class="chatIcon_list_item_head">
            <img :src="info?.plateInfo?.user?.avatar" alt=""/>
            <span class="head_name">{{ info?.plateInfo?.user?.nickname }}</span>
            <span class="head_time">{{ info?.plateInfo?.create_time }}</span>
          </div>
          <div>
            <div :class="{ 'chatIcon_list_item_info': true}" id="rich_text" v-html="handleMktoHtml(info?.plateInfo?.content.replace(/\n/g, '<br>'))" style="word-wrap: break-word;"></div>
          </div>
          <div>
              <div style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;" v-for="(img, i) in info?.plateInfo?.file_img" :key="i">
                  <img :src="img" alt="" style="width:100%;height:150px;object-fit: cover;" @click="openPreview1(i,info?.plateInfo?.file_img)">
              </div>
              <div v-if="showPreview1" class="modal" @click="closePreview1">
                  <div class="modal-content">
                      <span class="close" @click="closePreview1">&times;</span>
                      <img :src="images11" alt="Preview" style="max-width: 100%; max-height: 100vh;"/>
                  </div>
              </div>
          </div>
          <div v-for="(img, i) in info?.plateInfo?.file_video" :key="i">
              <video :src="img" style="width: 100%;height: 300px;" controls></video>
          </div>
          <div v-for="(img, i) in info?.plateInfo?.file_data" :key="i">
            <a class="file_style" @click="download(img)">{{ img.filename }}</a>
          </div>
          <div v-for="(img, i) in info?.plateInfo?.file_audio" :key="i">
              <audio controls style="width: 100%;">
                  <source :src="img" type="audio/mp3">
              </audio>
          </div>

            <div style="background:rgb(13, 46, 120);padding: 5px;margin-top: 10px;" v-if="info?.plateInfo?.reprint_id != null">
                <div :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }" style="word-wrap: break-word;" v-html="info?.plateInfo?.reprint_data.post_content.replace(/\n/g, '<br>')" id="rich_text"></div>
                <div v-if="info?.plateInfo?.reprint_data.file_img_num > 0" style="height: 150px;overflow: hidden;position: relative">
                    <div v-for="(img, i) in info?.plateInfo?.reprint_data.file_img"
                         :key="i" style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;">
                        <img :src="img" :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'cover' }"
                             alt=""
                             @click="openPreview1(i,info?.plateInfo?.reprint_data.file_img)">
                    </div>
                    <img v-if="info?.plateInfo?.reprint_data.file_img_num >= 3" alt="" src="@/assets/jia.png"
                         style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;object-fit: cover;" @click="jumpDetails(info?.plateInfo?.reprint_data)">
                    <div v-if="showPreview1" class="modal" @click="closePreview1">
                        <div class="modal-content">
                            <span class="close" @click="closePreview1">&times;</span>
                            <img :src="images11" alt="Preview" style="max-width: 100%; max-height: 100vh;"/>
                        </div>
                    </div>
                </div>

                <div v-if="info?.plateInfo?.reprint_data.file_video != []">
                    <div v-for="(img, i) in info?.plateInfo?.reprint_data.file_video" :key="i">
                        <video :src="img" controls style="width: 100%;height: 300px;"></video>
                    </div>
                </div>

                <div v-if="info?.plateInfo?.reprint_data.file_data != []">
                    <div v-for="(img, i) in info?.plateInfo?.reprint_data.file_data" :key="i">
                        <!--                <a class="file_style" :href="img.url" :download="img.filename" @click="downloadobj">{{ img.filename }}</a>-->
                        <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                    </div>
                </div>

                <div v-if="info?.plateInfo?.reprint_data.file_audio != []" style="margin: 10px;">
                    <div v-for="(img, i) in info?.plateInfo?.reprint_data.file_audio" :key="i">
                        <audio controls style="width: 100%;">
                            <source :src="img" type="audio/mp3">
                        </audio>
                    </div>
                </div>
            </div>

          <div class="chatIcon_list_item_pj" v-if="info.commentList && info.commentList.length > 0">
            <div class="chatIcon_list_item_pj_item" v-for="(item, index) in info.commentList" :key="index">
            <div style="background-color: #fff" v-if="item.active">
                <div class="chatIcon_list_item_pj_item_pl">
                    <div class="item_pl_user" style="padding: 6px;color:#000;display: flex;justify-content: space-between;">
                        <span>@{{ item.user.nickname }}</span>
                        <span style="cursor: pointer" @click="closeComment(item)">x</span>
                    </div>
                    <div class="item_pl_content">
                        <textarea class="item_pl_content_textarea pltextarea" v-model="item.text" rows="1" placeholder="请输入内容" :id="'aaaaaa'+item.id" @input="adjustTextareaHeight('aaaaaa'+item.id)"></textarea>
                    </div>
                    <div class="item_pl_caozuo" style="cursor: pointer;margin-top: 5px;">
                        <el-popover
                                placement="bottom"
                                title="请选择表情"
                                :width="300"
                                trigger="click">
                            <template #reference>
                                <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                            </template>
                            <chatIcon @changeText="getIconChild"/>
                        </el-popover>
                        <imageContent :content="item.text" :obj="item" :type="index"
                                      @result="imageTwoContentResult" @change="handleImageSelected"></imageContent>
                        <div class="item_pl_caozuo_btn" @click="sendChildComment(item)">发送</div>
                    </div>
                </div>
                <div v-if="item.comment_image && item.comment_image.length>0" class="comment-image-box">
                    <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in item.comment_image">
                        <img style="width: 80px;height: 80px;" :src="imgitem">
                        <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg1(item.comment_image,index)">
                    </div>
                </div>
            </div>
              <div class="chatIcon_list_item_pj_item_top">
                <div class="pj_item_top_user">
                  <img :src="item.user.avatar" alt=""/>
                  <span>{{ item.user.nickname }}的评论</span>
                </div>
                <div class="pj_item_top_time" style="margin-right: 20px;">
                  <div class="pj_item_top_time_a" @click="handleMouseEnter(index)">
                    <img src="@/assets/pl@2x.png" alt=""/>
                    <span>评论</span>
                  </div>
                    <div class="pj_item_top_time_a" v-if="item.is_author === 1" @click.stop="deleteComment(item.id)">
                       <span>删除</span>
                    </div>
                    <div class="pj_item_top_time_a" v-if="item.is_author === 1" @click.stop="updaComment(item)">
                        <span>修改</span>
                    </div>
                  <div class="pj_item_top_time_b">{{ item.createtime }}</div>
                </div>
              </div>
              <div class="chatIcon_list_item_pj_item_bottom">
                <div v-html="handleMktoHtml(item.content)" style="font-size: 14px;word-wrap: break-word;" class="comment-box"
                     @click="handleClick"></div>
                <span></span>
              </div>
<!--                <div class="chatIcon_list_item_pj_item_list" v-if="pidnum===item.id">-->
                 <div class="chatIcon_list_item_pj_item_list">
<!--                    <div class="chatIcon_list_item_pj_item_list_item" v-for="(child, s) in childernComment" :key="s">-->
                     <div class="chatIcon_list_item_pj_item_list_item" v-for="(child, s) in item.children" :key="s">
                        <!-- 二级评论回复弹窗 -->
                        <div style="background-color: #fff" v-if="child.active">
                            <div class="chatIcon_list_item_pj_item_pl">
                                <div class="item_pl_user" style="padding: 6px;color:#000;display: flex;justify-content: space-between;">
                                    <span>@{{ item.user.nickname }}</span>
                                    <span style="cursor: pointer" @click="child.active = false">x</span>
                                </div>
                                <div class="item_pl_content">
                                    <textarea class="item_pl_content_textarea pltextarea" v-model="child.text" rows="1" placeholder="请输入内容" :id="'bbbbbb'+child.id" @input="adjustTextareaHeight('bbbbbb'+child.id)"></textarea>
                                </div>
                                <div class="item_pl_caozuo" style="cursor: pointer;margin-top: 5px;">
                                    <el-popover
                                            placement="bottom"
                                            title="请选择表情"
                                            :width="300"
                                            trigger="click">
                                        <template #reference>
                                            <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                                        </template>
                                        <chatIcon @changeText="e => getIconChild1(e, s, item.children)"/>
                                    </el-popover>
                                    <imageContent :content="child.text" :obj="s" :type="index" @result="imageTwoContentResult1($event,item.children)" @change="handleImageSelected"></imageContent>
                                    <div class="item_pl_caozuo_btn" @click="sendChildComment(child)">发送</div>
                                </div>
                            </div>
                            <div v-if="child.comment_image && child.comment_image.length>0" class="comment-image-box">
                                <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in child.comment_image">
                                    <img style="width: 80px;height: 80px;" :src="imgitem">
                                    <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg1(child.comment_image,index)">
                                </div>
                            </div>
                        </div>
                        <div class="chatIcon_list_item_pj_item_top" style="margin-left: 10px;">
                            <div class="pj_item_top_user">
                                <img :src="child?.user?.avatar" alt=""/>
                                <span>{{ child?.user?.nickname }}的评论</span>
                            </div>
                            <div class="pj_item_top_time" style="margin-right: 20px;">
                                <div class="pj_item_top_time_a" @click.stop="handleMouseEnter1(s,item.children)">
                                    <img src="@/assets/pl@2x.png" alt=""/>
                                    <span>评论</span>
                                </div>
                                <div class="pj_item_top_time_a" v-if="child.is_author === 1" @click.stop="deleteComment(child.id)">
                                    <span>删除</span>
                                </div>
                                <div class="pj_item_top_time_a" v-if="child.is_author === 1" @click.stop="updaComment(child)">
                                    <span>修改</span>
                                </div>
                                <div class="pj_item_top_time_b">{{ child?.createtime }}</div>
                            </div>
                        </div>
                        <div class="chatIcon_list_item_pj_item_bottom" style="margin-left: 20px;">
                            <div v-html="handleMktoHtml(child?.content)" style="font-size: 14px;word-wrap: break-word;" class="comment-box"></div>
                        </div>

                        <div style="margin-left: 30px;" v-if="child.children.length > 0" v-for="(childs, ss) in child.children" :key="ss">
                          <div class="chatIcon_list_item_pj_item_top" style="margin-top: 10px;margin-bottom: 10px;">
                              <div class="pj_item_top_user">
                                  <img :src="childs?.user?.avatar" alt=""/>
                                  <span>{{ childs?.user?.nickname }}的评论</span>
                              </div>
                              <div class="pj_item_top_time" style="margin-right: 20px;">
                                  <div class="pj_item_top_time_a" @click.stop="handleMouseEnter1(s,child.children)">
                                      <img src="@/assets/pl@2x.png" alt=""/>
                                      <span>评论</span>
                                  </div>
                                  <div class="pj_item_top_time_a" v-if="childs.is_author === 1" @click.stop="deleteComment(childs.id)">
                                      <span>删除</span>
                                  </div>
                                  <div class="pj_item_top_time_a" v-if="childs.is_author === 1" @click.stop="updaComment(childs)">
                                      <span>修改</span>
                                  </div>
                                  <div class="pj_item_top_time_b">{{ childs?.createtime }}</div>
                              </div>
                          </div>
                          <div class="chatIcon_list_item_pj_item_bottom">
                              <div v-html="handleMktoHtml(childs?.content)" style="font-size: 14px;word-wrap: break-word;" class="comment-box"></div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="chatIcon_list_item_pj_more" style="cursor: pointer" @click="getCommentList(item.aid, item.id, item.id,item.children,1)" v-if="item.children_count > 10 && zhanerji === 1"> 展开查看更多 > </div>
                <div class="chatIcon_list_item_pj_more" style="cursor: pointer" v-if="item.children_count > 10 && zhanerji === 2"> 暂无更多评论 </div>
            </div>
              <div style="cursor: pointer" @click="getCommentList_top(info?.plateInfo?.id, 0, 0)" v-if="info.commentList.length > 9 && zhanyiji === 1">查看更多评论</div>
              <div style="cursor: pointer"  v-if="info.commentList.length > 9 && zhanyiji === 2">暂无更多评论</div>
          </div>
          <div class="chatIcon_list_item_footer"  v-if="route.query.status == 1">
            <div class="chatIcon_list_item_footer_left">
<!--              <div class="chatIcon_list_item_footer_left_item" @click="Like()">-->
<!--                <img :src="info?.plateInfo?.is_like == 1 ? require('@/assets/dianz.png') : require('@/assets/zz.png')"-->
<!--                     alt=""/>-->
<!--                <span>{{ info?.plateInfo?.post_like }}</span>-->
<!--              </div>-->
              <div class="chatIcon_list_item_footer_left_item" @click="collect()">
<!--                  <img :src="info?.plateInfo?.is_favorite == 1 ? require('@/assets/shouc.png') : require('@/assets/sc.png')" alt=""/>-->
                  <img :src="info?.plateInfo?.is_favorite == 1 ? require('@/assets/dianz.png') : require('@/assets/zz.png')" alt=""/>
                <span>点赞</span>
              </div>
<!--              <el-popover-->
<!--                  :width="150"-->
<!--                  trigger="hover">-->
<!--                <template #reference>-->
<!--                  <div class="chatIcon_list_item_footer_left_item">-->
<!--                    <img src="@/assets/zf.png" alt=""/>-->
<!--                    <span>转发</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <div style="width: 100%; display: flex; align-items: center;">-->
<!--                  <div @click="shareWeChat"-->
<!--                      style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                    <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt=""/>-->
<!--                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>-->
<!--                  </div>-->
<!--                  <div @click="shareQQ"-->
<!--                       style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                      <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt=""/>-->
<!--                      <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>-->
<!--                  </div>-->
<!--                  <div @click="shareMicroBlog"-->
<!--                      style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                    <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt=""/>-->
<!--                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </el-popover>-->
              <div class="chatIcon_list_item_footer_left_item">
                <img src="@/assets/pl.png" alt=""/>
                <span>评论 {{info?.plateInfo?.comment_count}} </span>
              </div>
            </div>
          </div>
          <div v-if="route.query.status == 1">
            <div class="chatIcon_list_item_pj_item_pl" @click.stop>
                <div class="item_pl_content">
                    <textarea class="item_pl_content_textarea pltextarea"
                              v-model="from.text"
                              rows="1" placeholder="欢迎评论" @input="adjustTextareaHeight('aaaaaa')"
                              id='aaaaaa'></textarea>
                </div>
                <div class="item_pl_caozuo" style="cursor: pointer">
                    <el-popover
                            placement="bottom"
                            title="请选择表情"
                            :width="300"
                            trigger="click">
                        <template #reference>
                            <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                        </template>
                        <chatIcon @changeText="getIcon"/>
                    </el-popover>
                    <imageContent :content="from.text" @result="imageContentResult1" @change="handleImageSelected"></imageContent>
                    <div class="item_pl_caozuo_btn" @click="sendCommentVal(info?.plateInfo?.id,from.text,info?.plateInfo)">发送</div>
                </div>
            </div>
            <div v-if="comment_image && comment_image.length>0" class="comment-image-box">
                <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in comment_image">
                    <img style="width: 80px;height: 80px;" :src="imgitem">
                    <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg(index)">
                </div>
            </div>
          </div>
        </div>
      </div>
        <div style="background-color: #fff;position: fixed;top:50%;left:50%;transform: translate(-50%, -50%);width: 900px;" v-if="aaa">
            <div class="item_pl_user" style="display: flex;justify-content: space-between;color: #000;padding: 10px;">
                <span> 修改内容 </span>
                <span style="cursor: pointer" @click.stop="closeComment1()">X</span>
            </div>
            <div class="chatIcon_list_item_pj_item_pl" @click.stop style="margin-left:8px;">
                <div class="item_pl_content">
                    <textarea class="item_pl_content_textarea pltextarea"
                              v-model="update_text"
                              rows="1" placeholder="请输入内容" @input="adjustTextareaHeight('cccccc')"
                              id='cccccc'></textarea>
                </div>
                <div class="item_pl_caozuo" style="cursor: pointer">
                    <el-popover
                            placement="bottom"
                            title="请选择表情"
                            :width="300"
                            trigger="click">
                        <template #reference>
                            <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                        </template>
                        <chatIcon @changeText="getIcon1"/>
                    </el-popover>
                    <imageContent :content="from.text" @result="imageContentResult2" @change="handleImageSelected"></imageContent>
                    <div class="item_pl_caozuo_btn" @click="updateCommentVal()">修改</div>
                </div>
            </div>
            <div v-if="update_image && update_image.length>0" class="comment-image-box">
                <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in update_image">
                    <img style="width: 80px;height: 80px;" :src="imgitem">
                    <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg1(update_image)">
                </div>
            </div>
        </div>
    </div>
      <vue-easy-lightbox
              :imgs="images"
              :index="image_index"
              :visible="visible"
              @hide="handleClose"
              :minZoom="0.5"
              :zoomScale="zoomScale_index"
              :maxZoom="zoomScale_index"
              :on-index-change="index_change"
      ></vue-easy-lightbox>
  </div>
    </div>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router';
import {ref, reactive, onMounted, defineProps} from 'vue';
import {
    sendComment,
    show,
    addLive,
    delLive,
    addFavorite,
    delFavorite,
    addComment,
    getComment,
    delComment,
    updateComment,
    grapevine,
    grapeshow
} from '@/api'
import chatIcon from '@/components/chatIcon.vue'
import {ElMessage} from 'element-plus'
import {fromBase64,toBase64,downloadobj} from "@/utils/utils";
import imageContent from '@/components/imageContent.vue'
import {marked} from 'marked';

import {openImagePreview} from "@/components/openImagePreview";
const { visible, images, handleShow, handleClose, image_index, zoomScale_index } = openImagePreview();


const props = defineProps({
    title: String,  // 栏目标题
    channel: Number,     // 栏目id
    timeVal: Number,   // 刷新时间
    create_time: String,     // 创建时间
    typeView: String | Number    // 1 首页广场   5 新闻资讯    3 债市论坛
})

const route = useRoute()
const router = useRouter()

let from = reactive({
  index: 0,
  text: ''
})
// const qc = fromBase64(route.query.id)
const qc = route.query.id
const content = JSON.parse(qc)

const getOpacity = (index) => {
    return index === 2 ? 0.2 : 1;
}

// 跳转详情
const jumpDetails = (item) => {
    const newObj = (({category_type, comment_type, id, table_name}) => ({
        category_type,
        comment_type,
        id,
        table_name
    }))(item);
    const content = toBase64(JSON.stringify(newObj))
    if (item.channel_pid == 5) {
        const routeUrl = router.resolve({
            path: "/home/newDetails",
            query: {id: item.id, type: props.typeView, content: content}
        });
        window.open(routeUrl.href, "_blank");
    } else {
        const routeUrl = router.resolve({
            path: "/home/forumDetails",
            // query: {id: item.id, comment_type:item.comment_type}
            query: {id: item.id, type: item.category_type, comment_type: item.comment_type,status: '1'}
        });
        window.open(routeUrl.href, "_blank");
    }
}

let images1 = ref([])
let showPreview = ref(false)
let aaa = ref(false)
let update_info = ref(0)
let update_text = ref('')
let update_image = ref([])
let initialZoomScale = ref('')
let currentImageIndex = ref(0)
let loading = ref( false)
let showPreview1 = ref(false)
let images11 = ref('')
let info = ref({})
// let visible = ref(false)
// let images = ref([])
let active = ref(false)
let childernComment = ref([])
let comment_image = ref([])
let pages = ref(1)
let pages1 = ref(2)
let pidnum = ref(0)
let zhanyiji = ref(1)
let zhanerji = ref(1)

const openPreview1 = (index,ima) => {
    const arr = {index,ima}
    // images11.value = ima
    // showPreview1.value = true;
    // emit('openPreview', ima)
    handleShow(arr)
}
const index_change = (oldIndex, newIndex) => {
    image_index.value = newIndex
    const imgs = images.value[newIndex]
    const img = new Image();
    img.src = imgs;
    img.onload = () => {
        console.log('远程图片宽度为：' + img.width);
        console.log('远程图片高度为：' + img.height);
        const bl = (img.width < img.height) ? Math.ceil(img.height / img.width) : Math.ceil(img.width / img.height)
        if (bl < 5) {
            zoomScale_index.value = 2
        } else {
            zoomScale_index.value = bl
        }
        console.log('比例：' + bl);
    };
}

const closePreview1 = () => {
    showPreview1.value = false;
}

const handleMouseEnter = (index) => {
    info.value.commentList.forEach((row, i) => {
        row.text = ''
        row.active = i === index
    })
}

const openPreview = (index,ima) => {
    images1.value = ima
    showPreview.value = true;
    currentImageIndex.value = index;
}

const closePreview = (index) => {
    showPreview.value = false;
}
const handleMouseEnter1 = (index,item) => {
    item.forEach((row, i) => {
        row.text = ''
        row.active = i === index
    })
}

const deleteComment = (id) => {
    delComment({id:id}).then(res => {
        if(res.code === 1){
            ElMessage({message: res.msg, type: 'success'})
            router.go(0);
        }
    })
}

// 分享到QQ
const shareQQ = () => {
    var url = window.location.href;
    var encodedUrl = encodeURIComponent(url);
    var shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?url=' + encodedUrl;
    window.open(shareUrl);
}
// 分享到微博
const shareMicroBlog = () => {
    let message = String(location.href)
    var url = 'https://service.weibo.com/share/share.php?url=' + encodeURIComponent(message) + '&appkey=MrShi&title=测试标题&language=zh_cn'
    window.open(url)
}
//分享到微信
const shareWeChat = () => {
    var url = window.location.href;
    var encodedUrl = encodeURIComponent(url);
    var qrcodeUrl = 'https://login.weixin.qq.com?url=' + encodedUrl;
    window.open(qrcodeUrl)
};

const handleMktoHtml = (e) => {
  return e !== undefined ? marked(e) : ''
}
// 子级评论选择表情
const getIconChild = (e) => {
  info.value.commentList[from.index].text += e
}

const getIconChild1 = (e,index,item) => {
    item[index].text += e
    // info.value.commentList[from.index].text += e
    // childernComment
}

// 子级评论选择表情
const beforeIconChild = (a) => {
  from.index = a
}

const closeComment = (item) => {
  item.active = false
  item.text = ''
}

const closeComment1 = (item) => {
    aaa.value = false
}

const getCommentList = (aid, pid ,top_pid,item,sta) => {
    if(sta !== 2){
        let page = 1 + pages.value
        getComment({type:route.query.comment_type, aid, pid, orderway: 'desc', page, limit: 10, top_pid})
            .then(res => {
                if (res.code === 1) {
                    // pages.value += 1
                    pidnum.value = res.data.pid
                    if (pid === pidnum.value){
                        pages.value += res.data.current_page
                        const newData = res.data.list;
                        childernComment.value.push(...newData);
                        item.push(...newData);
                        if (page*10>res.data.total){
                            zhanerji.value = 2
                            pages.value = 0
                        }
                    }else{
                        pages.value = 0
                        childernComment.value = res.data.list
                        item = res.data.list
                        zhanerji.value = 1
                    }
                }
            })
    }else{
        console.log(sta,item,111)
        // const arrayFromProxy = Object.values(item).slice(0, 10);
        // const actualArray = arrayFromProxy.map(proxy => proxy.target || proxy);
        //
        // console.log(actualArray)
        item = []
        console.log(sta,item,222)
        zhanerji.value = 1
    }
}

const getCommentList_top = (aid, pid ,top_pid) => {
    let page = pages1.value ++
    getComment({type:route.query.comment_type, aid, pid, orderway: 'desc', page, limit: 10, top_pid})
        .then(res => {
            if (res.code === 1) {
                // pages.value += 1
                pidnum.value = res.data.pid
                if (page === 1){
                    // pages.value += res.data.current_page
                    // info.commentList.value.push(...newData);
                    info.value.commentList = res.data.list
                    if (res.data.list.length === 0){
                        zhanyiji.value = 2
                    }
                }else{
                    const newData = res.data.list;
                    if (res.data.list.length === 0){
                        zhanyiji.value = 2
                    }
                    info.value.commentList.push(...newData)
                }
            }
        })
}

// 点赞
const Like = () => {
  if (info.value.plateInfo.is_like == 1) {
    delLive({table_name: 'plate_post', object_id: route.query.id})
        .then(res => {
          if (res.code === 1) {
            info.value.plateInfo.is_like = 0
            info.value.plateInfo.post_like -= 1
          }
        })
  } else {
    addLive({table_name: 'plate_post', object_id: route.query.id})
        .then(res => {
          if (res.code === 1) {
            info.value.plateInfo.is_like = 1
            info.value.plateInfo.post_like += 1
          }
        })
  }
}

// 收藏
const collect = () => {
  if (info.value.plateInfo.is_favorite == 1) {
    delFavorite({table_name: 'plate_post', object_id: route.query.id})
        .then(res => {
          if (res.code === 1) {
            info.value.plateInfo.is_favorite = 0
          }
        })
  } else {
    addFavorite({table_name: 'plate_post', object_id: route.query.id})
        .then(res => {
          if (res.code === 1) {
            info.value.plateInfo.is_favorite = 1
          }
        })
  }
}

// 父级发送评论
const sendCommentVal = () => {
    let content = from.text
    // if(content.length > 140){
    //     ElMessage({message: '字数不能超过140', type: 'error'})
    //     return
    // }
    if (comment_image.value && comment_image.value.length > 0) {
        for (let i = 0; i < comment_image.value.length; i++) {
            content += `![Image Description](${comment_image.value[i]})`
        }
    }
  if (!from.text) return ElMessage({
    message: '请先输入评论内容',
    grouping: true,
    type: 'warning',
  })
  addComment({
    type: route.query.comment_type,
    aid: info.value.plateInfo.id,
    pid: 0,
    content: content,
    image: comment_image.value,
    con: from.text,
  }).then(res => {
    if (res.code === 1) {
      router.go(0);
    }
  })
}
//修改评论
const updateCommentVal = () => {
    let content = update_text.value
    if (update_image.value && update_image.value.length > 0) {
        for (let i = 0; i < update_image.value.length; i++) {
            content += `![Image Description](${update_image.value[i]})`
        }
    }
    updateComment({id:update_info.value,content:content,con:update_text.value,image:update_image.value}).then(res => {
        if(res.code === 1){
            ElMessage({message: res.msg, type: 'success'})
            aaa.value = false
            update_text.value = ''
            update_image.value = []
            update_info.value = 0
            router.go(0);
        }
    })
}
const updaComment = (index) => {
    update_info.value = index.id
    if(index.content_files == ''){
        update_image.value = []
    }else{
        update_image.value = index.content_files.split(',')
    }
    update_text.value = index.content_text
    aaa.value = true
}
const adjustTextareaHeight = (id) => {
    // 动态调整textarea高度的方法，可以根据实际需求进行调整
    // 例如，可以根据内容的行数来动态设置高度
    const textarea = document.getElementById(id)
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
}
const imageContentResult1 = (e) => {
    loading.value = false;
    let str = e.type;
    if(comment_image.value.length < 3){
        comment_image.value.push(e.response)
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const imageContentResult2 = (e) => {
    loading.value = false;
    if(update_image.value.length < 3){
        update_image.value.push(e.response)
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const handleImageSelected = (e) => {
    loading.value = true;
}
const delimg = (e) => {
    comment_image.value.splice(e, 1);
}
const delimg1 = (item,e) => {
    item.splice(e, 1);
}

// 父级评论选择表情
const getIcon = (e) => {
  from.text += e
}
//修改内容选择表情
const getIcon1 = (e) => {
    update_text.value += e
}

// 发送子级评论
const sendChildComment = (item) => {
    let content = item.text
    // if(content.length > 140){
    //     ElMessage({message: '字数不能超过140', type: 'error'})
    //     return
    // }
    if (item.comment_image && item.comment_image.length > 0) {
        for (let i = 0; i < item.comment_image.length; i++) {
            content += `![Image Description](${item.comment_image[i]})`
        }
    }
  if (!item.text) return ElMessage({
    message: '请先输入评论内容',
    grouping: true,
    type: 'warning',
  })
  addComment({
    type: route.query.comment_type,
    aid: info.value.plateInfo.id,
    pid: item.id,
    content: content,
    top_pid: item.id,
    image: item.comment_image,
    con: item.text,
  }).then(res => {
    if (res.code === 1) {
      router.go(0);
    }
  })
}
const imageTwoContentResult = (e) => {
    loading.value = false;
    let str = e.type;
    info.value.commentList[str].comment_image = info.value.commentList[str].comment_image ?? [];
    if(info.value.commentList[str].comment_image.length < 3){
        info.value.commentList[str].comment_image.push(e.response);
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const imageTwoContentResult1 = (e,item) => {
    loading.value = false;
    let str = e.type;
    item[e.item].comment_image = item[e.item].comment_image ?? [];
    if (item[e.item].comment_image === ''){
        item[e.item].comment_image = []
    }
    if(item[e.item].comment_image.length < 3){
        item[e.item].comment_image.push(e.response);
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
// const handleClick = (e) => {
//   if (e.target.tagName === 'IMG') {
//     // 获取 img 标签的 src 属性
//     images.value = [e.target.src]
//     visible.value = true
//   }
// }
// const handleHide = () => {
//   images.value = []
//   visible.value = false
// }
const download = (e) => {
    downloadobj(e)
}

const updateTitleAndFavicon = () => {
    document.title = '复盘';
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
        favicon.href = 'https://video-source.obs.cn-north-4.myhuaweicloud.com/img/default_user.jpg';
    }
}
const updateTitleAndFavicon1 = () => {
    document.title = '复盘 - 帖子详情';
}

onMounted(() => {
    if(route.query.status === '2'){
        grapeshow({id: route.query.id})
            .then(res => {
                if (res.code === 1) {
                    info.value = res.data
                    info.value.commentList = []
                }
            })
    }else{
        show({category_type: route.query.type, id: route.query.id})
            .then(res => {
                if (res.code === 1) {
                    info.value = res.data
                    info.value.commentList.forEach(item => {
                        item.text = ''
                    })
                }
            })
    }
    updateTitleAndFavicon()
    updateTitleAndFavicon1()
  // getNewsDetail(route.query.id)
  //     .then(res => {
  //         if (res.code === 1) {
  //             info.value = res.data
  //             info.value.commentList.forEach(item => {
  //                 item.text = ''
  //             })
  //         }
  //     })
})
</script>
<style>
.comment-box img {
  width: 100px;
}
</style>
<style lang="scss" scoped>
#rich_text{
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.1em;
}
.file_style{
  text-decoration: none;
  color: #26c3dd;
  cursor: pointer;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.layout {
  width: 100%;
  min-width: 975px;
  min-height: 100vh;
  background-color: #0d2e64;
  display: flex;
  justify-content: center;
  position: relative;

  .layout_content {
    width: 975px;
    padding: 20px 0;
    box-sizing: border-box;
    height: auto;
    color: azure;

    .chatIcon_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: rgb(11 37 79);
      border-radius: 6px;

      .chatIcon_list_item {
        padding: 20px;
        display: flex;
        flex-direction: column;

        .chatIcon_list_item_head {
          width: 100%;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 50%;
          }

          .head_name {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            padding-right: 9px;
            margin-right: 9px;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
          }

          .head_time {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.5);
          }
        }

        .chatIcon_list_item_info::v-deep {
          width: 100%;
          margin-top: 15px;

          span {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            font-size: 16px;
            font-family: Source Han Sans CN;
            // font-weight: bold;
            &:first-child {
              color: #FFFFFF;
            }

            &:last-child {
              cursor: pointer;
              color: rgba(7, 243, 252, 1);
            }
          }

          img {
            width: 100%;
          }
        }

        .chatIcon_list_item_pj {
          width: 100%;
          margin-top: 15px;

          .chatIcon_list_item_pj_item {
            width: 100%;
            padding: 12px 14px;
            box-sizing: border-box;
            background: #094074;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            margin-bottom: 9px;
            position: relative;

            &:last-child {
              margin-bottom: 0 !important;
            }



            .chatIcon_list_item_pj_item_top {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .pj_item_top_user {
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  margin-right: 6px;
                }

                span {
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #FFFFFF;
                }
              }

              .pj_item_top_time {
                display: flex;
                align-items: center;

                .pj_item_top_time_a {
                  display: flex;
                  align-items: center;
                  padding-right: 6px;
                  margin-right: 6px;
                  cursor: pointer;
                  border-right: 2px solid rgba(255, 255, 255, 0.3);

                  img {
                    width: 13px;
                    height: 13px;
                    margin-right: 3px;
                  }

                  span {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FCB407;
                  }
                }

                .pj_item_top_time_b {
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #FFFFFF;
                }
              }
            }

            .chatIcon_list_item_pj_item_bottom {
              margin-top: 15px;

              span {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 16px;

                &:first-child {
                  color: #FFFFFF;
                }

                &:last-child {
                  cursor: pointer;
                  color: rgba(7, 243, 252, 1);
                }
              }
            }
          }
        }

        .chatIcon_list_item_footer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 17px;
          cursor: auto;

          .chatIcon_list_item_footer_left {
            width: 280px;
            display: flex;
            align-items: center;

            .chatIcon_list_item_footer_left_item {
              flex: 1;
              display: flex;
              align-items: center;
              vertical-align: middle;
              cursor: pointer;

              img {
                width: 15px;
                height: 14px;
                margin-right: 4px;
              }

              span {
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }
            .chatIcon_list_item_footer_right {
              width: 195px;
              height: 23px;
              padding: 0 1px 0 13px;
              box-sizing: border-box;
              background: #EAF5FF;
              border-radius: 11px;
              margin-left: 21px;
              flex-shrink: 0;
              display: flex;
              align-items: center;

              .inputText {
                flex: 1;
                height: 100%;

                input::-webkit-input-placeholder {
                  font-size: 9px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  color: #0361BA;
                }

                input {
                  font-size: 9px;
                  color: #222222;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0);
                  border: none;
                  outline: none;
                }
              }

              .caozuo {
                flex-shrink: 0;
                display: flex;
                align-items: center;

                img {
                  width: 13px;
                  height: 13px;
                  margin-right: 8px;
                  cursor: pointer;
                }

                .caozuo_btn {
                  width: 42px;
                  height: 20px;
                  line-height: 20px;
                  text-align: center;
                  background: linear-gradient(0deg, #085EAF, #2791F4);
                  border-radius: 10px;
                  font-size: 9px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #FFFFFF;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.chatIcon_list_item_pj_item_pl {
  width: 100%;
  position: relative;
  margin-top: 12px;

  .item_pl_content {
    width: 100%;

    textarea {
      width: 95%;
      border-radius: 30px;
      height: 15px;
      //margin-top: 20px;
      padding: 6px 10px;
      resize: none;
      background: #ebf4fd;
      color: #709cd2;
    }

    textarea:focus {
      outline: none;
    }
  }

  .item_pl_caozuo {
    display: flex;
    position: absolute;
    bottom: 15%;
    right: 3%;

    .item_pl_caozuo_img {
      width: 21px;
      height: 21px;
    }

    .item_pl_caozuo_btn {
      width: 42px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 10px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
</style>
