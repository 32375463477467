import {ref} from "vue";

export function openImagePreview() {
    const visible = ref(false)
    const images = ref([])
    const image_index = ref(0)
    const zoomScale_index = ref(0)

    function handleShow(index) {
        images.value = index.ima;
        image_index.value = index.index
        const img = new Image();
        img.src = images.value[image_index.value];
        img.onload = () => {
            console.log('远程图片宽度为：' + img.width);
            console.log('远程图片高度为：' + img.height);
            const bl = (img.width < img.height) ? Math.ceil(img.height / img.width) : Math.ceil(img.width / img.height)
            if (bl < 5) {
                zoomScale_index.value = 2
            } else {
                zoomScale_index.value = bl
            }
            console.log('比例：' + bl);
        };
        visible.value = true
        // console.log('父组件传递的图片列表1',images.value,image_index.value,zoomScale_index.value)
    }

    function handleClose() {
        visible.value = false
        console.log('关闭图片预览')
    }

    return {visible, images, handleShow, handleClose, image_index, zoomScale_index}
}
