<template>
    <div class="calendar">
        <div class="header">
            <img class="header-left" src="@/assets/1n@2x.png" @click="prevY" />
            <img class="header-left1" src="@/assets/j5@2x.png" @click="prevMonth" />
            <div class="title">{{ title }}</div>
            <img class="header-right1" src="@/assets/j5@2x.png" @click="nextMonth" />
            <img class="header-right" src="@/assets/1n@2x.png" @click="nextY" />
        </div>
        <div class="weekdays">
            <div v-for="day in daysOfWeek" :key="day" class="day">{{ day }}</div>
        </div>
        <div class="days">
            <div v-for="day in days" :key="day.date" :class="{
                dayItem: true,
                // today: isToday(day),
                today: isActive(day),
                notCurrentMonth: isNotCurrentMonth(day),
            }" @click="select(day)">
                <span>{{ day.day }}</span>
                <img class="activeImg" v-if="isSelected(day)" src="@/assets/ac@2x.png" alt="" />
            </div>
        </div>
    </div>
</template>
   
<script>
import { ref, computed } from "vue";

export default {
    name: "FeiCalendar",
    props: {
        selectedDate: Date,
        activeList: Array
    },
    emits: ["update:selectedDate", "update:change"],
    setup(props, { emit }) {
        const weekdays = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
        const currentDate = ref(new Date());
        const selectedDate = ref(props.selectedDate || currentDate.value);

        const daysOfWeek = computed(() => {
            return weekdays;
        });

        const days = computed(() => {
            const year = currentDate.value.getFullYear();
            const month = currentDate.value.getMonth();
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const daysInLastMonth = new Date(year, month, 0).getDate();
            const firstDayOfMonth = new Date(year, month, 1).getDay();

            const days = [];
            let day = 1;
            let lastMonthDay = daysInLastMonth - firstDayOfMonth + 1;
            let nextMonthDay = 1;

            for (let i = 0; i < 6 * 7; i++) {
                if (i < firstDayOfMonth) {
                    days.push({
                        date: new Date(year, month - 1, lastMonthDay),
                        day: lastMonthDay,
                        isLastMonth: true,
                        isNextMonth: false,
                    });
                    lastMonthDay++;
                } else if (i >= firstDayOfMonth + daysInMonth) {
                    days.push({
                        date: new Date(year, month + 1, nextMonthDay),
                        day: nextMonthDay,
                        isLastMonth: false,
                        isNextMonth: true,
                    });
                    nextMonthDay++;
                } else {
                    const date = new Date(year, month, day);
                    days.push({ date, day, isLastMonth: false, isNextMonth: false });
                    day++;
                }
            }

            return days;
        });

        const title = computed(
            () =>
                `${currentDate.value.toLocaleString("default", {
                    month: "long",
                })} ${currentDate.value.getFullYear()}`
        );

        const prevMonth = () => {
            currentDate.value = new Date(
                currentDate.value.getFullYear(),
                currentDate.value.getMonth() - 1,
                1
            );
            emit("update:change", currentDate.value);
        };

        const prevY = () => {
            currentDate.value = new Date(
                currentDate.value.getFullYear() -1,
                currentDate.value.getMonth(),
                1
            );
            emit("update:change", currentDate.value);
        };

        const nextMonth = () => {
            currentDate.value = new Date(
                currentDate.value.getFullYear(),
                currentDate.value.getMonth() + 1,
                1
            );
            emit("update:change", currentDate.value);
        };

        const nextY = () => {
            currentDate.value = new Date(
                currentDate.value.getFullYear() + 1,
                currentDate.value.getMonth(),
                1
            );
            emit("update:change", currentDate.value);
        };

        const isToday = (day) => {
            const today = new Date();
            return day.date.toDateString() === today.toDateString();
        };

        const isActive = (day) => {
            for (let i = 0; i < props.activeList.length; i++) {
                if (new Date(props.activeList[i]).toDateString() === day.date.toDateString()) {
                    return true
                }
            }
        };

        const isSelected = (day) => {
            return day.date.toDateString() === selectedDate.value.toDateString();
        };

        const isNotCurrentMonth = (day) => {
            return day.isLastMonth || day.isNextMonth;
        };

        const select = (day) => {
            selectedDate.value = day.date;
            emit("update:selectedDate", day.date);
        };

        return {
            daysOfWeek,
            days,
            title,
            prevMonth,
            prevY,
            nextY,
            nextMonth,
            isToday,
            isSelected,
            isNotCurrentMonth,
            select,
            isActive
        };
    },
};
</script>
   
<style>
.calendar {
    max-width: 500px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 41px;
    box-sizing: border-box;
    margin-bottom: 5px;
}

.header img {
    width: 9px;
    height: 11px;
    cursor: pointer;
}

.header-right {
    transform: rotate(180deg);
}

.header-right1 {
    transform: rotate(180deg);
}

.title {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
}

.weekdays {
    display: flex;
    justify-content: space-around;
}

.dayItem {
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.dayItem span {
    color: #FFFFFF;
    font-size: 10px;
}

.activeImg {
    position: absolute;
    top: 0;
    left: 5px;
    width: 20px;
    height: 20px;
}

.day {
    width: 30px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
}

.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 5px;
}

.today span {
    color: #00D5D8 !important;
}

.selected {
    background-color: blue;
    color: white;
}

.notCurrentMonth {
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(72, 105, 141, 1);
}
</style>