<template>
      <div class="image-content" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <img src="@/assets/image.png" alt="图片上传" @click="open(1)"/>
        <!-- 上传图片 -->
        <input type="file" style="display: none;" accept=".jpg,.png,.JPG,.JPEG,.PNG" ref="Img" @change="uploads"/>
      </div>
</template>

<script setup>
import {ref, reactive, defineProps, defineEmits, createApp } from 'vue'
import {upload} from '@/api'
let loading = ref( false)

let props = defineProps({
  content: String,
  obj: Object,
  type: String | Number
})
let from = reactive({
  message: '',
  fileImgs: [],
  fileVideos: [],
  file: []
})
let emit = defineEmits(['result'])
let typeFile = ref(1)
const Img = ref(null)   // 上传图片

const open = (type) => {
  typeFile.value = type
  if (type == 1) {
    Img.value.click()
  }
}

const uploads = (event) => {
    loading.value = false;
  const formdate = new FormData()
  formdate.append('file', event.target.files[0])
  uploadFile(formdate)
}

const uploadFile = async (formdate) => {
  const res = await upload(formdate)
  if (res.code === 1) {
    if (typeFile.value === 1) {
        loading.value = false;
      from.fileImgs.push(res.data)
      handleUploadSuccess(res.data.fullurl, 'image')
    }
  }
  // 清空input值
  Img.value.value = ''

}

const handleUploadSuccess = (response, fileType, action = 1) => {
  let content = props.content
  let item = props.obj
  let type = props.type

  emit('result', {content, item, type, response})
}
</script>
<style lang="scss" scoped>
img {
  width: 21px;
  height: 21px;
  margin: 0px 6px;
}

.image-content {
  /* Add your full-screen styles here */
  //position: fixed;
  //top: 0;
  //left: 0;
  //width: 100%;
  //height: 100%;
  //background: rgba(0, 0, 0, 0.8);
  //z-index: 999; /* Adjust z-index as needed */
  /* Add other styles as needed */
}
</style>
