<template>
  <div class="layout">
    <div class="layout_content">
      <div class="chatIcon_list">
          <div class="right_day_head">
              <div class="right_day_head_left">
                  <div style="display: flex;align-items: center;cursor: pointer;">
                      <img src="@/assets/jian@2x.png" alt=""/>
                      <span>小道消息</span>
                  </div>
              </div>
          </div>
        <div class="chatIcon_list_item">
            <div class="right_day_list_item" v-for="(item, index) in message" :key="index"
                 @click="jumpInfo(item)">
                <img class="right_day_list_item_icon" src="@/assets/wb.png" alt=""/>
                <div class="right_day_list_item_wz">
                    {{ timestampToTime(item.createtime, 1) }} {{ item.title }}
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router';
import {ref, reactive, onMounted, defineProps} from 'vue';
import {
    grapevine,
} from '@/api'
import {ElMessage} from 'element-plus'
import {fromBase64, toBase64, downloadobj, timestampToTime} from "@/utils/utils";
import {marked} from 'marked';
import {openImagePreview} from "@/components/openImagePreview";
const { visible, images, handleShow, handleClose, image_index, zoomScale_index } = openImagePreview();


let message = ref([])

const jumpInfo = (item) => {
    window.open(item.form_url, "_blank");
}
const getgrapevine = () => {
    grapevine()
        .then(res => {
            if (res.code === 1) {
                message.value = res.data.list
            }
        })
}

const updateTitleAndFavicon = () => {
    document.title = '复盘';
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
        favicon.href = 'https://video-source.obs.cn-north-4.myhuaweicloud.com/img/default_user.jpg';
    }
}
const updateTitleAndFavicon1 = () => {
    document.title = '复盘 - 小道消息';
}


onMounted(() => {
    updateTitleAndFavicon()
    updateTitleAndFavicon1()
    getgrapevine()
})
</script>
<style>
.comment-box img {
  width: 100px;
}
</style>
<style lang="scss" scoped>
#rich_text{
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.1em;
}
.file_style{
  text-decoration: none;
  color: #26c3dd;
  cursor: pointer;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.layout {
  width: 100%;
  min-width: 975px;
  min-height: 100vh;
  background-color: #0d2e64;
  display: flex;
  justify-content: center;
  position: relative;

  .layout_content {
    width: 975px;
    padding: 20px 0;
    box-sizing: border-box;
    height: auto;
    color: azure;

    .chatIcon_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: rgb(11 37 79);
      border-radius: 6px;

      .chatIcon_list_item {
        padding: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.right_day_list_item {
  width: 100%;
  height: 21px;
  display: flex;
  align-items: center;
  padding: 0 5px 0 0;
  margin: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  transition: .3s;
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.1em;
  font-weight: bold;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;

    .right_day_list_item_wz {
      transition: .3s;
      color: rgba(255, 255, 255, 1) !important;
    }
  }

  .right_day_list_item_tps {
    flex-shrink: 0;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E556FF;
  }

  .right_day_list_item_wz {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(132, 149, 179, 1);
  }

  .right_day_list_item_icon {
    width: 15px;
    height: 13px;
    margin-right: 9px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .right_day_list_item_play {
    flex-shrink: 0;
    width: 13px;
    height: 13px;
    margin-left: 5px;
  }
}
.right_day_head {
  width: 100%;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right_day_head_left {
    padding: 20px 0 0 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 16px;
      height: 10px;
      margin-right: 8px;
    }

    span {
      line-height: 23px;
      font-size: 13px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #00D5D8;
    }
  }
}
</style>
