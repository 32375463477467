<template>
  <div v-loading="loading1" class="image-content" element-loading-background="rgba(0, 0, 0, 0.8)"
       element-loading-spinner="el-icon-loading" element-loading-text="拼命加载中">
    <div class="chatIcons">
      <!-- 刷新 -->
      <div class="chatIcon_head">
        <div class="chatIcon_head_left">
          <img alt="" src="@/assets/jian@2x.png"/>

            <span v-if="title_show === 2" style="cursor: pointer" @click="title_fun">{{title_text}}</span>
            <span v-else>
                <span v-if="props.channel == 1" style="cursor: pointer" @click="getNewList2('rem')">{{props.title}}</span>
                <span v-else-if="props.channel == 3" style="cursor: pointer" @click="title_fun">{{ props.title }}</span>
                <span v-else>
                    <span style="cursor: pointer;" @click="title_fun">{{ props.title.slice(0, 4) }}</span>
                    -
                    <span style="cursor: pointer;" @click="getNewList('rem')">{{ props.title1 }}</span>
                </span>
            </span>
        </div>
        <div class="chatIcon_head_right">
          <span><el-countdown :value="props.timeVal" @finish="finish"/>后自动更新</span>
          <div class="chatIcon_head_right_btn" @click="shuaxin">
            <img alt="" src="@/assets/update@2x.png"/>
            <span>手动更新</span>
          </div>
        </div>
      </div>

      <!-- 操作 -->
      <div class="chatIcon_operate">
<!--        <div class="layout_content_picture_item_head_sele">-->
<!--          <span>{{ from.sortName }}</span>-->
<!--          <img alt="" src="@/assets/dwon@2x.png"/>-->
<!--          <div class="sele_menu">-->
<!--            <div v-for="(item, index) in sort" :key="index" class="sele_menu_item" @click="clickSort(item)">-->
<!--              {{-->
<!--                item.name-->
<!--              }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="chatIcon_operate_cate">
          <div v-for="(item, index) in dateType"
               :key="index"
               :class="item.active ? 'chatIcon_operate_cate_item active' : 'chatIcon_operate_cate_item'"
               @click="clickDate(index, item.id)">{{ item.name }}
          </div>
          <div class="block">
            <el-date-picker
                v-model="value1"
                end-placeholder="结束日期"
                range-separator="至"
                size="small"
                start-placeholder="开始日期"
                style="width: 240px;"
                type="daterange"
                @change="date_range">
            </el-date-picker>
          </div>
        </div>
        <div class="chatIcon_operate_w" @click="openProp" style="margin-top: 8px;">
          <div style="position: relative">
            <img alt="" src="@/assets/wdbg.png" style="width: 48px">
            <div style="position: absolute;top: 3px;text-align: center;width: 52px;letter-spacing: 5px;font-size: 13px;font-weight: 900;">
              问答
            </div>
          </div>
        </div>
      </div>
      <div class="chatIcon_list">
        <div v-for="(item, index) in from.list" :key="index" class="chatIcon_list_item"
             style="position: relative">
          <div v-if="item.table_name =='plate_post'">
            <div class="chatIcon_list_item_head">
              <img :src="item.avatar" alt=""/>
              <span class="head_name">{{ item.username }}</span>
              <span class="head_time">{{ item.create_time }}</span>
            </div>
            <div class="right-option">
              <div v-if="item.is_reprint==1">
                <img alt="" src="@/assets/zhuan1.png" style="width: 28px;height: 28px">
              </div>
              <el-popover :width="150" trigger="click">
                <template #reference>
                  <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer">
                    <img v-if="item.is_author === 1" alt="" src="@/assets/dian.png"
                         style="width: 25px;"/>
                  </div>
                </template>
                <div style="width: 100%; display: flex; align-items: center;justify-content: space-between">
                  <div v-if="item.is_author === 1" class="updatePost" style="color:#fff;"
                       @click="updatePost(item)">修改
                  </div>
                  <div v-if="item.is_author === 1" class="deletePost" style="color:#fff;"
                       @click="deletePost(item)">删除
                  </div>
                </div>
              </el-popover>
              <div v-if="item.recommended==1"><img alt="" src="@/assets/refined1.png"
                                                   style="width: 28px;height: 28px">
              </div>
              <div v-if="item.is_top==1"><img alt="" src="@/assets/topping1.png"
                                              style="width: 28px;height: 28px"></div>
            </div>
            <div>
              <div :ref="el => setPostContentRef(el, index)"
                   :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }"
                   style="word-wrap: break-word;" v-html="formattedPostContent(item.post_content)"
                   id="rich_text"></div>
              <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
                <text v-if="expandStates[index]" style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;"
                      @click="expand(index, $event.target)">展开
                </text>
              </div>
            </div>
            <div v-if="item.file_img_num > 0" style="height: 150px;overflow: hidden;position: relative">
              <div v-for="(img, i) in item.file_img" :key="i"
                   style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;">
                <img :src="img" :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'cover' }"
                     alt="" @click="openPreview1(i,item.file_img)">
              </div>
              <img v-if="item.file_img_num >= 3" alt="" src="@/assets/jia.png"
                   style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;" @click="jumpDetails(item)">
              <div v-if="showPreview1" class="modal" @click="closePreview1" style="z-index: 9999;">
                <div class="modal-content">
                  <span class="close" @click="closePreview">&times;</span>
                  <img :src="images11" alt="Preview" style="max-width: 100%; max-height: 100vh;"/>
                </div>
              </div>
            </div>

            <div v-if="item.file_video.length > 0" style="height: 300px;">
              <div v-for="(img, i) in item.file_video" :key="i">
                <video :src="img" controls style="width: 100%;height: 300px;"></video>
              </div>
            </div>

            <div v-if="item.file_data.length > 0">
              <div v-for="(img, i) in item.file_data" :key="i">
                <!--                <a class="file_style" :href="img.url" :download="img.filename" @click="downloadobj">{{ img.filename }}</a>-->
                <a class="file_style" @click="download(img)">{{ img.filename }}</a>
              </div>
            </div>

            <div v-if="item.file_audio.length > 0" style="margin: 10px;">
              <div v-for="(img, i) in item.file_audio" :key="i">
                <audio controls style="width: 100%;">
                  <source :src="img" type="audio/mp3">
                </audio>
              </div>
            </div>

            <div style="background:rgb(13, 46, 120);padding: 5px;margin-top: 10px;"
                 v-if="item.reprint_id != null">
              <div :ref="el => setPostContentRef(el, index)"
                   :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }"
                   style="word-wrap: break-word;"
                   v-html="formattedPostContent(item.reprint_data.post_content)"
                   id="rich_text"></div>
              <div v-if="item.reprint_data.file_img_num > 0"
                   style="height: 150px;overflow: hidden;position: relative">
                <div v-for="(img, i) in item.reprint_data.file_img"
                     :key="i"
                     style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;">
                  <img :src="img"
                       :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'cover' }"
                       alt=""
                       @click="openPreview1(i,item.reprint_data.file_img)">
                </div>
                <img v-if="item.reprint_data.file_img_num >= 3" alt="" src="@/assets/jia.png"
                     style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;"
                     @click="jumpDetails(item.reprint_data)">
                <div v-if="showPreview1" class="modal" @click="closePreview1">
                  <div class="modal-content">
                    <span class="close" @click="closePreview">&times;</span>
                    <img :src="images11" alt="Preview"
                         style="max-width: 100%; max-height: 100vh;"/>
                  </div>
                </div>
              </div>

              <div v-if="item.reprint_data.file_video != []">
                <div v-for="(img, i) in item.reprint_data.file_video" :key="i">
                  <video :src="img" controls style="width: 100%;height: 300px;"></video>
                </div>
              </div>

              <div v-if="item.reprint_data.file_data != []">
                <div v-for="(img, i) in item.reprint_data.file_data" :key="i">
                  <!--                <a class="file_style" :href="img.url" :download="img.filename" @click="downloadobj">{{ img.filename }}</a>-->
                  <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                </div>
              </div>

              <div v-if="item.reprint_data.file_audio != []">
                <div v-for="(img, i) in item.reprint_data.file_audio" :key="i">
                  <audio controls style="width: 100%;">
                    <source :src="img" type="audio/mp3">
                  </audio>
                </div>
              </div>
            </div>


            <div class="chatIcon_list_item_footer" @click.stop>
              <div class="chatIcon_list_item_footer_left">
                <!--            <div class="chatIcon_list_item_footer_left_item" @click.stop="transport(item)" style="cursor: pointer">-->
                <!--              <img src="@/assets/zhuanzai.png" alt=""/>-->
                <!--              <span>转载</span>-->
                <!--            </div>-->
                <div
                    v-if="item.transports"
                    style="position: fixed;top: 40%;width: 630px;left: 50%;transform: translateX(-50%);background-color: #fff;padding: 10px;z-index: 9999"
                    @click.stop>
                  <div class="item_pl_user"
                       style="display: flex;justify-content: space-between;color: #000;">
                    <span> 转载内容 </span>
                    <span style="cursor: pointer" @click.stop="closetransport(item)">X</span>
                  </div>
                  <textareaComponent :category_type="item.category_type" :channel="item.channel_id"
                                     :fuid="item.id"
                                     :inputtype="inputtype" :typeView="channels_id"
                                     @result="sendMessageResult"/>
                  <!--                  <div class="chatIcon_list_item_info" v-html="item.post_content"></div>-->
                  <textarea id="myTextarea" :value="item.post_content_text"
                            class="item_pl_content_textarea" cols="30"
                            rows="10" @input="handleTextareaInput"></textarea>
                </div>
                <!--              <el-popover-->
                <!--                  :width="150"-->
                <!--                  trigger="hover">-->
                <!--                <template #reference>-->
                <!--                  <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer">-->
                <!--                    <img alt="" src="@/assets/zf.png"/>-->
                <!--                    <span>转发{{ item.post_forward }}</span>-->
                <!--                  </div>-->
                <!--                </template>-->
                <!--                <div style="width: 100%; display: flex; align-items: center;">-->
                <!--                  <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"-->
                <!--                       @click="shareWeChat">-->
                <!--                    <img alt="" src="@/assets/wx.png" style="width: 30px; height: 30px;"/>-->
                <!--                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>-->
                <!--                  </div>-->
                <!--                  <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"-->
                <!--                       @click="shareQQ">-->
                <!--                    <img alt="" src="@/assets/QQ@2x.png" style="width: 30px; height: 30px;"/>-->
                <!--                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>-->
                <!--                  </div>-->
                <!--                  <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"-->
                <!--                       @click="shareMicroBlog">-->
                <!--                    <img alt="" src="@/assets/wb.png" style="width: 30px; height: 30px;"/>-->
                <!--                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>-->
                <!--                  </div>-->
                <!--                  <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;"-->
                <!--                       @click="transport(item)">-->
                <!--                    <img alt="" src="@/assets/zhuanzai1.png" style="width: 30px; height: 30px;"/>-->
                <!--                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">转载</span>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </el-popover>-->
                <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer"
                     @click="transport(item)">
                  <img alt="" src="@/assets/zf.png"/>
                  <span>转载</span>
                </div>
                <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer"
                     @click="jumpDetails(item)">
                  <img alt="" src="@/assets/pl.png"/>
                  <span v-if="props.channel === 19">回答{{ item.comment_count }}</span>
                  <span v-else>评论{{ item.comment_count }}</span>
                </div>
                <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer"
                     @click.stop="collect(item.id, item.is_myfavorite, item)">
                  <!--              <img :src="item.is_myfavorite == 1 ? require('@/assets/shouc.png') : require('@/assets/sc.png')" alt=""/>-->
                  <img :src="item?.is_myfavorite == 1 ? require('@/assets/dianz.png') : require('@/assets/zz.png')"
                       alt=""/>
                  <span>点赞{{ item.post_favorites }}</span>
                </div>
              </div>
            </div>
            <div class="chatIcon_list_item_pj_item_pl" @click.stop>
              <div class="item_pl_content">
            <textarea :id="'aaaaaa'+item.id"
                      v-model="item.text"
                      class="item_pl_content_textarea pltextarea" placeholder="欢迎评论" rows="1"
                      @input="adjustTextareaHeight(item.id)"></textarea>
              </div>
              <div class="item_pl_caozuo" style="cursor: pointer;margin-top: 5px;">
                <el-popover
                    :width="300"
                    placement="bottom"
                    title="请选择表情"
                    trigger="click"
                    @before-enter="beforeIconChild(index, i)">
                  <template #reference>
                    <img alt="" class="item_pl_caozuo_img" src="@/assets/icon.png"/>
                  </template>
                  <chatIcon @changeText="getIcon"/>
                </el-popover>
                <imageContent :content="item.text" :obj="item" :type="index"
                              @change="handleImageSelected"
                              @result="imageContentResult1"></imageContent>
                <div class="item_pl_caozuo_btn" @click="sendCommentVal(item.id,item.text,item)">发送
                </div>
              </div>
            </div>
            <div v-if="item.comment_image && item.comment_image.length>0" class="comment-image-box">
              <div v-for="(imgitem, index) in item.comment_image"
                   style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;">
                <img :src="imgitem" style="width: 80px;height: 80px;">
                <img src="@/assets/X.png"
                     style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;"
                     @click="delimg(item.comment_image,index)">
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="item.is_show_file == 0" :ref="el => setPostContentRef(el, index)"
                 :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }">
              <div style="padding-bottom: 10px;">发布时间:{{ item.content_time }}</div>
              政策面:<span class="rich_text" v-html="item.policy_content"></span>
              市场新闻:<span class="rich_text" v-html="item.news_content"></span>
              宏观面:<span class="rich_text" v-html="item.macro_content"></span>
              资金面及预期:<span class="rich_text" v-html="item.fund_content"></span>
              市场预期/关注:<span class="rich_text" v-html="item.expect_content"></span>
              情绪面:<span class="rich_text" v-html="item.emotion_content"></span>
            </div>
            <div
                style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
              <text v-if="expandStates[index]" style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;"
                    @click="expand(index, $event.target)">展开
              </text>
            </div>
            <div v-if="item.is_show_file == 1">
              <div v-for="(item1, index1) in item.file_list" :key="index1">
                <div v-if="filetype(item1.url) == 1" style="margin-top: 10px;">
                  <audio controls style="width: 100%;">
                    <source :src="item1.url" type="audio/mp3">
                  </audio>
                </div>
                <div v-if="filetype(item1.url) == 2" style="margin-top: 10px;">
                  <video controls style="width: 100%;height: 400px;">
                    <source :src="item1.url" type="video/mp4">
                  </video>
                </div>
                <div v-if="filetype(item1.url) == 3" style="margin-top: 10px;">
                  <div style="height: 300px;">
                    <img :src="item1.url"
                         :style="{ width: '100%', height: '300px', opacity: getOpacity(i),objectFit: 'cover' }"
                         alt="" @click="openPreview1(0,item1.url)">
                  </div>
                  <div v-if="showPreview1" class="modal" @click="closePreview1">
                    <div class="modal-content">
                      <span class="close" @click="closePreview1">&times;</span>
                      <img :src="images11" alt="Preview"
                           style="max-width: 100%; max-height: 100vh;"/>
                    </div>
                  </div>
                </div>
                <div v-if="filetype(item1.url) == 4" style="margin-top: 10px;">
                  <a class="file_style" @click="download(item1.url)">{{ item1.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Loading v-if="loading"/>
      <div v-if="!loading && from.list.length > 0 && noMore" class="info">
        <el-text class="mx-1" type="info">没有更多了~</el-text>
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible3" title="开通会员" width="650">
      <div v-if="paytype==1" style="height: 200px;display: flex;z-index: 10;margin-top: 15px;margin-right: 20px;">
        <div v-for="(item, index) in vipinfo"
             :key="index"
             style="width: 20%;height: 200px;border: 2px #0B3D6C solid;background-color: #d3dce6;margin-left: 10px;">
          <div style="font-size: 22px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">{{
              item.name
            }}
          </div>
          <div v-if="pay_status === 1"
               style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">
            <text style="color: red;font-size: 22px">{{ item.membership_price }}</text>
            ￥
          </div>
          <div v-else
               style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">
            <text style="color: red;font-size: 22px">{{ item.membership_score }}</text>
          </div>
          <div style="text-align: center;padding-top: 15px">
            <!--          <el-button type="primary" @click="dredge(item.id)">开通</el-button>-->
            <el-button v-if="pay_status === 1" type="primary" @click="dredge(item.id)">开通</el-button>
            <el-button v-else type="primary" @click="dredge1(item.id)">积分开通</el-button>
          </div>
        </div>
      </div>
      <div v-else style="height: 200px;text-align: center;display: flex">
        <img :src="payimg" alt="" style="width: 200px;height: 200px"/>
      </div>

      <template #footer>
      <span class="dialog-footer" style="margin-right: 10px;">
         <text v-if="pay_status !== 1" style="color: #000000;padding-right: 20px;">现有积分:{{ userinfo.score }}</text>
        <el-button @click="cancellation()">取消</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible4" title="开通会员" width="500">
      <div style="margin-top: 15px;margin-left: 20px;">
        <el-button @click="payment">付费</el-button>
        <el-button @click="exchange">积分兑换</el-button>
        <el-button @click="apply_for">审核申请</el-button>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false">取消</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible1" title="开通会员" width="500">
      <div style="margin-top: 15px;margin-left: 20px;">暂未开通会员,开通会员观看!</div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取消</el-button>
        <el-button type="primary" @click="confirm">
          确认
        </el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible2" title="评价" width="300">
      <div style="display: flex;justify-content: center; align-items: center;">
        <el-rate size="large" @change="comment_star"/>
      </div>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible" title="" style="z-index: 999">
      <textareaComponent :channel="channels_id" :inputtype="3" :typeView="channels_id" :category_type="categorys"
                         @result="shuaxin"/>
    </el-dialog>

<!--    <vue-easy-lightbox-->
<!--        :imgs="images"-->
<!--        :index="0"-->
<!--        :visible="visible"-->
<!--        @hide="handleHide"-->
<!--    ></vue-easy-lightbox>-->
  </div>
</template>

<script setup>
import textareaComponent from '@/components/textareaComponent.vue'
import {
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watchEffect,
  nextTick,
  computed
} from 'vue'
import {
  addComment,
  addFavorite,
  addLive,
  delFavorite,
  delLive,
  getComment,
  getHomeList,
  plaza_post_index,
  searchList,
  systemConfig,
  delComment,
  addRating,
  getVipList,
  getAlbumVideoList,
  createVip,
  payVip,
  Vipquery,
  delPost,
  scorePay,
  goodsShow,
  getUserDetails,
  homePlaza,
} from '@/api/index'
import chatIcon from '@/components/chatIcon.vue'
import imageContent from '@/components/imageContent.vue'
import Loading from '@/components/Loading.vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {toBase64, downloadobj} from '@/utils/utils'
import {marked} from 'marked';
import {ElMessage} from "element-plus";
import Player from "xgplayer";

let channels_id = ref('')
const store = useStore()
const router = useRouter()
const props = defineProps({
  title: String,  // 栏目标题
  title1: String,  // 栏目小标题
  channel: Number,     // 栏目id
  timeVal: Number,   // 刷新时间
  create_time: String,     // 创建时间
  typeView: String | Number,    // 1 首页广场   5 新闻资讯    3 债市论坛
  typeInput: String | Number    // 19 问答社区
})
const emit = defineEmits(['finish', 'openPreview', 'updateContent'])

let sort = ref([
  {name: '默认排序', id: 'desc'},
  {name: '降序', id: 'desc'},
  {name: '升序', id: 'asc'}
])
let dateType = ref([
  {name: '全部', id: 'all', active: true},
  {name: '今日', id: 1, active: false},
  {name: '七日', id: 2, active: false},
  {name: '月', id: 3, active: false},
  {name: '年', id: 4, active: false}
])

let categorys = ref('plate')
let title = ref('')
let title1 = ref('')
let titletype = ref(1)
let start_time = ref('')
let end_time = ref('')
let payimg = ref('')
let time_type = ref(0)
let loading = ref(true)

let loading1 = ref(false)
let paytype = ref(1)
let comment = ref('')
let value1 = ref('')
const postContents = ref([]); // 存储 DOM 引用的数组
let showTexttype = ref(1)

let investmentList = ref([])
let albumList = ref([])
let comment_image = ref([])
let liveList = ref([])
let plateList = ref([])
let vipinfo = ref([])
let videoList = ref([])
let inputtype = ref(4)
let searchtype = ref(1)
let player = ref({});
let zhibotype = ref(1);
let zhibourl = ref('');
let albumtype = ref(1);
let is_collection = ref(1);
let introducedata = ref([]);
let directorydata = ref([]);
let videowhichpage = ref(1);
let videopage = ref(1);
let images1 = ref([])
let images11 = ref([])
let showPreview1 = ref(false)
let showPreview = ref(false)
let isScrolledToBottom = ref(false)
let currentImageIndex = ref(0)
let userinfo = ref([])
let from = reactive({
  page: 1,
  total: 0,
  keyword: '',
  sortName: '降序',
  sortId: 'desc',
  dateType: 'all',
  index: 0,
  indexChild: 0,
  list: []
})
let noMore = ref(false)
let dialogFormVisible = ref(false)
let dialogFormVisible1 = ref(false)
let dialogFormVisible2 = ref(false)
let video_id = ref(1)
let commentdata = ref([])
let commentnum = ref([])
let whichpage = ref([])
let dialogFormVisible3 = ref(false)
let dialogFormVisible4 = ref(false)
let pay_status = ref(0)
let pay_al_type = ref(0)
let pay_alid = ref(0)
let order_exchange = ref(0)
let goods_type = ref('')
let table_name = ref('')
let goods_ids = ref('')
let tan_type = ref(1)
let title_show = ref(1)
let title_text = ref('')
const expandStates = reactive(new Array(from.list.length).fill(false));
// 倒计时结束
const finish = () => {
  if (props.channel == 1) {
    getNewList4('rem')
  } else {
    getNewList2('rem')
  }
  emit('finish')
}

const title_fun = () => {
    updateTitleAndFavicon1('复盘 - 债市广场')
    getNewList2('rem')
    title_text.value = '债市广场'
    title_show.value = 2
}

const openPreview1 = (index,ima) => {

  // images11.value = ima
  // showPreview1.value = true;
  // console.log('预览图片',images11)
  emit('openPreview', {index, ima})
}

const closePreview1 = () => {
  showPreview1.value = false;
}

const shuaxin = () => {
  noMore.value = true
  if (props.channel == 1) {
    getNewList4('rem')
  } else if (props.channel == 3) {
    getNewList2('rem')
  } else {
    getNewList('rem')
  }
}

const filetype = (fileName) => {
  const extension = getFileExtension(fileName);
  switch (extension.toLowerCase()) {
    case 'mp3':
      return 1;
    case 'mp4':
      return 2;
    case 'jpg':
      return 3;
    case 'png':
      return 3;
    case 'webp':
      return 3;
    case 'jpeg':
      return 3;
    case 'gif':
      return 3;
    default:
      return 4;
  }
}

const getFileExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return fileName.slice(lastDotIndex + 1);
  } else {
    return '';
  }
}


const adjustTextareaHeight = (id) => {
  // 动态调整textarea高度的方法，可以根据实际需求进行调整
  // 例如，可以根据内容的行数来动态设置高度
  const textarea = document.getElementById('aaaaaa' + id)
  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
}
const adjustTextareaHeight1 = (id) => {
  // 动态调整textarea高度的方法，可以根据实际需求进行调整
  // 例如，可以根据内容的行数来动态设置高度
  const textarea = document.getElementById('aaaaaa')
  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
}

const dredge = (id) => {
  createVip({
    goods_type: 'user_group',
    table_name: 'user_group',
    goods_id: id,
  }).then(res => {
    if (res.code === 1) {
      payVip({order_id: res.data.order_id}).then(res1 => {
        if (res1.code === 1) {
          payimg.value = res1.data.code_url
          paytype.value = 2
          const intervalId = setInterval(() => {
            Vipquery({order_id: res.data.order_id}).then(res2 => {
              if (res2.code === 1) {
                if (res2.data.pay_status === 1) {
                  clearInterval(intervalId); // 停止轮询
                  location.reload();
                }
              }
            });
          }, 2000);
        }
      })
    }

  })
}
const payment = (val) => {
  pay_status.value = 1
  dialogFormVisible4.value = false
  dialogFormVisible1.value = true
}

const confirm = (val) => {
  dialogFormVisible3.value = true
  dialogFormVisible1.value = false
  if (pay_status.value === 1) {
    getVipList().then(res => {
      if (res.code === 1) {
        vipinfo.value = res.data
      }
    })
  }
}

const postcharge = (val) => {
  tan_type.value = 2
  dialogFormVisible4.value = true
  pay_alid.value = val
}
const apply_for = () => {
  if (tan_type.value === 2) {
    goods_type.value = 'articles';
    table_name.value = 'plate_post';
    goods_ids.value = pay_alid.value
  } else {
    goods_type.value = 'categories';
    table_name.value = 'plate';
    goods_ids.value = props.channel
  }
  createVip({
    goods_type: goods_type.value,
    table_name: table_name.value,
    goods_id: goods_ids.value,
    pay_type: 3,
  }).then(res => {
    if (res.code === 1) {
      ElMessage({message: '提交成功,等待审核', type: 'success'})
      dialogFormVisible4.value = false
    }
  })
}
const exchange = (val) => {
  pay_status.value = 2
  dialogFormVisible4.value = false
  dialogFormVisible1.value = true
  getUserDetails().then(res => {
    userinfo.value = res.data
  });
  if (tan_type.value === 2) {
    goods_type.value = 'articles';
    table_name.value = 'plate_post';
    goods_ids.value = pay_alid.value
  } else {
    goods_type.value = 'categories';
    table_name.value = 'plate';
    goods_ids.value = props.channel
  }
  goodsShow({
    goods_type: goods_type.value,
    table_name: table_name.value,
    goods_id: goods_ids.value,
  }).then(res => {
    if (res.code === 1) {
      vipinfo.value = res.data
    }
  })
}

const dredge1 = (id) => {
  //差一个积分支付的接口
  let aaaaa = '';
  if (tan_type.value === 2) {
    goods_type.value = 'articles';
    table_name.value = 'plate_post';
    goods_ids.value = pay_alid.value
  } else {
    goods_type.value = 'categories';
    table_name.value = 'plate';
    goods_ids.value = props.channel
  }
  createVip({
    goods_type: goods_type.value,
    table_name: table_name.value,
    goods_id: goods_ids.value,
    pay_type: 2,
  }).then(res => {
    if (res.code === 1) {
      order_exchange.value = res.data.order_id
      dialogFormVisible4.value = false
      dialogFormVisible3.value = false
      scorePay({order_id: order_exchange.value}).then(res1 => {
        if (res1.code === 1) {
          ElMessage({message: res1.msg, type: 'success'})
        }
      })
    }
  })
}

const comment_star = (e) => {
  addRating({
    table_name: 'video',
    object_id: video_id.value,
    score: e
  }).then(res => {
    if (res.code === 1) {
      dialogFormVisible2.value = false
      fun_getAlbumVideoList(introducedata.value.id, 1, 10)
    }
  })
}
const fun_getAlbumVideoList = (aaa, bbb, ccc) => {
  getAlbumVideoList({
    album_id: aaa,
    page: bbb,
    limit: ccc,
  }).then(res => {
    if (res.code === 1) {
      is_collection.value = res.data.album.is_favorite
      introducedata.value = res.data.album
      directorydata.value = res.data.video_list
      videowhichpage.value = res.data.total
      videopage.value = res.data.current_page
    }
  })
  get_getComment(aaa, ccc, bbb)
}
const cancellation = () => {
  dialogFormVisible3.value = false
  paytype.value = 1
}

const openPreview = (index, ima) => {
  emit('openPreview', {index, ima})
}

const closePreview = (index) => {
  showPreview.value = false;
}

const zhibo = (url, shou, item) => {
  if (shou !== 1) {
    zhibourl.value = url
    zhibotype.value = 2
    // player.value = new Player({
    //   id: 'mse',
    //   autoplay: true,
    //   fluid: true,
    //   url: url,
    // })
  } else {
    dialogFormVisible4.value = true
    tan_type.value = 1
    pay_alid.value = item.id
    pay_al_type.value = 2
  }
}

const playlive = () => {
  if (zhibourl.value !== '') {
    player.value = new Player({
      id: 'mse',
      autoplay: true,
      fluid: true,
      url: zhibourl.value,
    })
    zhibotype.value = 3
  } else {
    ElMessage({message: '暂未开播', type: 'error'})
  }
}

const albumvideo = (item) => {
  if (item.is_member_expire !== 1) {
    albumtype.value = 2
    fun_getAlbumVideoList(item.id, 1, 10)
  } else {
    dialogFormVisible4.value = true
    tan_type.value = 2
    pay_alid.value = item.id
    pay_al_type.value = 2
  }
}

const video_info = (val) => {
  document.getElementById('image_mse').style.display = 'none';
  document.getElementById('mse_video').style.display = 'block';

  player.value = new Player({
    id: 'mse_video',
    autoplay: true,
    fluid: true,
    url: val,
  })
}

const album_collection = (val) => {
  addFavorite({
    table_name: 'album',
    object_id: val
  }).then(res => {
    if (res.code === 1) {
      is_collection.value = 1
    } else {
      is_collection.value = 2
    }
  })
}

const handleMouseEnter_video = (id) => {
  dialogFormVisible2.value = true
  video_id.value = id
}

const del_collection = (val) => {
  delFavorite({
    table_name: 'album',
    object_id: val
  }).then(res => {
    if (res.code === 1) {
      is_collection.value = 2
    } else {
      is_collection.value = 1
    }
  })
}

const introduce = (val) => {
  document.getElementById('introduce').style.display = 'block';
  document.getElementById('directory').style.display = 'none';
  document.getElementById('comment').style.display = 'none';
  document.getElementById('jieshao').style.background = '#0D67BB';
  document.getElementById('mulu').style.background = '#0B3D6C';
  document.getElementById('pinglun').style.background = '#0B3D6C';
}
const directory = (val) => {
  document.getElementById('introduce').style.display = 'none';
  document.getElementById('directory').style.display = 'block';
  document.getElementById('comment').style.display = 'none';
  document.getElementById('jieshao').style.background = '#0B3D6C';
  document.getElementById('mulu').style.background = '#0D67BB';
  document.getElementById('pinglun').style.background = '#0B3D6C';
}
const comment1 = (val) => {
  document.getElementById('introduce').style.display = 'none';
  document.getElementById('directory').style.display = 'none';
  document.getElementById('comment').style.display = 'block';
  document.getElementById('jieshao').style.background = '#0B3D6C';
  document.getElementById('mulu').style.background = '#0B3D6C';
  document.getElementById('pinglun').style.background = '#0D67BB';
}

const deletePost = (item) => {
  delPost({
    category_type: item.category_type,
    id: item.id
  }).then(res => {
    if (res.code === 1) {
      ElMessage({message: res.msg, type: 'success'})
      // getNewList('rem')
      shuaxin()
    }
  })
}
// 更新帖子
const updatePost = (item) => {
  let files = []
  for (let i = 0; i < item.file_data.length; i++) {
    files.push(item.file_data[i].url)
  }
  item.file_data = files
  emit('updateContent', item)
}

// 打开发布框
const openProp = () => {


  dialogFormVisible.value = true
}
const echoerror = (err) => {
}
const expand = (index, el) => {

  if (postContents.value[index].classList.contains('textinfo')) {
    postContents.value[index].classList.remove('textinfo')
    el.textContent = "收起";
  } else {
    postContents.value[index].classList.add('textinfo')
    el.textContent = "展开";
  }
}
const showTextInfo = async (index) => {
  await nextTick();
  let el = postContents.value[index]
  if (el) {
    expandStates[index] = el.scrollHeight > el.offsetHeight
  }
}

const updateTitleAndFavicon1 = (text) => {
    document.title = text;
}

const getOpacity = (index) => {
  return index === 2 ? 0.2 : 1;
}
// 点击评论
const handleMouseEnter = (comment) => {
  comment.active = true
}

//删除评论
const deleteComment = (id) => {
  delComment({id: id}).then(res => {
    if (res.code === 1) {
      ElMessage({message: res.msg, type: 'success'})
      // getNewList('rem')
      shuaxin()
    }
  })
}

// 关闭评论
const closeComment = (comment) => {
  comment.active = false
}

const inputframe = (comment) => {
  comment.inputframes = true
}

const closeframe = (comment) => {
  comment.inputframes = false
}

const getCategoryLists1 = () => {
  systemConfig().then(res => {
    channels_id = res.data.web_qa_community_channels_id
  })
}

const transport = (comment) => {
  inputtype.value = 4
  comment.transports = true
}

const closetransport = (comment) => {
  comment.transports = false
}

const sendMessageResult = (event) => {
  if (event) {
    // getNewList('rem')
    shuaxin()
  }
}

// 获取评论
const getCommentList = (aid, pid, page, f, z, comment, top_pid) => {

  let type = ''
  if (props.typeView == 1) {
    type = 'bbs'
  } else if (props.typeView == 3) {
    type = 'bbs'
  } else if (props.typeView == 5) {
    type = 'news'
  }
  getComment({type, aid, pid, orderway: 'desc', page, limit: 5, top_pid})
      .then(res => {
        if (res.code === 1) {
          comment.page += 1
          res.data.list.forEach(item => {
            item.isOpen = false
            item.text = ''
          })
          if (!from.list[f].commentList[z].childernComment) {
            from.list[f].commentList[z].childernComment.push(...res.data.list)
          } else {
            res.data.list.forEach((child, ss) => {
              from.list[f].commentList[z].childernComment.forEach(item => {
                if (child.id == item.id) {
                  res.data.list.splice(ss, 1)
                }
              })
            })
            from.list[f].commentList[z].childernComment.push(...res.data.list)
          }
          if (from.list[f].commentList[z].childernComment.length === res.data.total) {
            from.list[f].commentList[z].isShow = false
          } else {
            from.list[f].commentList[z].isShow = true
          }
        }
      })
}
// 跳转详情
const jumpDetails = (item) => {
  const newObj = (({category_type, comment_type, id, table_name}) => ({
    category_type,
    comment_type,
    id,
    table_name
  }))(item);
  const content = toBase64(JSON.stringify(newObj))
  if (props.typeView == 1 || props.typeView == 3) {
    const routeUrl = router.resolve({
      path: "/home/forumDetails",
      // query: {id: item.id, comment_type:item.comment_type}
      query: {id: item.id, type: item.category_type, comment_type: item.comment_type, status: '1'}
    });
    window.open(routeUrl.href, "_blank");
  } else if (props.typeView == 5) {
    const routeUrl = router.resolve({
      path: "/home/newDetails",
      query: {id: item.id, type: props.typeView, content: content}
    });
    window.open(routeUrl.href, "_blank");
  }
}
// 父级评论打开图标
const beforeIcon = (index) => {
  from.index = index
}
// 子级评论打开图标
const beforeIconChild = (a, b) => {
  from.index = a
  from.indexChild = b
}
// 子级评论选择表情
const getIconChild = (e) => {
  from.list[from.index].commentList[from.indexChild].text += e
}
// 点赞
const Like = (id, status, item) => {
  let table_name = ''
  if (props.typeView == 1) {
    table_name = item.table_name
  } else if (props.typeView == 3) {
    table_name = item.table_name
  } else if (props.typeView == 5) {
    table_name = item.table_name
  }
  if (status == 1) {
    delLive({table_name, object_id: id})
        .then(res => {
          if (res.code === 1) {
            item.is_mylike = 0
            item.post_like -= 1
          }
        })
  } else {
    addLive({table_name, object_id: id})
        .then(res => {
          if (res.code === 1) {
            item.is_mylike = 1
            item.post_like += 1
          }
        })
  }
}
// 父级评论选择表情
const getIcon = (e) => {
    if(from.list[from.index].text == undefined){
        from.list[from.index].text = e
    }else{
        from.list[from.index].text += e
    }
}
const getIcon2 = (e) => {
  plateList.value[from.index].text += e
}
const getIcon1 = (e) => {
  comment.value += e
}
// 收藏
const collect = (id, status, item) => {
  let table_name = item.table_name
  if (status == 1) {
    delFavorite({table_name, object_id: id})
        .then(res => {
          if (res.code === 1) {
            item.is_myfavorite = 0
            ElMessage({message: '操作成功', type: 'success'})
            // getNewList('rem')
            shuaxin()
          }
        })
  } else {
    addFavorite({table_name, object_id: id})
        .then(res => {
          if (res.code === 1) {
            item.is_myfavorite = 1
            ElMessage({message: '操作成功', type: 'success'})
            // getNewList('rem')
            shuaxin()
          }
        })
  }
}
// 子级发送评论
const sendChildComment = (obj, comment, top_pid) => {
  let type = ''
  if (props.typeView == 1) {
    type = 'bbs'
  } else if (props.typeView == 3) {
    type = 'bbs'
  } else if (props.typeView == 5) {
    type = 'news'
  }
  addComment({type, aid: obj.aid, pid: obj.id, content: obj.text, top_pid})
      .then(res => {
        if (res.code === 1) {
          obj.active = false
          obj.text = ''
          obj.isShow = true
          res.data.isOpen = false
          res.data.text = ''
          if (comment) {
            comment.childernComment.push(res.data)
          } else {
            obj.childernComment.push(res.data)
          }
        }
      })
}
// 父级发送评论
const sendCommentVal = (id, text, item) => {
    if (!text){
        text = ''
    }
  let content = text
  // if(content.length > 140){
  //     ElMessage({message: '字数不能超过140', type: 'error'})
  //     return
  // }
  let type = ''
  if (props.typeView == 1) {
    type = item.comment_type
  } else if (props.typeView == 3) {
    type = 'bbs'
  } else if (props.typeView == 5) {
    type = 'news'
  }

  if (item.comment_image && item.comment_image.length > 0) {
    for (let i = 0; i < item.comment_image.length; i++) {
      content += `![Image Description](${item.comment_image[i]})`
    }
  }


  addComment({type, aid: id, pid: 0, content, top_pid: 0})
      .then(res => {
        if (res.code === 1) {
          item.text = ''
          item.comment_image = ''
          res.data.isOpen = false
          res.data.text = false
          res.data.page = 1
          res.data.childernComment = []
          res.data.user.username = res.data.user.nickname
          item.commentList.push(res.data)
          ElMessage.success(res.msg)
          // getNewList('rem')
          shuaxin()
        }
      })
}
const delimg = (item, e) => {
  item.splice(e, 1);
}

// 计算属性，确保 post_content 是字符串并进行替换
const formattedPostContent = (text) => {
  if (text && typeof text === 'string') {
    return text.replace(/\n/g, '<br>');
  }
  return '';
};

const sendCommentVal1 = (id, text) => {
  let type = 'album'
  let content = text
  if (comment_image.value && comment_image.value.length > 0) {
    for (let i = 0; i < comment_image.value.length; i++) {
      content += `![Image Description](${comment_image.value[i]})`
    }
  }
  addComment({type, aid: id, pid: 0, content: content, top_pid: 0})
      .then(res => {
        if (res.code === 1) {
          comment = ''
          res.data.isOpen = false
          res.data.text = false
          res.data.page = 1
          res.data.childernComment = []
          res.data.user.username = res.data.user.nickname
          get_getComment(id, 10, 1)
        }
      })
}

const get_getComment = (aaa, bbb, ccc) => {
  getComment({
    aid: aaa,
    type: 'album',
    limit: bbb,
    page: ccc
  }).then(res => {
    if (res.code === 1) {
      let data = res.data.list
      // const regex = new RegExp('img', 'gi')
      // for (var i = 0; i < res.data.list.length; i++) {
      //     data[i].content = res.data.list[i].content
      //         .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
      //     data[i].content = res.data.list[i].content
      //         .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
      // }
      commentdata.value = data
      commentnum.value = res.data.total
      whichpage.value = res.data.current_page
    }
  })
}

// 选择排序方式
const clickSort = (row) => {
  from.sortId = row.id
  from.sortName = row.name
  // getNewList('rem')
  shuaxin()
}
// 选择日期
const clickDate = (i, id) => {
  from.dateType = id
  dateType.value.forEach((item, index) => {
    item.active = index === i
  })
  // getNewList('rem')
  shuaxin()
}
// 日期范围
const date_range = (e) => {
  start_time.value = formatDate(value1.value[0])
  end_time.value = formatDate(value1.value[1])
  time_type.value = 2
  // getNewList('rem')
  shuaxin()
}

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// 关键字搜索
const getKeyword = (val) => {
  from.keyword = val
  getNewList3('rem')
}

const getNewList3 = (type) => {
  if (type === 'rem') {
    from.list = []
    from.page = 1
    noMore.value = false
  }
  if (noMore.value) return
  loading.value = true
  zhibotype.value = 1
  albumtype.value = 1
  searchList({
    page: from.page,
    limit: 20,
    keyword: from.keyword,
    time: props.create_time ? props.create_time : 0,
  }).then(res => {
    if (res.code === 1) {
      albumList.value = res.data.albumList
      investmentList.value = res.data.investmentList
      liveList.value = res.data.liveList
      // plateList.value = res.data.plateList
      videoList.value = res.data.videoList
      searchtype.value = 2
      let data = res.data.plateList
      if (data.table_name == "plate_post") {
        const regex = new RegExp('img', 'gi')
        for (var i = 0; i < res.data.plateList.length; i++) {
          data[i].post_content = res.data.plateList[i].post_content
              .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
          data[i].post_content = res.data.plateList[i].post_content
              .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
        }
      }
      plateList.value = data


    }
  }).finally(() => {
    loading.value = false
  })
}

const getNewList4 = (type) => {
  console.log(type)
  if (type === 'rem') {
    from.list = []
    from.page = 1
    noMore.value = false
  }
  if (noMore.value) return
  loading.value = true
  zhibotype.value = 1
  albumtype.value = 1
  homePlaza({
    page: from.page,
    limit: 20,
    type: from.keyword,
    keyword: '',
    time: from.time,
    start_time: start_time.value,
    end_time: end_time.value,
    orderway: 'desc',
  }).then(res => {
    if (res.code === 1) {
      albumList.value = res.data.albumList
      investmentList.value = res.data.investmentList
      liveList.value = res.data.liveList
      // plateList.value = res.data.plateList
      videoList.value = res.data.videoList
      searchtype.value = 2
      let data = res.data.plateList
      if (data.table_name == "plate_post") {
        const regex = new RegExp('img', 'gi')
        for (var i = 0; i < res.data.plateList.length; i++) {
          data[i].post_content = res.data.plateList[i].post_content
              .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
          data[i].post_content = res.data.plateList[i].post_content
              .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
        }
      }
      for (let i = 0; i < data.length; i++) {
        plateList.value.push(data[i])
        from.list.push(data[i])
      }
      console.log(from.list)
    }
  }).finally(() => {
    loading.value = false
  })
}

// 获取列表数据
const getNewList = (type) => {
    title_show.value = 1
  if (type === 'rem') {
    from.list = []
    from.page = 1
    noMore.value = false
  }
  if (noMore.value) return
  loading.value = true
  let category_type = ''
  if (props.typeView == 1) {
    category_type = 'plaza'
  } else if (props.typeView == 3) {
    category_type = 'plate'
  } else if (props.typeView == 5) {
    category_type = 'news'
  }
  plaza_post_index({
    start_time: start_time.value,
    end_time: end_time.value,
    category_id: props.channel,
    page: from.page,
    limit: 20,
    type: from.dateType,
    orderway: from.sortId,
    keyword: from.keyword,
    time: props.create_time ? props.create_time : 0,
    category_type
  }).then(res => {
    if (res.code === 1) {
      start_time.value = ''
      end_time.value = ''
      value1.value = ''
      from.page += 1
      from.total = res.data.total
      // 首页广场
      if (props.typeView == 1) {
        if (res.data.platePostList.length > 0) {
          res.data.platePostList.forEach(item => {
            item.text = ''
            if (item.commentList.length > 0) {
              item.commentList.forEach(row => {
                row.isOpen = false
                row.text = ''
                row.page = 1
                row.childernComment = []
              })
            }
          })
          from.list = [...from.list, ...res.data.platePostList]
          if ((from.list && from.list.length === res.data.total) || (res.data.plazaList && res.data.plazaList.length === 0)) {
            noMore.value = true
          }
          let data = from.list
          if (data.table_name == "plate_post") {
            const regex = new RegExp('img', 'gi')
            for (var i = 0; i < from.list.length; i++) {
              data[i].post_content = from.list[i].post_content
                  .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
              data[i].post_content = from.list[i].post_content
                  .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
            }
          }
          from.list = data
        }

        // 新闻资讯
      } else if (props.typeView == 5) {
        res.data.newsList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.newsList]
        if ((from.list.length === res.data.total) || res.data.newsList.length === 0) {
          noMore.value = true
        }
        // 债市论坛
      } else if (props.typeView == 3) {
        res.data.plateList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.plateList]
        if ((from.list.length === res.data.total) || res.data.plateList.length === 0) {
          noMore.value = true
        }
      }
    } else if (res.msg === '尚未开通会员') {
      dialogFormVisible4.value = true
      tan_type.value = 1
    }
  }).finally(() => {
    loading.value = false
  })
  dialogFormVisible.value = false
}

const getNewList1 = (type) => {
  if (type === 'rem') {
    from.list = []
    from.page = 1
    from.time = ''
    noMore.value = false
  }
  if (noMore.value) return
  loading.value = true
  let category_type = ''
  if (props.typeView == 1) {
    category_type = 'plaza'
  } else if (props.typeView == 3) {
    category_type = 'plate'
  } else if (props.typeView == 5) {
    category_type = 'news'
  }
  getHomeList({
    start_time: start_time.value,
    end_time: end_time.value,
    category_id: props.channel,
    channels_id: 5,
    page: from.page,
    limit: 20,
    time: from.time,
    keyword: from.keyword,
    category_type: 'news',
  }).then(res => {
    if (res.code === 1) {
      start_time.value = ''
      end_time.value = ''
      value1.value = ''
      from.page += 1
      from.total = res.data.total
      // 首页广场
      if (props.typeView == 1) {
        if (res.data.platePostList.length > 0) {
          res.data.platePostList.forEach(item => {
            item.text = ''
            if (item.commentList.length > 0) {
              item.commentList.forEach(row => {
                row.isOpen = false
                row.text = ''
                row.page = 1
                row.childernComment = []
              })
            }
          })
          from.list = [...from.list, ...res.data.platePostList]
          if ((from.list && from.list.length === res.data.total) || (res.data.plazaList && res.data.plazaList.length === 0)) {
            noMore.value = true
          }
        }

        // 新闻资讯
      } else if (props.typeView == 5) {
        res.data.newsList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.newsList]
        if ((from.list.length === res.data.total) || res.data.newsList.length === 0) {
          noMore.value = true
        }
        // 债市论坛
      } else if (props.typeView == 3) {
        res.data.plateList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.plateList]
        if ((from.list.length === res.data.total) || res.data.plateList.length === 0) {
          noMore.value = true
        }
      }
    }
  }).finally(() => {
    loading.value = false
  })
  dialogFormVisible.value = false
}

const getNewList2 = (type) => {
    title_show.value = 1
  if (type === 'rem') {
    from.list = []
    from.page = 1
    from.time = ''
    noMore.value = false
  }
  if (noMore.value) return
  loading.value = true
  let category_type = ''
  if (props.typeView == 1) {
    category_type = 'plate'
  } else if (props.typeView == 3) {
    category_type = 'plate'
  } else if (props.typeView == 5) {
    category_type = 'news'
  }
  getHomeList({
    category_id: props.channel,
    channels_id: 3,
    page: from.page,
    limit: 20,
    time: from.time,
    keyword: from.keyword,
    category_type: 'plate',
    start_time: start_time.value,
    end_time: end_time.value,
  }).then(res => {
    if (res.code === 1) {
      from.page += 1
      from.total = res.data.total
      // 首页广场
      if (props.typeView == 1) {
        // if (res.data.total > 0) {
        //   res.data.platePostList.forEach(item => {
        //     item.text = ''
        //     if (item.commentList.length > 0) {
        //       item.commentList.forEach(row => {
        //         row.isOpen = false
        //         row.text = ''
        //         row.page = 1
        //         row.childernComment = []
        //       })
        //     }
        //   })
        //   from.list = [...from.list, ...res.data.platePostList]
        //   if ((from.list && from.list.length === res.data.total) || (res.data.plazaList && res.data.plazaList.length === 0)) {
        //     noMore.value = true
        //   }
        // }
        res.data.plateList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.plateList]
        if ((from.list.length === res.data.total) || res.data.plateList.length === 0) {
          noMore.value = true
        }

        // 新闻资讯
      } else if (props.typeView == 5) {
        res.data.newsList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.newsList]
        if ((from.list.length === res.data.total) || res.data.newsList.length === 0) {
          noMore.value = true
        }
        // 债市论坛
      } else if (props.typeView == 3) {
        res.data.plateList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.plateList]
        if ((from.list.length === res.data.total) || res.data.plateList.length === 0) {
          noMore.value = true
        }
      }
    }
  }).finally(() => {
    loading.value = false
  })
  dialogFormVisible.value = false
}

// 分享到QQ
const shareQQ = () => {
  var url = window.location.href;
  var encodedUrl = encodeURIComponent(url);
  var shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?url=' + encodedUrl;
  window.open(shareUrl);
}

// 分享到微博
const shareMicroBlog = () => {
  let message = String(location.href)
  var url = 'https://service.weibo.com/share/share.php?url=' + encodeURIComponent(message) + '&appkey=MrShi&title=测试标题&language=zh_cn'
  window.open(url)
  // count=表示是否显示当前页面被分享数量(1显示)(可选，允许为空)
  // &url=将页面地址转成短域名，并显示在内容文字后面。(可选，允许为空)
  // &appkey=用于发布微博的来源显示，为空则分享的内容来源会显示来自互联网。(可选，允许为空)
  // &title=分享时所示的文字内容，为空则自动抓取分享页面的title值(可选，允许为空)
  // &pic=自定义图片地址，作为微博配图(可选，允许为空)
  // &ralateUid=转发时会@相关的微博账号(可选，允许为空)
  // &language=语言设置(zh_cn|zh_tw)(可选)
}

//分享到微信
const shareWeChat = () => {
  var url = window.location.href;
  var encodedUrl = encodeURIComponent(url);
  var qrcodeUrl = 'https://login.weixin.qq.com?url=' + encodedUrl;
  window.open(qrcodeUrl)
};

const loadData = () => {
  if (props.channel == 1) {
    from.page += 1
    getNewList4('')
  } else if (props.channel == 3) {
    getNewList2('')
    console.log(345)
  } else {
    getNewList('')
    console.log(567)
  }
  setTimeout(() => {
    isScrolledToBottom.value = false;
  }, 1000);
}

const scrollFn = (e) => {
  //可视区域
  let clientHeight = document.documentElement.clientHeight
  // 滚动文档高度
  let scrollHeight = document.body.scrollHeight
  // 已滚动的高度
  let scrollTop = parseInt(document.documentElement.scrollTop)

  if ((scrollTop + clientHeight) === scrollHeight && scrollTop >= clientHeight) {
    if (!noMore.value) {
      loadData()
      console.log(clientHeight, scrollHeight, scrollTop, noMore.value);
      // shuaxin()
    }
  }
}
const handleTextareaInput = (event) => {
  // Log changes when the textarea value changes
  if (event.target.value.includes('【') && event.target.value.includes('】')) {

  } else {
    event.target.value = '';
    inputtype.value = 3
  }
};
const imageContentResult1 = (e) => {
  loading.value = false;
  let str = e.type;
  // from.list[str].comment_image = from.list[str].comment_image ? from.list[str].comment_image.push(e.response) : [e.response]
  from.list[str].comment_image = from.list[str].comment_image ?? [];
  if (from.list[str].comment_image.length < 3) {
    from.list[str].comment_image.push(e.response);
  } else {
    ElMessage({message: '最多上传三张图片', type: 'error'})
  }
}
const imageContentResult2 = (e) => {
  loading.value = false;
  let str = e.type;
  plateList.value[str].comment_image = plateList.value[str].comment_image ?? [];
  if (plateList.value[str].comment_image.length < 3) {
    plateList.value[str].comment_image.push(e.response);
  } else {
    ElMessage({message: '最多上传三张图片', type: 'error'})
  }
}
const imageContentResult = (e) => {
  loading.value = false;
  let str = e.type;
  if (comment_image.value.length < 3) {
    comment_image.value.push(e.response)
  } else {
    ElMessage({message: '最多上传三张图片', type: 'error'})
  }
}

const handleImageSelected = (e) => {
  loading.value = true;
}

const handleMktoHtml = (e) => {
  return marked(e)
}
// const handleHide = () => {
//   images.value = []
//   visible.value = false
// }
const handleClick = (e) => {
  if (e.target.tagName === 'IMG') {
    // 获取 img 标签的 src 属性
    images.value = [e.target.src]
    visible.value = true
  }
}

const setPostContentRef = (el, index) => {
  if (el) {
    // 确保数组大小与 items 一致
    postContents.value[index] = el;
  }
};

defineExpose({getNewList, getKeyword, getNewList1, getNewList2, getNewList3, getNewList4})

onMounted(() => {
  window.addEventListener("scroll", scrollFn)
  getCategoryLists1()
})

onUnmounted(() => {
  window.removeEventListener("scroll", scrollFn);
})

watchEffect(() => {
  // 每次 items 改变时，重新设置引用
  if (from.list.length > 0) {
    // if(from.list.table_name == 'plate_post'){
    //
    // }
    for (let i = 0; i < from.list.length; i++) {
      showTextInfo(i)
    }
  }
});

const download = (e) => {
  downloadobj(e)
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.rich_text {
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.1em;
}

#rich_text {
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.file_style {
  text-decoration: none;
  color: #26c3dd;
  cursor: pointer;
}

.video-right-option {
  display: flex;
  position: absolute;
  top: 15px;
  right: 10px;
}

.deletePost {
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(0deg, #085EAF, #2791F4);
  border-radius: 4px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  cursor: pointer;
  width: 45px;
  margin-left: 10px;
}

.updatePost {
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(0deg, #085EAF, #2791F4);
  border-radius: 4px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  cursor: pointer;
  width: 45px;
}

.textinfo {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatIcons {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  margin-top: 18px;
  padding: 18px;
  box-sizing: border-box;

  .info {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .chatIcon_head {
    width: 100%;
    height: 30px;
    position: sticky;
    z-index: 9;
    background: #02183F;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chatIcon_head_left {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }

    .chatIcon_head_right {
      display: flex;
      align-items: center;

      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;

            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }

      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .chatIcon_operate {
    width: 100%;
    height: 30px;
    margin: 5px 0 17px 0;
    display: flex;
    align-items: center;
    position: sticky;
    background: #02183F;
    top: 30px;
    z-index: 9;

    .chatIcon_operate_w {
      width: 56px;
      height: 28px;
      font-size: 16px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
    }

    .chatIcon_operate_cate {
      flex: 1;
      margin-left: 30px;
      display: flex;
      align-items: center;

      .active {
        background: linear-gradient(0deg, #085EAF, #2791F4) !important;
      }

      .chatIcon_operate_cate_item {
        width: 36px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        background: #0B3D6C;
        border-radius: 3px;
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 9px;
        cursor: pointer;
      }
    }

    .layout_content_picture_item_head_sele {
      flex-shrink: 0;
      width: 98px;
      height: 23px;
      background: rgba(13, 46, 100, 0.6);
      border-radius: 3px;
      border: 1px solid rgba(119, 198, 253, 0.3);
      padding: 0 11px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      &:hover {
        .sele_menu {
          height: 90px;
        }
      }

      .sele_menu::-webkit-scrollbar {
        width: 5px;
      }

      .sele_menu::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #5D6B82;
      }

      .sele_menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        overflow-y: scroll;
        border-radius: 5px;
        transition: .5s;
        z-index: 9;
        background-color: rgba(13, 46, 100, 0.6);

        .sele_menu_item {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;

          &:hover {
            background-color: rgba(2, 24, 63, 0.5);
          }
        }
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }

      img {
        width: 13px;
        height: 7px;
      }
    }
  }

  .chatIcon_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
}

.chatIcon_list_item {
  padding: 14px;
  background: rgba(13, 46, 100, 1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
  //cursor: pointer;

  &:last-child {
    margin-bottom: 0 !important;
  }

  .chatIcon_list_item_head {
    width: 100%;
    display: flex;
    align-items: center;

    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    .head_name {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      padding-right: 9px;
      margin-right: 9px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    .head_time {
      font-size: 11px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .right-option {
    display: flex;
    position: absolute;
    top: 15px;
    right: 10px;

    .deletePost {
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 4px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      cursor: pointer;
      width: 45px;
      margin-left: 10px;
    }

    .zhuanicon {
      font-family: Source Han Sans CN;
      width: 23px;
      height: 23px;
      margin-left: 10px;
      cursor: pointer;
      background: #6ca9f1;
      color: #FFFFFF;
      text-align: center;
      border-radius: 3px;
    }
  }

  .chatIcon_list_item_info::v-deep {
    width: 100%;
    margin-top: 10px;

    img {
      width: 100%;
    }

    video {
      width: 100%;
    }
  }

  .chatIcon_list_item_pj {
    width: 100%;
    margin-top: 10px;
    cursor: auto;

    .chatIcon_list_item_pj_item {
      width: 100%;
      padding: 8px 14px;
      box-sizing: border-box;
      background: #094074;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 9px;
      position: relative;

      &:last-child {
        margin-bottom: 0 !important;
      }


      .chatIcon_list_item_pj_item_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pj_item_top_user {
          display: flex;
          align-items: center;

          img {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            margin-right: 6px;
          }

          span {
            font-size: 9px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }

        .pj_item_top_time {
          display: flex;
          align-items: center;

          .pj_item_top_time_a {
            display: flex;
            align-items: center;
            padding-right: 6px;
            margin-right: 6px;
            cursor: pointer;
            border-right: 2px solid rgba(255, 255, 255, 0.3);

            img {
              width: 9px;
              height: 8px;
              margin-right: 3px;
            }

            span {
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FCB407;
            }
          }

          .pj_item_top_time_b {
            font-size: 9px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }

      .chatIcon_list_item_pj_item_bottom {
        margin-top: 6px;

        span {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 16px;

          &:first-child {
            color: #FFFFFF;
          }
        }
      }

      .chatIcon_list_item_pj_more {
        margin-top: 10px;
        font-size: 9px;
        color: #fff;
        cursor: pointer;
      }

      .chatIcon_list_item_pj_item_list {
        width: 100%;
        margin-top: 10px;
        padding-left: 20px;
        box-sizing: border-box;

        .chatIcon_list_item_pj_item_list_item {
          width: 100%;
          padding: 8px 0;
          box-sizing: border-box;
          background: #094074;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          position: relative;

          &:last-child {
            margin-bottom: 0 !important;
          }

          .chatIcon_list_item_pj_item_top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .pj_item_top_user {
              display: flex;
              align-items: center;

              img {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                margin-right: 6px;
              }

              span {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }

            .pj_item_top_time {
              display: flex;
              align-items: center;

              .pj_item_top_time_a {
                display: flex;
                align-items: center;
                padding-right: 6px;
                margin-right: 6px;
                cursor: pointer;
                border-right: 2px solid rgba(255, 255, 255, 0.3);

                img {
                  width: 9px;
                  height: 8px;
                  margin-right: 3px;
                }

                span {
                  font-size: 9px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FCB407;
                }
              }

              .pj_item_top_time_b {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }
          }

          .chatIcon_list_item_pj_item_bottom {
            margin-top: 6px;

            span {
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 16px;

              &:first-child {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }

  .comment-image-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .chatIcon_list_item_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 17px;
    cursor: auto;

    .chatIcon_list_item_footer_left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .chatIcon_list_item_footer_left_item {
        flex: 1 1 calc(25% - 10px); /* 调整最后的减数（10px）以适应间隙 */
        margin: 5px; /* 可选，为元素添加一些外边距 */
        display: flex;
        justify-content: center;

        img {
          width: 15px;
          height: 14px;
          margin-right: 4px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }

    .chatIcon_list_item_footer_right {
      width: 195px;
      height: 23px;
      padding: 0 1px 0 13px;
      box-sizing: border-box;
      background: #EAF5FF;
      border-radius: 11px;
      margin-left: 21px;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .inputText {
        flex: 1;
        height: 100%;

        input::-webkit-input-placeholder {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #0361BA;
        }

        input {
          font-size: 9px;
          color: #222222;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0);
          border: none;
          outline: none;
        }
      }

      .caozuo {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        img {
          width: 13px;
          height: 13px;
          margin-right: 8px;
          cursor: pointer;
        }

        .caozuo_btn {
          width: 42px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background: linear-gradient(0deg, #085EAF, #2791F4);
          border-radius: 10px;
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }
}

.chatIcon_list_item_pj_item_pl {
  width: 100%;
  position: relative;

  .item_pl_content {
    width: 100%;

    textarea {
      width: 95%;
      border-radius: 30px;
      height: 15px;
      margin-top: 8px;
      padding: 6px 10px;
      resize: none;
      background: #ebf4fd;
      color: #709cd2;
    }

    textarea:focus {
      outline: none;
    }
  }

  .item_pl_caozuo {
    display: flex;
    position: absolute;
    bottom: 10%;
    right: 3%;

    .item_pl_caozuo_img {
      width: 21px;
      height: 21px;
    }

    .item_pl_caozuo_btn {
      width: 42px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 10px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

</style>
