<template>
    <div class="chatIcons">
        <div class="fenye">
            <img src="@/assets/notfind.png" alt="" />
            <span>暂无数据</span>
        </div>
    </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
    .chatIcons {
        .fenye {
            width: 100%;
            height: 300px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            img {
                width: 80px;
                height: 80px;
            }
            span {
                color: #ffffff98;
                font-size: 13px;
                margin-top: 5px;
            }
        }
    }
</style>