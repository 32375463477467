<template>
    <div class="chatIcon">
        <!-- 刷新 -->
        <div class="chatIcon_head">
            <div class="chatIcon_head_left">
                <img src="@/assets/jian@2x.png" alt="" />

                <span v-if="title_show === 2" style="cursor: pointer" @click="title_fun">{{title_text}}</span>
                <span v-else>
                    <span style="cursor: pointer;" v-if="props.channel == 6" @click="title_fun">{{ props.title }}</span>
                    <span v-else>
                        <span style="cursor: pointer;" @click="title_fun">{{ props.title.slice(0, 4) }}</span>
                        -
                        <span style="cursor: pointer;" @click="getVideoList('rem')">{{ props.title1 }}</span>
                    </span>
                </span>
            </div>
        </div>
        <div v-if="postLists.length == 0" style="height: 260px;width: 100%;background: rgb(13, 46, 100);margin-top: 10px;overflow-y: auto;font-size: 16px;text-align: center;line-height: 260px;">
            暂无说明
        </div>
        <div v-else style="height: 500px;width: 100%;overflow-y: auto;">
            <div class="gundong" style="width: 100%;background: rgb(13, 46, 100);margin-top: 10px;" v-for="(item,index) in postLists" :key="index">
                <div style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;">{{ item.createtime }}</div>
                <div :ref="el => setPostContentRef(el, index)" :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }" style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;" v-html="item.content"></div>

                <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
                    <text v-if="expandStates[index]" style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;"
                          @click="expand(index, $event.target)">展开
                    </text>
                </div>

                <div v-if="item.file_img.length > 0" style="height: 150px;overflow: hidden;position: relative;padding: 10px;">
                    <div v-for="(img, i) in item.file_img" :key="i" style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;">
                        <img :src="img" :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'cover' }" alt="" @click="openPreview1(i,item.file_img)">
                    </div>
                    <img v-if="item.file_img.length >= 3" @click="jumpDetails(item)" alt="" src="@/assets/jia.png" style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;">
                    <div v-if="showPreview1" class="modal" @click="closePreview1">
                        <div class="modal-content">
                            <span class="close" @click="closePreview1">&times;</span>
                            <img :src="images11" alt="Preview" style="object-fit: contain;"/>
                        </div>
                    </div>
                </div>

                <div v-if="item.file_video.length > 0">
                    <div v-for="(img, i) in item.file_video" :key="i">
                        <video :src="img" controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 300px;"></video>
                    </div>
                </div>

                <div v-if="item.file_data.length > 0" style="padding: 10px;color: #26c3dd">
                    <div v-for="(img, i) in item.file_data" :key="i">
                        <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                    </div>
                </div>

                <div v-if="item.file_audio.length > 0" style="margin: 10px;">
                    <div v-for="(img, i) in item.file_audio" :key="i">
                        <audio controls style="width: 100%;">
                            <source :src="img" type="audio/mp3">
                        </audio>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;float: left">
            <div class="aaa" style="cursor: pointer;width: 46%;height: 180px;background-color: #023C67;display: inline-block;margin: 5px 2%;border-radius: 10px;" v-for="(item, index) in from.listinfo" :key="index">
                <img class="image" style="width: 95%;height: 130px;margin-left: 2.5%;margin-top: 5px;border-radius: 10px;object-fit:cover" :src="item.image" alt="" @click="switchs(item.id,item.is_member_expire)"/>
                <div style="display: flex;align-items: center;padding-left: 15px;padding-top: 10px;">
                    <img class="play" style="width: 20px;height: 20px;" src="@/assets/sharpicons_present.png" alt="" />
                    <span style="padding-left: 10px;">{{ item.title }}</span>
                </div>
            </div>

            <div id="bbb" style="display: none;">
                <div style="width: 100%;height: 330px;background-color: #023C67;display: inline-block;margin-top: 10px;border-radius: 10px;">
                    <div id="mse" v-if="introducedata.live_url !== ''"></div>
                    <img class="image" style="width: 95%;height: 220px;margin-left: 2.5%;margin-top: 10px;border-radius: 10px;" :src="introducedata.image" alt="" v-else/>
                    <div style="display: flex;align-items: center;padding-left: 15px;padding-top: 10px;">
                        <img class="play" style="width: 20px;height: 20px;" src="@/assets/zhibo.png" alt="" />
                        <text style="padding-left: 10px;width: 150px;">{{ introducedata.title }}</text>
                        <div style="width: 65px;height: 30px;background-color: #1573CC;border-radius: 5px;line-height: 30px;cursor: pointer;margin-left: 42%;text-align: center">
                            <text style="text-align: center"> 立即报名 </text>
                        </div>
                        <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;"  @click="handleMouseEnter()" v-if="introducedata.rating == 0">
                            <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/evaluate.png" alt="" />
                            <text style="padding-left: 5px;"> 评价 </text>
                        </div>
                        <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;" v-else>
                            <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/evaluate1.png" alt=""/>
                            <text style="padding-left: 5px;color:#5e5b5b"> 评价</text>
                        </div>
                    </div>
                </div>
                <div style="margin: 30px 0;">
                    <text style="font-size: 23px;font-weight: bold;">评论</text>
                    <text style="font-size: 15px;font-weight: bold;color: #94969D;padding-left:10px;">尽情愉快的评论吧</text>
                </div>
                <div id="comment" style="width: 100%;margin: 5px 0;border-radius: 10px;">
                    <div style="margin-top: 5px;background-color: #0D2E64;border-radius: 5px;" v-for="(item, index) in commentlist" :key="index">
                        <div style="width: 90%;height: 20px;padding: 5px;margin-top: 5px;display: flex;align-items: center;justify-content: space-between;">
                            <div style="line-height: 20px;display: flex;align-items: center;">
                                <img class="play" style="width: 20px;height: 20px;border-radius: 100px;margin-left: 8px;" :src="item.user.avatar" alt="" />
                                <text style="padding-left: 5px;"> {{ item.user.nickname }} </text>
                                <text style="padding-left: 5px;"> {{ item.createtime }} </text>
                            </div>
                            <div>
                                <el-rate disabled v-model="item.rating_score" size="large"/>
                            </div>
                        </div>
                        <div style="width: 100%;padding-left: 15px;margin-top: 5px;line-height: 25px;">
                            <div v-html="handleMktoHtml(item.content)" style="font-size: 14px" class="comment-box"></div>
                        </div>
                    </div>
                </div>
                <div class="chatIcon_list_item_pj_item_pl" @click.stop>
                    <div class="item_pl_content">
                    <textarea class="item_pl_content_textarea pltextarea"
                      v-model="comment"
                      rows="1" placeholder="请输入内容" @input="adjustTextareaHeight1()"
                      id='aaaaaa'></textarea>
                    </div>
                    <div class="item_pl_caozuo" style="cursor: pointer">
                        <el-popover
                            placement="bottom"
                            title="请选择表情"
                            :width="300"
                            trigger="click">
                            <template #reference>
                                <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                            </template>
                            <chatIcon @changeText="getIcon"/>
                        </el-popover>
                        <imageContent :content="comment" :obj="item" :type="index"
                                      @result="imageContentResult1" @change="handleImageSelected"></imageContent>
                        <div class="item_pl_caozuo_btn" @click="sendChildComment(introducedata.id, comment)">发送</div>
                    </div>
                </div>
                <div v-if="comment_image && comment_image.length>0" class="comment-image-box">
                    <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in comment_image">
                        <img style="width: 80px;height: 80px;" :src="imgitem">
                        <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg(index)">
                    </div>
                </div>
                <div style="margin-top: 60px;margin-bottom: 30px;display: flex;justify-content: center; align-items: center;">
                    <el-pagination
                        small
                        background
                        layout="prev, pager, next"
                        :total="commentnum"
                        class="mt-4"
                        @currentChange="testfun"
                    />
                </div>
            </div>
        </div>

        <Loading v-if="from.loading" />
        <notFind v-if="zhidata.length === 0" />
        <div class="info">
            <el-text class="mx-1" type="info">没有更多了~</el-text>
        </div>
    </div>

    <el-dialog v-model="dialogFormVisible2" title="开通会员" width="650">
        <div style="height: 200px;display: flex;z-index: 10;margin-top: 15px;margin-right: 20px;" v-if="paytype==1">
            <div style="width: 20%;height: 200px;border: 2px #0B3D6C solid;background-color: #d3dce6;margin-left: 10px;" v-for="(item, index) in vipinfo" :key="index">
                <div style="height: 50%;font-size: 22px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">{{ item.name }}</div>
<!--                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;"><text style="color: red;font-size: 22px">{{ item.membership_price }}</text>￥</div>-->
                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-if="pay_status === 1"><text style="color: red;font-size: 22px">{{ item.membership_price }}</text>￥</div>
                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-else><text style="color: red;font-size: 22px">{{ item.membership_score }}</text></div>
                <div style="text-align: center;padding-top: 15px">
                    <el-button type="primary" v-if="pay_status === 1" @click="dredge(item.id)">开通</el-button>
                    <el-button type="primary" v-else @click="dredge1(item.id)">积分开通</el-button>
                </div>
            </div>
        </div>
        <div style="height: 200px;text-align: center;display: flex" v-else>
            <img :src="payimg" style="width: 200px;height: 200px" alt="" />
        </div>

        <template #footer>
      <span class="dialog-footer" style="margin-right: 10px;">
           <text style="color: #000000;padding-right: 20px;" v-if="pay_status !== 1">现有积分:{{ userinfo.score }}</text>
        <el-button @click="cancellation()">取消</el-button>
      </span>
        </template>
    </el-dialog>


    <el-dialog v-model="dialogFormVisible3" title="开通会员" width="500">
        <div style="margin-top: 15px;margin-left: 20px;">暂未开通会员,开通会员观看!</div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取消</el-button>
        <el-button type="primary" @click="confirm">
          确认
        </el-button>
      </span>
        </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible4" title="开通会员" width="500">
        <div style="margin-top: 15px;margin-left: 20px;">
            <el-button @click="payment">付费</el-button>
            <el-button @click="exchange">积分兑换</el-button>
            <el-button @click="apply_for">审核申请</el-button>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false">取消</el-button>
      </span>
        </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible1" title="评价" width="300">
        <div style="display: flex;justify-content: center; align-items: center;">
            <el-rate size="large" @change="comment_star"/>
        </div>
    </el-dialog>

    <el-dialog title="提示" v-model="dialogFormVisible5" width="30%" draggable>
        <div>确定要评价{{many_stars}}星吗?</div>
        <div slot="footer" class="dialog-footer"
             style="padding-top: 20px;display: flex;justify-content: center;align-items: center;">
            <el-button @click="dialogFormVisible5 = false">取消</el-button>
            <el-button type="primary" @click="confirmCheck()">确定</el-button>
        </div>
    </el-dialog>
</template>

<script setup>
import Player from "xgplayer";
import HlsJsPlayer from 'xgplayer-hls.js'; // M3U8格式
import chatIcon from '@/components/chatIcon.vue'
import imageContent from '@/components/imageContent.vue'
import textareaComponent from '@/components/textareaComponent.vue'
import {defineProps, defineExpose, defineEmits, reactive, ref, onMounted, onUnmounted, nextTick, watchEffect} from 'vue'
import { useRouter } from 'vue-router'
import {
    videoList,
    plaza_post_index,
    getVipList,
    createVip,
    payVip,
    Vipquery,
    getAlbumVideoList,
    getComment,
    addFavorite,
    delFavorite,
    addComment,
    addRating,
    show, getHomeList, scorePay, goodsShow, getUserDetails,postGetList
} from '@/api'
import Loading from '@/components/Loading.vue'
import {downloadobj, timestampToTime} from '@/utils/utils.js'
import notFind from '@/components/notFind.vue'
import {ElMessage} from "element-plus";
import {marked} from "marked";
import Video from "@/components/video.vue";


const dialogFormVisible = ref(false)
const dialogFormVisible1 = ref(false)
const props = defineProps({
    title: String,
    title1: String,
    channel: Number,     // 栏目id
    timeVal: Number,
    create_time: String
})
let title = ref('')
let title1 = ref('')
let titletype = ref(0)
let payimg = ref('')
let paytype = ref(1)
let vipinfo = ref([])
let introducedata = ref([])
let postLists = ref([])
let commentlist = ref([])
let commentnum = ref([])
let whichpage = ref([])
let comment_image = ref([])
let commentdata = ref([])
let is_collection = ref(1)
let dialogFormVisible2 = ref(false)
let dialogFormVisible3 = ref(false)
let dialogFormVisible4 = ref(false)
let dialogFormVisible5 = ref(false)
let many_stars = ref('')
let pay_status = ref(1)
let pay_alid = ref(1)
let pay_al_type = ref(1)
let order_exchange = ref(0)
let comment = ref('')
let videowhichpage = ref('')
let videopage = ref('')
let zhidata = ref('')
let player = ref({});
const emit = defineEmits(['finish','openPreview'])
const router = useRouter()
let noMore = ref(false)
let userinfo = ref([])
let title_show = ref(1)
let title_text = ref('')
let from = reactive({
    listLeft: [],
    listRight: [],
    page: 1,
    title: '',
    create_time: '',
    total: 0,
    loading: true,
    keyword: '',
    sortId: 'desc',
    dateType: ''
})


let images11 = ref([])
let showPreview1 = ref(false)
const expandStates = reactive(new Array(postLists.value.length).fill(false));
const postContents = ref([]); // 存储 DOM 引用的数组

const title_fun = () => {
    updateTitleAndFavicon1('复盘 - 直播专区')
    getVideoList1('rem')
    title_text.value = '直播专区'
    title_show.value = 2
}

//文件下载
const download = (e) => {
    downloadobj(e)
}
const getOpacity = (index) => {
    return index === 2 ? 0.2 : 1;
}
const openPreview1 = (index,ima) => {
    emit('openPreview', {index, ima})
    //
    // console.log(ima)
    //
    // images11.value = ima
    // showPreview1.value = true;
}
const closePreview1 = () => {
    showPreview1.value = false;
}
// 跳转详情
const jumpDetails = (item) => {
    localStorage.setItem('txt_item', JSON.stringify(item));
    const routeUrl = router.resolve({
        path: "/home/BackendText",
    });
    window.open(routeUrl.href, "_blank");
}

const setPostContentRef = (el, index) => {
    if (el) {
        // 确保数组大小与 items 一致
        postContents.value[index] = el;
    }
};
const expand = (index, el) => {
    if (postContents.value[index].classList.contains('textinfo')) {
        postContents.value[index].classList.remove('textinfo')
        el.textContent = "收起";
    } else {
        postContents.value[index].classList.add('textinfo')
        el.textContent = "展开";
    }
}
const showTextInfo = async (index) => {
    await nextTick();
    let el = postContents.value[index]
    if (el) {
        expandStates[index] = el.scrollHeight > el.offsetHeight
    }
}
watchEffect(() => {
    // 每次 items 改变时，重新设置引用
    if (postLists.value.length > 0) {
        // if(from.list.table_name == 'plate_post'){
        //
        // }
        for (let i = 0; i < postLists.value.length; i++) {
            showTextInfo(i)
        }
    }
});


// 关键字搜索
const getKeyword = (val) => {
    from.title = val
    getVideoList('rem')
}

const handleMouseEnter = () => {
    dialogFormVisible1.value = true
}

const confirm = (val) => {
    dialogFormVisible2.value = true
    dialogFormVisible3.value = false
    if(pay_status.value === 1){
        // getVipList().then(res => {
        //     if (res.code === 1) {
        //         vipinfo.value = res.data
        //     }
        // })
        if(pay_al_type.value === 2){
            goodsShow({
                goods_type: 'articles',
                table_name: 'live',
                goods_id: pay_alid.value,
            }).then(res => {
                if (res.code === 1) {
                    vipinfo.value = res.data
                }
            })
        }else{
            goodsShow({
                goods_type: 'categories',
                table_name: 'live_category',
                goods_id: props.channel,
            }).then(res => {
                if (res.code === 1) {
                    vipinfo.value = res.data
                }
            })
        }
    }
}

const exchange = (val) => {
    pay_status.value = 2
    dialogFormVisible4.value = false
    dialogFormVisible3.value = true
    getUserDetails().then(res => {
        userinfo.value = res.data
    });
    if(pay_al_type.value === 2){
        goodsShow({
            goods_type: 'articles',
            table_name: 'live',
            goods_id: pay_alid.value,
        }).then(res => {
            if (res.code === 1) {
                vipinfo.value = res.data
            }
        })
    }else{
        goodsShow({
            goods_type: 'categories',
            table_name: 'live_category',
            goods_id: props.channel,
        }).then(res => {
            if (res.code === 1) {
                vipinfo.value = res.data
            }
        })
    }
}

const dredge1 = (id) => {
    //差一个积分支付的接口
    let aaaaa = '';
    if(pay_al_type.value === 2){
        createVip({
            goods_type:'articles',
            table_name:'live',
            goods_id:pay_alid.value,
            pay_type:2,
        }).then(res => {
            if (res.code === 1){
                order_exchange.value = res.data.order_id
                dialogFormVisible4.value = false
                dialogFormVisible2.value = false
                scorePay({order_id:order_exchange.value}).then(res1=>{
                    if (res1.code === 1){
                        ElMessage({message: res1.msg, type: 'success'})
                    }
                })
            }
        })
    }else{
        createVip({
            goods_type:'categories',
            table_name:'live_category',
            goods_id:props.channel,
            pay_type:2,
        }).then(res => {
            if (res.code === 1){
                order_exchange.value = res.data.order_id
                dialogFormVisible4.value = false
                dialogFormVisible2.value = false
                scorePay({order_id:order_exchange.value}).then(res1=>{
                    if (res1.code === 1){
                        ElMessage({message: res1.msg, type: 'success'})
                    }
                })
            }
        })
    }
}

const payment = (val) => {
    pay_status.value = 1
    dialogFormVisible4.value = false
    dialogFormVisible3.value = true
}
const apply_for = () => {
    if(pay_al_type.value === 2){
        createVip({
            goods_type:'articles',
            table_name:'live',
            goods_id:pay_alid.value,
            pay_type:3,
        }).then(res => {
            if (res.code === 1){
                ElMessage({message: '提交成功,等待审核', type: 'success'})
                dialogFormVisible4.value = false
            }
        })
    }else{
        createVip({
            goods_type:'categories',
            table_name:'live_category',
            goods_id:props.channel,
            pay_type:3,
        }).then(res => {
            if (res.code === 1){
                ElMessage({message: '提交成功,等待审核', type: 'success'})
                dialogFormVisible4.value = false
            }
        })
    }
}


const getVideoList = (type) => {
    title_show.value = 1
    from.loading = true
    if (type === 'rem') {
        from.listLeft = []
        from.listRight = []
        from.listinfo = []
        from.page = 1
        noMore.value = false
    }
    if (noMore.value) return
    // videoList({
    //     title: from.title,
    //     per_page: 10,
    //     page: from.page,
    //     create_time: props.create_time ? props.create_time : timestampToTime(null, 1)
    // })
    postGetLists(props.channel)
    plaza_post_index({
        category_id: props.channel,
        page: from.page,
        limit: 20,
        type: props.create_time ? '' : from.dateType,
        orderway: from.sortId,
        keyword: from.keyword,
        time: props.create_time ? props.create_time : 0,
        category_type: 'live'
    }).then(res => {
        if (res.code === 1) {
            from.page += 1
            from.listRight = []
            from.listLeft = []
            from.listinfo = []
            res.data.liveList.forEach(item => {
                from.listinfo.push(item)
            })
            zhidata.value = res.data.liveList
            from.total = res.data.total
            let num = res.data.liveList.length
            if ((num === res.data.total) || res.data.videoList.length === 0) {
                noMore.value = true
            }
        } else if (res.msg === '尚未开通会员') {
            dialogFormVisible4.value = true
        }
    }).finally(() => {
        from.loading = false
    })

}

const getVideoList1 = (type) => {
    from.loading = true
    if (type === 'rem') {
        from.listLeft = []
        from.listRight = []
        from.listinfo = []
        from.page = 1
        from.time = ''
        noMore.value = false
    }
    if (noMore.value) return
    postGetLists(6)
    getHomeList({
        category_id: props.channel,
        channels_id: 6,
        page: from.page,
        limit: 20,
        time: from.time,
        keyword: from.keyword,
        category_type: 'live'
    }).then(res => {
        if (res.code === 1) {
            from.page += 1
            from.listRight = []
            from.listLeft = []
            from.listinfo = []
            res.data.liveList.forEach(item => {
                from.listinfo.push(item)
            })
            zhidata.value = res.data.liveList
            from.total = res.data.total
            let num = res.data.liveList.length
            if ((num === res.data.total) || res.data.videoList.length === 0) {
                noMore.value = true
            }
        }else if (res.msg === '尚未开通会员'){
            dialogFormVisible1.value = true
        }
    }).finally(() => {
        from.loading = false
    })
}

const switchs = (val,is_member_expire) => {
    if (is_member_expire !== 1){
        var elements = document.getElementsByClassName('aaa');
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none';
        }
        document.getElementById('bbb').style.display = 'block';
        show({
            category_type: 'live',
            id: val,
        }).then(res => {
            if(res.code === 1){
                introducedata.value = res.data.liveInfo
                if (introducedata.value.live_url !== ''){
                    player.value = new Player({
                        id: 'mse',
                        autoplay: true,
                        fluid: true,
                        url: res.data.liveInfo.live_url,
                        plugins: [HlsJsPlayer]
                    })
                }
            }
        })
        fun_getComment(val,10,1)
    }else {
        dialogFormVisible4.value = true
        pay_alid.value = val
        pay_al_type.value = 2
    }
}

const confirmCheck = () => {
    addRating({
        table_name: 'live',
        object_id: introducedata.value.id,
        score: many_stars.value
    }).then(res => {
        if(res.code === 1){
            ElMessage({message: '感谢您的评价', type: 'success'})
            fun_getComment(introducedata.value.id,10,1)
            dialogFormVisible5.value = false
        }
    })
}

const comment_star = (e) => {
    dialogFormVisible5.value = true
    dialogFormVisible1.value = false
    many_stars.value = e
}
const adjustTextareaHeight1 = (id) => {
    // 动态调整textarea高度的方法，可以根据实际需求进行调整
    // 例如，可以根据内容的行数来动态设置高度
    const textarea = document.getElementById('aaaaaa')
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
}
const imageContentResult1 = (e) => {
    let str = e.type;
    from.loading = false
    if(comment_image.value.length < 3){
        comment_image.value.push(e.response)
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const handleImageSelected = (e) => {
    from.loading = true;
}
const delimg = (e) => {
    comment_image.value.splice(e, 1);
}
const sendChildComment = (obj, data) => {
    let content = data
    // if(content.length > 140){
    //     ElMessage({message: '字数不能超过140', type: 'error'})
    //     return
    // }
    if (comment_image.value && comment_image.value.length > 0) {
        for (let i = 0; i < comment_image.value.length; i++) {
            content += `![Image Description](${comment_image.value[i]})`
        }
    }
    addComment({
        type:'live',
        aid: obj,
        pid: 0,
        content: content,
        top_pid:0
    })
        .then(res => {
            if(res.code === 1){

                fun_getComment(obj,10,1)
                comment.value = ''
                comment_image.value = ''
            }
        })
}
const getIcon = (e) => {
    comment.value += e
}
const handleMktoHtml = (e) => {
    return e !== undefined ? marked(e) : ''
}
const fun_getComment = (aaa,bbb,ccc) => {
    getComment({
        aid: aaa,
        type: 'live',
        limit: bbb,
        page: ccc
    }).then(res => {
        if(res.code === 1){
            let data = res.data.list
            // const regex = new RegExp('img', 'gi')
            // for (var i = 0; i < res.data.list.length; i++) {
            //     data[i].content = res.data.list[i].content
            //         .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
            //     data[i].content = res.data.list[i].content
            //         .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
            // }
            commentlist.value = data
            commentnum.value = res.data.total
            whichpage.value = res.data.current_page
        }
    })
}
// const prev_click = () => {
//     if(whichpage.value !== 1){
//         fun_getComment(introducedata.value.id,10,whichpage.value - 1)
//     }else{
//         fun_getComment(introducedata.value.id,10,whichpage.value)
//     }
// }
// const next_click = () => {
//     fun_getComment(introducedata.value.id,10,whichpage.value + 1)
// }
const testfun = (e)=>{
    fun_getComment(introducedata.value.id,10,e)
}

const imageContentResult = (e) => {
    let str = e.type;
    let obj = eval(str);
    for (let i = 0; i < obj.length; i++) {
        if (obj[i].category_type === e.item.category_type && obj[i].id === e.item.id) {
            obj[i].text = e.content
        }
    }
}

const jump = (id) => {
    const routeUrl = router.resolve({
        path: "/home/videoDetails",
        query: { id }
    });
    window.open(routeUrl.href, "_blank");
}

const finish = () => {
    getVideoList('rem')
    emit('finish')
}

const cancellation = () => {
    dialogFormVisible2.value = false
    paytype.value = 1
}
const dredge = (id) => {
    let goods_types = ''
    let table_names = ''
    let goods_ids = ''
    if(pay_al_type.value === 2){
        goods_types = 'articles'
        table_names = 'live'
        goods_ids = pay_alid.value
    }else{
        goods_types = 'categories'
        table_names = 'live_category'
        goods_ids = props.channel
    }
    createVip({
        goods_type:goods_types,
        table_name:table_names,
        goods_id:goods_ids,
    }).then(res => {
        if (res.code === 1){
            payVip({order_id:res.data.order_id}).then(res1=>{
                if (res1.code === 1){
                    payimg.value = res1.data.code_url
                    paytype.value = 2
                    const intervalId = setInterval(() => {
                        Vipquery({ order_id: res.data.order_id }).then(res2 => {
                            if (res2.code === 1) {
                                if (res2.data.pay_status === 1) {
                                    clearInterval(intervalId); // 停止轮询
                                    location.reload();
                                }
                            }
                        });
                    }, 2000);
                }
            })
        }

    })
}

const updateTitleAndFavicon1 = (text) => {
    document.title = text;
}

const postGetLists = (channels_id) => {
    postGetList({
        channels_id:channels_id,
        page: 1,
        limit: 200,
    }).then(res => {
        if (res.code === 1){
            postLists.value = res.data.data
            console.log(res,678)
        }
    })
}

// const scrollFn = (e) => {
//     //可视区域
//     let clientHeight = document.documentElement.clientHeight
//     // 滚动文档高度
//     let scrollHeight = document.body.scrollHeight
//     // 已滚动的高度
//     let scrollTop = parseInt(document.documentElement.scrollTop)
//     if ((scrollTop + clientHeight) === scrollHeight) {
//         if (!noMore.value) {
//             getVideoList()
//         }
//     }
// }
defineExpose({ getVideoList, getKeyword, getVideoList1 })

// onMounted(() => {
//     // window.addEventListener("scroll", scrollFn)
//     postGetLists()
// })

// onUnmounted(() => {
    // window.removeEventListener("scroll", scrollFn);
// })
</script>

<style lang="scss" scoped>
.gundong::-webkit-scrollbar {
    display: none;
}
.chatIcon_list_item_head {
    width: 100%;
    display: flex;
    align-items: center;

    img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
    }

    .head_name {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        padding-right: 9px;
        margin-right: 9px;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    .head_time {
        font-size: 11px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
    }
}
.chatIcon {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  padding: 18px;
  box-sizing: border-box;
  .info {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .chatIcon_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chatIcon_head_left {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }
      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }
    .chatIcon_head_right {
      display: flex;
      align-items: center;
      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;
            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }
      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;
        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }
        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }
  .chatIcon_live {
    width: 48%;
    height: 213px;
    border-radius: 6px;
    //margin-top: 13px;
    overflow: hidden;
    display: inline-block;
    //align-items: center;
    //justify-content: center;
    position: relative;
    //margin-bottom: 21px;
    margin: 13px 2% 21px;
    .image {
      width: 100%;
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 31px;
      height: 31px;
      cursor: pointer;
      transform: translate(-50%, -50%);
    }
    .chatIcon_live_text {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 23px 12px 23px;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
  .chatIcon_list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .chatIcon_list_left {
      width: 100%;
      height: auto;
      .chatIcon_list_left_item {
        width: 100%;
        height: 256px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        position: relative;
        cursor: pointer;
        &:nth-child(even) {
          height: 355px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .chatIcon_list_left_item_img {
          width: 100%;
          height: 100%;
          img {
            height: 100%;
          }
        }
        .chatIcon_list_left_item_wz {
          width: 100%;
          position: absolute;
          bottom: 14px;
          left: 0;
          padding: 0 15px;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
    .chatIcon_list_right {
      width: 242px;
      height: auto;
      .chatIcon_list_right_item {
        width: 100%;
        height: 355px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        position: relative;
        cursor: pointer;
        &:nth-child(even) {
          height: 256px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        img {
          height: 100%;
        }
        .chatIcon_list_right_item_wz {
          width: 100%;
          position: absolute;
          bottom: 14px;
          left: 0;
          padding: 0 15px;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}
.chatIcon_list_item_pj_item_pl {
    width: 100%;
    position: relative;

    .item_pl_content {
        width: 100%;

        textarea {
            width: 95%;
            border-radius: 30px;
            height: 15px;
            margin-top: 20px;
            padding: 6px 10px;
            resize: none;
            background: #ebf4fd;
            color: #709cd2;
        }

        textarea:focus {
            outline: none;
        }
    }

    .item_pl_caozuo {
        display: flex;
        position: absolute;
        bottom: 5%;
        right: 3%;

        .item_pl_caozuo_img {
            width: 21px;
            height: 21px;
        }

        .item_pl_caozuo_btn {
            width: 42px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: linear-gradient(0deg, #085EAF, #2791F4);
            border-radius: 10px;
            font-size: 9px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

.chatIcon_list_item_footer_right {
  width: 195px;
  height: 23px;
  padding: 0 1px 0 13px;
  box-sizing: border-box;
  background: #EAF5FF;
  border-radius: 11px;
  margin-left: 21px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: right;

  .inputText {
    flex: 1;
    height: 100%;

    input::-webkit-input-placeholder {
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0361BA;
    }

    input {
      font-size: 9px;
      color: #222222;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      outline: none;
    }
  }

  .caozuo {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    img {
      width: 13px;
      height: 13px;
      margin-right: 8px;
      cursor: pointer;
    }

    .caozuo_btn {
      width: 42px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 10px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100vh;
}

.textinfo {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
