<template>
  <div class="home">
    <!-- <el-input id="textarea" class="chatInput" type="textarea" :rows="3" placeholder="请输入内容" v-model="message"></el-input>
    <chatIcon @changeText="getIcon" /> -->
    首页
  </div>
</template>

<script setup>
import chatIcon from '@/components/chatIcon.vue'
import { ref } from 'vue'
let message = ref('')
const getIcon = (e) => {
  message.value = message.value += e
}
</script>

<style lang="scss" scoped>

</style>