<template>
    <div class="layout">
        <div class="layout_content">
            <!-- v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)" -->
            <div class="chatIcon_list">
                <div class="chatIcon_list_item">
                    <div class="chatIcon_list_item_head">
                        <img :src="info?.newsInfo?.user?.avatar" alt="" />
                        <span class="head_name">{{ info?.newsInfo?.user?.nickname }}</span>
                        <span class="head_time">{{ info?.newsInfo?.create_time }}</span>
                    </div>
                    <div class="chatIcon_list_item_info" v-html="info?.newsInfo?.content"></div>
                    <div class="chatIcon_list_item_pj" v-if="info.commentList && info.commentList.length > 0">
                        <div class="chatIcon_list_item_pj_item" v-for="(item, index) in info.commentList" :key="index">
                            <div class="chatIcon_list_item_pj_item_pl" v-if="item.active">
                                <div class="item_pl_user">
                                    <span>@{{item.user.nickname}}</span>
                                    <span @click="closeComment(item)">x</span>
                                </div>
                                <div class="item_pl_content">
                                    <textarea v-model="item.text" placeholder="请输入内容"></textarea>
                                </div>
                                <div class="item_pl_caozuo">
                                    <el-popover
                                        placement="bottom"
                                        title="请选择表情"
                                        @before-enter="beforeIconChild(index)"
                                        :width="300"
                                        trigger="click">
                                        <template #reference>
                                            <img src="@/assets/icon.png" alt="" />
                                        </template>
                                        <chatIcon @changeText="getIconChild" />
                                    </el-popover>
                                    <img src="@/assets/image.png" alt="" />
                                    <div class="item_pl_caozuo_btn" @click="sendChildComment(item)">发送</div>
                                </div>
                            </div>
                            <div class="chatIcon_list_item_pj_item_top">
                                <div class="pj_item_top_user">
                                    <img :src="item.user.avatar" alt="" />
                                    <span>{{ item.user.nickname }}的评论</span>
                                </div>
                                <div class="pj_item_top_time">
                                    <div class="pj_item_top_time_a" @click="handleMouseEnter(index)">
                                        <img src="@/assets/pl@2x.png" alt="" />
                                        <span>评论</span>
                                    </div>
                                    <div class="pj_item_top_time_b">{{ item.createtime }}</div>
                                </div>
                            </div>
                            <div class="chatIcon_list_item_pj_item_bottom">
                                <span>{{ item.content }}</span>
                                <span></span>
                                <!-- <span>跟多</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="chatIcon_list_item_footer">
                        <div class="chatIcon_list_item_footer_left">
                            <div class="chatIcon_list_item_footer_left_item" @click="Like()">
                                <img :src="info?.newsInfo?.is_like == 1 ? require('@/assets/dianz.png') : require('@/assets/zz.png')" alt="" />
                                <span>{{ info?.newsInfo?.post_like }}</span>
                            </div>
                            <div class="chatIcon_list_item_footer_left_item" @click="collect()">
                                <img :src="info?.newsInfo?.is_favorite == 1 ? require('@/assets/shouc.png') : require('@/assets/sc.png')" alt="" />
                                <span>收藏</span>
                            </div>
                            <el-popover
                                :width="150"
                                trigger="hover">
                                <template #reference>
                                    <div class="chatIcon_list_item_footer_left_item">
                                        <img src="@/assets/zf.png" alt="" />
                                        <span>转发</span>
                                    </div>
                                </template>
                                <div style="width: 100%; display: flex; align-items: center;">
                                    <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                        <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt="" />
                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>
                                    </div>
                                    <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                        <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt="" />
                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>
                                    </div>
                                    <div style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                        <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt="" />
                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>
                                    </div>
                                </div>
                            </el-popover>
                            <div class="chatIcon_list_item_footer_left_item">
                                <img src="@/assets/pl.png" alt="" />
                                <span>评论</span>
                            </div>
                        </div>
<!--                        <div class="chatIcon_list_item_footer_right">-->
<!--                            <div class="inputText">-->
<!--                                <input type="text" v-model="from.text" placeholder="分享你的评论" />-->
<!--                            </div>-->
<!--                            <div class="caozuo">-->
<!--                                <el-popover-->
<!--                                    placement="bottom"-->
<!--                                    title="请选择表情"-->
<!--                                    :width="300"-->
<!--                                    trigger="click">-->
<!--                                    <template #reference>-->
<!--                                        <img src="@/assets/icon.png" alt="" />-->
<!--                                    </template>-->
<!--                                    <chatIcon @changeText="getIcon" />-->
<!--                                </el-popover>-->
<!--                                <img src="@/assets/image.png" alt="" />-->
<!--                                <div class="caozuo_btn" @click="sendCommentVal()">发送</div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref, reactive, onMounted } from 'vue';
import { show, addLive, delLive, addFavorite, delFavorite, addComment } from '@/api'
import chatIcon from '@/components/chatIcon.vue'
import { ElMessage } from 'element-plus'

const route = useRoute()

let from = reactive({
    index: 0,
    text: ''
})

let info = ref({})

const handleMouseEnter = (index) => {
    info.value.commentList.forEach((row, i) => {
        row.text = ''
        row.active = i === index
    })
}

// 子级评论选择表情
const getIconChild = (e) => {
    info.value.commentList[from.index].text += e
}

// 子级评论选择表情
const beforeIconChild = (a) => {
    from.index = a
}

const closeComment = (item) => {
    item.active = false
    item.text = ''
}

// 点赞
const Like = () => {
    if (info.value.newsInfo.is_like == 1) {
        delLive({ table_name: 'news', object_id: route.query.id })
            .then(res => {
                if (res.code === 1) {
                    info.value.newsInfo.is_like = 0
                    info.value.newsInfo.post_like -= 1
                }
            })
    } else {
        addLive({ table_name: 'news', object_id: route.query.id })
            .then(res => {
                if (res.code === 1) {
                    info.value.newsInfo.is_like = 1
                    info.value.newsInfo.post_like += 1
                }
            })
    }
}

// 收藏
const collect = () => {
    if (info.value.newsInfo.is_favorite == 1) {
        delFavorite({ table_name: 'news', object_id: route.query.id })
            .then(res => {
                if (res.code === 1) {
                    info.value.newsInfo.is_favorite = 0
                }
            })
    } else {
        addFavorite({ table_name: 'news', object_id: route.query.id })
            .then(res => {
                if (res.code === 1) {
                    info.value.newsInfo.is_favorite = 1
                }
            })
    }
}

// 父级发送评论
const sendCommentVal = () => {
    if (!from.text) return ElMessage({
        message: '请先输入评论内容',
        grouping: true,
        type: 'warning',
    })
    addComment({
        type: 'news',
        aid: info.value.newsInfo.id,
        pid: 0,
        content: from.text
    }).then(res => {
        if (res.code === 1) {
            from.text = ''
            info.value.commentList.unshift(res.data.comment)
        }
    })
}

// 父级评论选择表情
const getIcon = (e) => {
    from.text += e
}

// 发送子级评论
const sendChildComment = (item) => {
    if (!item.text) return ElMessage({
        message: '请先输入评论内容',
        grouping: true,
        type: 'warning',
    })
    addComment({
        type: 'news',
        aid: info.value.newsInfo.id,
        pid: item.id,
        content: item.text
    }).then(res => {
        if (res.code === 1) {
            item.text = ''
            item.active = false
            info.value.commentList.unshift(res.data)
        }
    })
}

onMounted(() => {
    show({ category_type: 'news', id: route.query.id })
        .then(res => {
            if (res.code === 1) {
                info.value = res.data
                info.value.commentList.forEach(item => {
                    item.text = ''
                })
            }
        })
    // getNewsDetail(route.query.id)
    //     .then(res => {
    //         if (res.code === 1) {
    //             info.value = res.data
    //             info.value.commentList.forEach(item => {
    //                 item.text = ''
    //             })
    //         }
    //     })
})
</script>

<style lang="scss" scoped>
    .layout {
        width: 100%;
        min-width: 975px;
        min-height: 100vh;
        background-color: #0d2e64;
        display: flex;
        justify-content: center;
        position: relative;
        .layout_content {
            width: 975px;
            padding: 20px 0;
            box-sizing: border-box;
            height: auto;
            color: azure;
            .chatIcon_list {
                width: 100%;
                display: flex;
                flex-direction: column;
                background: rgb(11 37 79);
                border-radius: 6px;
                .chatIcon_list_item {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    .chatIcon_list_item_head {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        img {
                            width: 40px;
                            height: 40px;
                            margin-right: 10px;
                            border-radius: 50%;
                        }
                        .head_name {
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            padding-right: 9px;
                            margin-right: 9px;
                            border-right: 1px solid rgba(255, 255, 255, 0.5);
                        }
                        .head_time {
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: rgba(255, 255, 255, 0.5);
                        }
                    }
                    .chatIcon_list_item_info::v-deep {
                        width: 100%;
                        margin-top: 15px;
                        span {
                            width: 100%;
                            overflow:hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            font-size: 16px;
                            font-family: Source Han Sans CN;
                            // font-weight: bold;
                            &:first-child {
                                color: #FFFFFF;
                            }
                            &:last-child {
                                cursor: pointer;
                                color: rgba(7, 243, 252, 1);
                            }
                        }
                        img {
                            width: 100%;
                        }
                    }
                    .chatIcon_list_item_pj {
                        width: 100%;
                        margin-top: 15px;
                        .chatIcon_list_item_pj_item {
                            width: 100%;
                            padding: 12px 14px;
                            box-sizing: border-box;
                            background: #094074;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 9px;
                            position: relative;
                            &:last-child {
                                margin-bottom: 0 !important;
                            }
                            .chatIcon_list_item_pj_item_pl {
                                position: absolute;
                                left: 0;
                                width: 100%;
                                padding: 0 16px;
                                box-sizing: border-box;
                                // height: 105px;
                                background: #FFFFFF;
                                border: 1px solid #1784FC;
                                border-radius: 6px;
                                top: 30px;
                                z-index: 99;
                                .item_pl_user {
                                    width: 100%;
                                    height: 30px;
                                    line-height: 30px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    border-bottom: 1px solid rgba(216, 216, 216, 1);
                                    span {
                                        &:first-child {
                                            font-size: 10px;
                                            font-family: Source Han Sans CN;
                                            font-weight: 500;
                                            color: #1784FC;
                                        }
                                        &:last-child {
                                            cursor: pointer;
                                            font-size: 16px;
                                            font-family: Source Han Sans CN;
                                            font-weight: 500;
                                            color: #000;
                                        }
                                    }
                                }
                                .item_pl_content {
                                    width: 100%;
                                    textarea {
                                        width: 100%;
                                        height: 50px;
                                        border: none;
                                        outline: none;
                                        font-size: 14px;
                                        resize: none;
                                        padding: 10px 0;
                                        box-sizing: border-box;
                                        font-family: Source Han Sans CN;
                                        font-weight: 400;
                                        color: #333333;
                                    }
                                }
                                .item_pl_caozuo {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                    padding-bottom: 8px;
                                    box-sizing: border-box;
                                    img {
                                        flex-shrink: 0;
                                        width: 20px;
                                        height: 20px;
                                        margin-right: 11px;
                                        cursor: pointer;
                                    }
                                    .item_pl_caozuo_btn {
                                        width: 42px;
                                        height: 20px;
                                        line-height: 20px;
                                        text-align: center;
                                        background: linear-gradient(0deg, #085EAF, #2791F4);
                                        border-radius: 10px;
                                        font-size: 14px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 500;
                                        color: #FFFFFF;
                                        cursor: pointer;
                                    }
                                }
                            }
                            .chatIcon_list_item_pj_item_top {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .pj_item_top_user {
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        margin-right: 6px;
                                    }
                                    span {
                                        font-size: 13px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 500;
                                        color: #FFFFFF;
                                    }
                                }
                                .pj_item_top_time {
                                    display: flex;
                                    align-items: center;
                                    .pj_item_top_time_a {
                                        display: flex;
                                        align-items: center;
                                        padding-right: 6px;
                                        margin-right: 6px;
                                        cursor: pointer;
                                        border-right: 2px solid rgba(255, 255, 255, 0.3);
                                        img {
                                            width: 13px;
                                            height: 13px;
                                            margin-right: 3px;
                                        }
                                        span {
                                            font-size: 13px;
                                            font-family: Source Han Sans CN;
                                            font-weight: 400;
                                            color: #FCB407;
                                        }
                                    }
                                    .pj_item_top_time_b {
                                        font-size: 13px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 500;
                                        color: #FFFFFF;
                                    }
                                }
                            }
                            .chatIcon_list_item_pj_item_bottom {
                                margin-top: 15px;
                                span {
                                    font-size: 14px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    line-height: 16px;
                                    &:first-child {
                                        color: #FFFFFF;
                                    }
                                    &:last-child {
                                        cursor: pointer;
                                        color: rgba(7, 243, 252, 1);
                                    }
                                }
                            }
                        }
                    }
                    .chatIcon_list_item_footer {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 17px;
                        cursor: auto;
                        .chatIcon_list_item_footer_left {
                            width: 280px;
                            display: flex;
                            align-items: center;
                            .chatIcon_list_item_footer_left_item {
                                flex: 1;
                                display: flex;
                                align-items: center;
                                vertical-align: middle;
                                cursor: pointer;
                                img {
                                    width: 15px;
                                    height: 14px;
                                    margin-right: 4px;
                                }
                                span {
                                    font-size: 13px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                }
                            }
                        }
                        .chatIcon_list_item_footer_right {
                            width: 350px;
                            height: 30px;
                            padding: 0 13px 0 13px;
                            box-sizing: border-box;
                            background: #EAF5FF;
                            border-radius: 11px;
                            margin-left: 21px;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            .inputText {
                                flex: 1;
                                height: 100%;
                                input::-webkit-input-placeholder {
                                    font-size: 14px;
                                    font-family: Source Han Sans CN;
                                    font-weight: bold;
                                    color: #0361BA;
                                }
                                input {
                                    font-size: 14px;
                                    color: #222222;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(0,0,0,0);
                                    border: none;
                                    outline: none;
                                }
                            }
                            .caozuo {
                                flex-shrink: 0;
                                display: flex;
                                align-items: center;
                                img {
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 8px;
                                    cursor: pointer;
                                }
                                .caozuo_btn {
                                    width: 45px;
                                    height: 22px;
                                    line-height: 20px;
                                    text-align: center;
                                    background: linear-gradient(0deg, #085EAF, #2791F4);
                                    border-radius: 10px;
                                    font-size: 9px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
