<template>
  <router-view></router-view>
</template>

<script setup>
import { provide } from 'vue';
import * as echarts from 'echarts';
import 'xgplayer/dist/index.min.css';
// // // 引入配置组件
// import { ElConfigProvider } from 'element-plus'
// // 引入中文包
// import zhCn from 'element-plus/lib/locale/lang/zh-cn';

// const { locale } = reactive({
//   locale: zhCn,
// });

provide('echarts', echarts)
</script>

<style lang="scss">
@font-face {
	font-family: "汉仪菱心体简";
	src: local("@/assets/font/xiaoweilogo.ttf"), url("@/assets/font/xiaoweilogo.ttf");
}
.no-scroll {
  overflow: hidden;
}
html, body {
  margin: 0;
  padding: 0;
}
html::-webkit-scrollbar {
  width: 10px;
}
html::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  //background: #081d40;
  background: #28bed7;
}
html::-webkit-scrollbar-thumb:hover {
  background: #041024;
}
html::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #0d2e64;
}
html::-webkit-scrollbar-button {
  background-color: #0d2e64;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
/* 隐藏滚动条但保持滚动功能 */
/* WebKit 浏览器（Chrome、Safari）*/
/* 隐藏滚动条 */
::-webkit-scrollbar {
    display: none;
}

/* 创建一个虚拟的滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: transparent;
}

/* 创建一个虚拟的滚动条滑块 */
::-webkit-scrollbar-thumb {
    background-color: transparent;
}
</style>
