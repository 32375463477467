<template>
     <div class="layout">
         <div class="layout_content">
             <div class="chatIcon_list">
                 <div class="chatIcon_list_item">
                     <div style="width: 100%;">
                         <div class="gundong" style="width: 100%;background: rgb(13, 46, 100);margin-top: 10px;">
                             <div style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;">{{ postLists.createtime }}</div>
                             <div style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;" v-html="postLists.content"></div>

                             <div v-if="postLists.file_img_count > 0" style="height: 150px;padding: 10px;">
                                 <div v-for="(img, i) in postLists.file_img" :key="i" style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;">
                                     <img :src="img" :style="{ width: '100%', height: '150px' }" alt="" @click="openPreview1(img)">
                                 </div>
                                 <div v-if="showPreview1" class="modal" @click="closePreview1">
                                     <div class="modal-content">
                                         <span class="close" @click="closePreview1">&times;</span>
                                         <img :src="images11" alt="Preview" style="object-fit: contain;"/>
                                     </div>
                                 </div>
                             </div>

                             <div v-if="postLists.file_video_count > 0">
                                 <div v-for="(img, i) in postLists.file_video" :key="i">
                                     <video :src="img" controls style="width: 100%;height: 300px;"></video>
                                 </div>
                             </div>

                             <div v-if="postLists.file_data_count > 0" style="padding: 10px;color: #26c3dd;">
                                 <div v-for="(img, i) in postLists.file_data" :key="i">
                                     <a class="file_style" style="cursor: pointer" @click="download(img)">{{ img.filename }}</a>
                                 </div>
                             </div>

                             <div v-if="postLists.file_audio_count > 0" style="margin: 10px;">
                                 <div v-for="(img, i) in postLists.file_audio" :key="i">
                                     <audio controls style="width: 100%;">
                                         <source :src="img" type="audio/mp3">
                                     </audio>
                                 </div>
                             </div>

                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router';
import {ref, reactive, onMounted, defineProps} from 'vue';
import {
    sendComment,
    show,
    addLive,
    delLive,
    addFavorite,
    delFavorite,
    addComment,
    getComment,
    delComment,
    updateComment,
    grapevine,
    grapeshow
} from '@/api'
import {downloadobj} from "@/utils/utils";
let postLists = ref([])

let images11 = ref([])
let showPreview1 = ref(false)
const router = useRouter()

//文件下载
const download = (e) => {
    downloadobj(e)
}
const openPreview1 = (ima) => {

    console.log(ima)

    images11.value = ima
    showPreview1.value = true;
}
const closePreview1 = () => {
    showPreview1.value = false;
}

onMounted(() => {
    postLists.value = JSON.parse(localStorage.getItem('txt_item'));
    console.log(postLists.value);
})

</script>
<style>
.comment-box img {
    width: 100px;
}
</style>
<style lang="scss" scoped>
.layout {
  width: 100%;
  min-width: 975px;
  min-height: 100vh;
  background-color: #0d2e64;
  display: flex;
  justify-content: center;
  position: relative;

  .layout_content {
    width: 975px;
    padding: 20px 0;
    box-sizing: border-box;
    height: auto;
    color: azure;
  }
}
.chatIcon_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: rgb(11 37 79);
    border-radius: 6px;
}

.chatIcon_list_item {
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100vh;
}
</style>
