<template>
    <div class="login">
        <div class="login_bos_title">
            会员权益
        </div>
        <div style="width: 40%;height: 500px;margin: auto;font-size: 20px">
            <span v-html="info"></span>
        </div>

        <div style="width: 100%;text-align: center">
            <el-button style="width: 20%;" type="primary" @click="submitForm()">
                开通
            </el-button>
            <el-button style="width: 20%;" type="primary" @click="gohome">
                取消
            </el-button>
        </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="开通会员">
        <div style="height: 200px;display: flex" v-if="paytype==1">
            <div style="width: 20%;height: 200px;border: 2px #0B3D6C solid;background-color: #d3dce6;margin-left: 10px;" v-for="(item, index) in vipinfo" :key="index">
                <div style="font-size: 25px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">{{ item.name }}</div>
                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;"><text style="color: red;font-size: 22px">{{ item.membership_price }}</text>￥</div>
                <div style="text-align: center;padding-top: 15px">
                    <el-button type="primary" @click="dredge(item.id)">开通</el-button>
                </div>
            </div>
        </div>
        <div style="height: 200px;text-align: center;display: flex" v-else>
            <img :src="payimg" style="width: 200px;height: 200px" alt="" />
        </div>

        <template #footer>
          <span class="dialog-footer" style="margin-right: 10px;">
            <el-button @click="cancellation()">取消</el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script setup>
import {onMounted, ref } from 'vue'
import {useStore} from 'vuex'
import request from '@/api/request'
import {createVip, getVipList, payVip, systemConfig, Vipquery} from "@/api";
import {useRouter,useRoute} from "vue-router";
let info = ref('')
let vipinfo = ref([])
let paytype = ref(1)
let payimg = ref('')
const dialogFormVisible = ref(false)
const router = useRouter()
const store = useStore()

const gohome = () => {
    router.push({path: '/index'})
}

const submitForm = () => {
    dialogFormVisible.value = true
    getVipList().then(res => {
        if (res.code === 1) {
            vipinfo.value = res.data
        }
    })
}

const cancellation = () => {
    dialogFormVisible.value = false
    paytype.value = 1
}

const dredge = (id) => {
    createVip({
        goods_type:'user_group',
        table_name:'user_group',
        goods_id:id,
    }).then(res => {
        if (res.code === 1){
            payVip({order_id:res.data.order_id}).then(res1=>{
                if (res1.code === 1){
                    payimg.value = res1.data.code_url
                    paytype.value = 2
                    const intervalId = setInterval(() => {
                        Vipquery({ order_id: res.data.order_id }).then(res2 => {
                            if (res2.code === 1) {
                                if (res2.data.pay_status === 1) {
                                    clearInterval(intervalId);
                                    router.push({path: '/index'})
                                }
                            }
                        });
                    }, 2000);
                }
            })
        }

    })
}


const getconfig = () => {
    systemConfig().then(res => {
        if (res.code === 1){
            info.value = res.data.web_service_agreement_text
        }
    })
}

onMounted(() => {
    getconfig()
})
</script>

<style lang="scss">
.el-form-item__label {
  color: #FFFFFF !important;
  /* 其他样式属性... */
}
.login {
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;

  .login_bos_title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 40px;
  }
}
</style>
