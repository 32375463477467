<template>
    <div class="image-content" v-loading="loading1" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="chatIcons">
            <div v-if="searchtype === 2">
                <div style="color:#fff;font-size:22px;text-align: center;padding:5px;">搜索结果</div>

                <div v-if="albumtype === 1" class="aaa"
                     style="cursor: pointer;width: 95%;height: 260px;background-color: #023C67;display: inline-block;margin: 5px 2%;border-radius: 10px;"
                     v-for="(item, index) in albumList" :key="index" @click="albumvideo(item)">
                    <img class="image" style="width: 95%;height: 210px;margin-left: 2.5%;margin-top: 5px;border-radius: 10px;"
                         :src="item.cover_image" alt=""/>
                    <div style="display: flex;align-items: center;padding-left: 15px;padding-top: 10px;">
                        <img class="play" style="width: 20px;height: 20px;" src="@/assets/sharpicons_present.png" alt=""/>
                        <span style="padding-left: 10px;">{{ item.name }}</span>
                    </div>
                </div>
                <div id="bbb" v-else>
                    <div style="width: 98%;height: 320px;background-color: #023C67;display: inline-block;margin: 5px 2%;border-radius: 10px;">
                        <img id="image_mse" class="image"
                             style="width: 95%;height: 253px;margin-left: 2.5%;margin-top: 10px;border-radius: 10px;"
                             :src="introducedata.cover_image" alt=""/>
                        <div id="mse_video"></div>
                        <div style="display: flex;align-items: center;justify-content: space-between;padding-left: 15px;padding-top: 10px;">
                            <div style="display: flex;align-items: center;">
                                <div style="display: flex;align-items: center;">
                                  <img class="play" style="width: 20px;height: 20px;" src="@/assets/sharpicons_present.png" alt=""/>
                                  <text style="padding-left: 10px;width: 150px;">{{ introducedata.name }}</text>
                                </div>
                            </div>
                            <div style="display: flex;justify-content: flex-end;align-items: center;padding-right: 30px;">
                                <div style="width: 65px;height: 30px;border-radius: 5px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-left: 18%;"
                                        @click="del_collection(introducedata.id,1,introducedata,'album')" v-if="is_collection===1">
                                    <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/dianz.png" alt=""/>
                                    <div style="padding-left: 5px;width: 30px;"> 点赞</div>
                                </div>
                                <div style="width: 65px;height: 30px;border-radius: 5px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-left: 18%;"
                                        @click="album_collection(introducedata.id,1,introducedata,'album')" v-else>
                                    <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/zz.png" alt=""/>
                                    <div style="padding-left: 5px;width: 30px;"> 点赞</div>
                                </div>
<!--                                <div style="width: 2px;height: 20px;background-color: #628FB1;color:#023C67;margin-left: 15px;">1 </div>-->
<!--                                <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-left: 7px;">-->
<!--                                    <el-popover-->
<!--                                            :width="150"-->
<!--                                            trigger="hover">-->
<!--                                        <template #reference>-->
<!--                                            <div class="chatIcon_list_item_footer_left_item" style="display: flex;align-items: center;">-->
<!--                                                <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/share.png"-->
<!--                                                     alt=""/>-->
<!--                                                <div style="padding-left: 5px;width: 30px;">分享</div>-->
<!--                                            </div>-->
<!--                                        </template>-->
<!--                                        <div @click="shareWeChat" style="width: 100%; display: flex; align-items: center;">-->
<!--                                            <div-->
<!--                                                    style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                                <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt=""/>-->
<!--                                                <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>-->
<!--                                            </div>-->
<!--                                            <div @click="shareMicroBlog"-->
<!--                                                 style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                                <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt=""/>-->
<!--                                                <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>-->
<!--                                            </div>-->
<!--                                            <div @click="shareQQ"-->
<!--                                                 style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                                <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt=""/>-->
<!--                                                <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </el-popover>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                    <div style="width: 80%;height: 50px;margin: 5px 2%;display: flex;justify-content:space-between;">
                        <div id="jieshao"
                             style="width: 80px;height: 30px;background-color: #0D67BB;border-radius: 5px;text-align: center;line-height:30px;cursor: pointer"
                             @click="introduce">
                            <text> 课程介绍</text>
                        </div>
                        <div id="mulu"
                             style="width: 80px;height: 30px;background-color: #0B3D6C;border-radius: 5px;text-align: center;line-height:30px;cursor: pointer"
                             @click="directory">
                            <text> 课程目录</text>
                        </div>
                        <div id="pinglun"
                             style="width: 80px;height: 30px;background-color: #0B3D6C;border-radius: 5px;text-align: center;line-height:30px;cursor: pointer"
                             @click="comment1">
                            <text> 用户评论({{ commentnum }})</text>
                        </div>
                    </div>
                    <div id="introduce" style="width: 98%;margin: 5px 2%;background-color: #0D2E64;border-radius: 10px;">
                        <div
                                style="width: 90%;height: 50px;margin: auto;text-align: center;line-height:50px;display: flex;justify-content: space-between;border-bottom: 2px #1E4173 solid">
                            <text style="padding: 5px 0 15px;color:#00D5D8;font-weight: bold;font-size: 18px;"> 课程平均评分</text>
                            <div>
                                <el-rate disabled v-model="introducedata.average_score" size="large"></el-rate>
                                <text style="padding: 5px 0 15px;color:#FFD100;font-weight: bold;font-size: 21px;">
                                    {{ introducedata.average_score }} 分
                                </text>
                            </div>
                        </div>
                        <div style="width: 90%;margin: auto;border-bottom: 2px #1E4173 solid;margin-top: 15px;">
                            <text style="padding: 5px 0 15px;color:#00D5D8;font-weight: bold;"> 课程介绍</text>
                            <div style="padding: 5px 0 15px;font-weight: bold;">
                                <div class="html-box" v-html="introducedata.introduction"></div>
                            </div>
                        </div>
                        <div style="width: 90%;margin: auto;margin-top: 15px;">
                            <text style="padding: 5px 0 15px;color:#00D5D8;font-weight: bold;"> 讲师介绍</text>
                            <div style="padding: 5px 0 15px;font-weight: bold;">
                                <div class="html-box" v-html="introducedata.lecturer_introduction"></div>
                            </div>
                        </div>
                    </div>
                    <div id="directory" style="width: 98%;display: none;">
                        <div style="width: 100%;height: 40px;background-color: #082352;border-radius: 8px;display: flex;padding: 5px;margin-top: 5px;justify-content: space-between;cursor: pointer;"
                                v-for="(item, index) in directorydata" :key="index">
                            <div style="display: flex;justify-content:space-between;align-items: center;"
                                 @click="video_info(item.video_path_obs)">
                                <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/play2.png" alt=""/>
                                <text style="padding-left: 5px;"> {{ item.title }}</text>
                            </div>
                            <div style="display: flex;align-items: center;justify-content: center">
                                <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;" @click="handleMouseEnter_video(item.id)" v-if="item.rating === 0">
                                    <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/evaluate.png" alt=""/>
                                    <text style="padding-left: 5px;"> 评价</text>
                                </div>
                                <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;" v-else>
                                    <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/evaluate1.png" alt=""/>
                                    <text style="padding-left: 5px;color:#5e5b5b"> 评价</text>
                                </div>
                                <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;"
                                     @click="del_collection(item.id,2,item,'video')" v-if="item.count_like===1">
                                    <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/dianz.png" alt=""/>
                                    <div style="padding-left: 5px;width: 30px;"> 点赞</div>
                                </div>
                                <div style="width: 65px;height: 30px;display: flex;align-items: center;line-height: 30px;cursor: pointer;margin-top: 8px;"
                                     @click="album_collection(item.id,2,item,'video')" v-else>
                                    <img class="play" style="width: 15px;height: 15px;margin-left: 8px;" src="@/assets/zz.png" alt=""/>
                                    <div style="padding-left: 5px;width: 30px;"> 点赞</div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 60px;margin-bottom: 30px;display: flex;justify-content: center; align-items: center;">
                            <el-pagination
                                    small
                                    background
                                    layout="prev, pager, next"
                                    :total="videowhichpage"
                                    class="mt-4"
                                    @currentChange="testfun1"
                            />
                        </div>
                    </div>
                    <div id="comment" style="width: 98%;margin: 5px 2%;border-radius: 10px;display: none;">
                        <div style="margin-top: 5px;background-color: #0D2E64;border-radius: 5px;"
                             v-for="(item, index) in commentdata" :key="index">
                            <div
                                    style="width: 90%;height: 20px;padding: 5px;margin-top: 5px;display: flex;align-items: center;justify-content: space-between;">
                                <div style="line-height: 20px;display: flex;align-items: center;">
                                    <img class="play" style="width: 20px;height: 20px;border-radius: 100px;margin-left: 8px;"
                                         :src="item.user.avatar" alt=""/>
                                    <text style="padding-left: 5px;"> {{ item.user.username }}</text>
                                    <text style="padding-left: 5px;"> {{ item.createtime }}</text>
                                </div>
                            </div>
                            <div style="padding-left: 15px;margin-top: 5px;">
                                <div v-html="handleMktoHtml(item.content)" style="font-size: 14px" class="comment-box"></div>
                            </div>
                        </div>
                        <div class="chatIcon_list_item_pj_item_pl" @click.stop>
                            <div class="item_pl_content">
            <textarea class="item_pl_content_textarea pltextarea"
                      v-model="comment"
                      rows="1" placeholder="请输入内容" @input="adjustTextareaHeight1()"
                      id="aaaaaa"></textarea>
                            </div>
                            <div class="item_pl_caozuo" style="cursor: pointer">
                                <el-popover
                                        placement="bottom"
                                        title="请选择表情"
                                        :width="300"
                                        @before-enter="beforeIconChild(index, i)"
                                        trigger="click">
                                    <template #reference>
                                        <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                                    </template>
                                    <chatIcon @changeText="getIcon1"/>
                                </el-popover>
                                <imageContent :content="comment" @result="imageContentResult" @change="handleImageSelected"></imageContent>
                                <div class="item_pl_caozuo_btn" @click="sendCommentVal1(introducedata.id,comment)">发送</div>
                            </div>
                        </div>
                        <div v-if="comment_image && comment_image.length>0" class="comment-image-box">
                            <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in comment_image">
                                <img style="width: 80px;height: 80px;" :src="imgitem">
                                <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg(comment_image,index)">
                            </div>
                        </div>
                        <div style="margin-top: 60px;margin-bottom: 30px;display: flex;justify-content: center; align-items: center;">
                            <el-pagination
                                    small
                                    background
                                    layout="prev, pager, next"
                                    :total="commentnum"
                                    class="mt-4"
                                    @currentChange="testfun"
                            />
                        </div>
                    </div>


                </div>

                <div class="aaa" style="width: 98%;height: 260px;cursor: pointer;background-color: #023C67;display: inline-block;margin: 5px 2%;border-radius: 10px;"
                     v-for="(item, index) in liveList" :key="index" @click="zhibo(item.live_url,item.is_member_expire,item)"
                     v-if="zhibotype === 1">
                    <img class="image" style="width: 95%;height: 210px;margin-left: 2.5%;margin-top: 5px;border-radius: 10px;"
                         :src="item.image" alt=""/>
                    <div style="display: flex;align-items: center;padding-left: 15px;padding-top: 10px;">
                        <img class="play" style="width: 20px;height: 20px;" src="@/assets/sharpicons_present.png" alt=""/>
                        <span style="padding-left: 10px;">{{ item.title }}</span>
                    </div>
                </div>
                <div class="aaa"
                     style="cursor: pointer;width: 95%;height: 260px;background-color: #023C67;display: inline-block;margin: 5px 2%;border-radius: 10px;text-align: center;"
                     v-else>
                    <div id="mse"></div>
                    <text @click="playlive" v-if="zhibotype === 2" style="line-height: 260px;">点击播放</text>
                </div>


                <div class="gundong" style="width: 100%;background: rgb(13, 46, 100);margin-top: 10px;" v-for="(item,index) in introduceList" :key="index">
                    <div style="padding:8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;">{{ item.createtime }}</div>

                    <div id="rich_text" :ref="el => setPostContentRef_one(el, index)" :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }" style="padding:0 8px;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;" v-html="item.content"></div>
                    <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
                        <text v-if="expandStates2[index]" style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;"
                              @click="expand_one(index, $event.target)">展开
                        </text>
                    </div>
                    <div v-if="item.file_img.length > 0" style="height: 150px;overflow: hidden;position: relative;padding: 10px;">
                        <div v-for="(img, i) in item.file_img" :key="i" style="margin: 10px 0 10px 2px;width: 33%;height: 150px;display: inline-block;float: left;position: relative;">
                            <img :src="img" :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'contain' }" alt="" @click="openPreview1(i,item.file_img)">
                        </div>
                        <img v-if="item.file_img.length >= 3" @click="jumpDetails(item)" alt="" src="@/assets/jia.png" style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;">
                        <div v-if="showPreview1" class="modal" @click="closePreview1">
                            <div class="modal-content">
                                <span class="close" @click="closePreview1">&times;</span>
                                <img :src="images11" alt="Preview" style="object-fit: contain;"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="item.file_video.length > 0">
                        <div v-for="(img, i) in item.file_video" :key="i">
                            <video :src="img" controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 300px;"></video>
                        </div>
                    </div>

                    <div v-if="item.file_data.length > 0" style="padding: 10px;color: #26c3dd;">
                        <div v-for="(img, i) in item.file_data" :key="i">
                            <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                        </div>
                    </div>

                    <div v-if="item.file_audio.length > 0" style="margin: 10px;">
                        <div v-for="(img, i) in item.file_audio" :key="i">
                            <audio controls style="width: 100%;">
                                <source :src="img" type="audio/mp3">
                            </audio>
                        </div>
                    </div>
                </div>


                <div class="chatIcon_list_item" v-for="(item, index) in plateList" :key="index" style="position: relative">
                    <div class="chatIcon_list_item_head">
                        <img :src="item.avatar" alt=""/>
                        <span class="head_name">{{ item.username }}</span>
                        <span class="head_time">{{ item.create_time.replace(/:\d{2}$/, '') }}</span>
                    </div>
                    <div class="video-right-option">
                        <div v-if="item.is_reprint==1">
<!--                            <div class="zhuanicon">转</div>-->
                            <img src="@/assets/zhuan1.png" alt="" style="width: 28px;height: 28px">
                        </div>
                        <!--          <div class="updatePost" v-if="item.is_author === 1" @click="updatePost(item)">修改</div>-->
                        <!--          <div class="deletePost" v-if="item.is_author === 1" @click="deletePost(item)">删除</div>-->
                        <el-popover :width="150" trigger="click">
                            <template #reference>
                                <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer">
                                    <img style="width: 25px;" src="@/assets/dian.png" alt="" v-if="item.is_author === 1"/>
                                </div>
                            </template>
                            <div style="width: 100%; display: flex; align-items: center;justify-content: space-between">
                                <div class="updatePost" style="color:#fff;z-index: 999;" v-if="item.is_author === 1" @click="updatePost(item)">修改</div>
                                <div class="deletePost" style="color:#fff;z-index: 999;" v-if="item.is_author === 1" @click="deletePost(item)">删除</div>
                            </div>
                        </el-popover>
                        <div v-if="item.recommended==1"><img src="@/assets/refined1.png" alt="" style="width: 28px;height: 28px"></div>
                        <div v-if="item.is_top==1"><img src="@/assets/topping1.png" alt="" style="width: 28px;height: 28px"></div>
                    </div>
                    <div>
                        <div :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }" style="word-wrap: break-word;" v-html="item.post_content"
                             :ref="el => setPostContentRef(el, index)" id="rich_text"></div>
                        <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
                            <text style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;" @click="expand(index, $event.target)" v-if="expandStates[index]">展开</text>
                        </div>
                    </div>

                    <div style="height: 150px;overflow: hidden;position: relative;" v-if="item.file_img_num > 0">
                        <div style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;" v-for="(img, i) in item.file_img" :key="i">
                            <img :src="img" alt="" :style="{ width: '100%', height: '150px', opacity: getOpacity(i) }"
                                 @click="openPreview1(i,item.file_img)">
                        </div>
                        <img src="@/assets/jia.png" alt="" style="width: 50px;height: 52px;position: absolute;top: 42%;left: 80%;"
                             v-if="item.file_img_num >= 3" @click="jumpDetails(item)">

                        <div v-if="showPreview1" class="modal" @click="closePreview1">
                            <div class="modal-content">
                                <span class="close" @click="closePreview">&times;</span>
                                <img :src="images11" alt="Preview" style="max-width: 100%; max-height: 100vh;"/>
                            </div>
                        </div>
                    </div>
                    <div style="height: 300px;" v-if="item.file_video.length > 0">
                        <div v-for="(img, i) in item.file_video" :key="i">
                            <video :src="img" style="width: 100%;height: 300px;" controls></video>
                        </div>
                    </div>

                    <div v-if="item.file_data.length > 0">
                        <div v-for="(img, i) in item.file_data" :key="i">
<!--                            <a class="file_style" :href="img.url" :download="img.filename">{{ img.filename }}</a>-->
                          <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                        </div>
                    </div>

                    <div v-if="item.file_audio.length > 0">
                        <div v-for="(img, i) in item.file_audio" :key="i">
                            <audio controls style="width: 100%;">
                                <source :src="img" type="audio/mp3">
                            </audio>
                        </div>
                    </div>


                    <div style="background:rgb(13, 46, 120);padding: 5px;margin-top: 10px;" v-if="item.reprint_id != null">
                        <div :ref="el => setPostContentRef(el, index)" :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }" style="word-wrap: break-word;" v-html="item.reprint_data.post_content" id="rich_text"></div>
                        <div v-if="item.reprint_data.file_img_num > 0" style="height: 150px;overflow: hidden;position: relative">
                            <div v-for="(img, i) in item.reprint_data.file_img"
                                 :key="i" style="margin-top: 10px;width: 33%;height: 150px;margin-left: 2px;display: inline-block;float: left;position: relative;">
                                <img :src="img" :style="{ width: '100%', height: '150px', opacity: getOpacity(i),objectFit: 'cover' }"
                                     alt=""
                                     @click="openPreview1(i,item.reprint_data.file_img)">
                            </div>
                            <img v-if="item.reprint_data.file_img_num >= 3" alt="" src="@/assets/jia.png"
                                 style="width: 50px;height: 50px;position: absolute;top: 40%;left: 80%;" @click="jumpDetails(item.reprint_data)">
                            <div v-if="showPreview1" class="modal" @click="closePreview1">
                                <div class="modal-content">
                                    <span class="close" @click="closePreview">&times;</span>
                                    <img :src="images11" alt="Preview" style="max-width: 100%; max-height: 100vh;"/>
                                </div>
                            </div>
                        </div>

                        <div v-if="item.reprint_data.file_video != []">
                            <div v-for="(img, i) in item.reprint_data.file_video" :key="i">
                                <video :src="img" controls style="width: 100%;height: 300px;"></video>
                            </div>
                        </div>

                        <div v-if="item.reprint_data.file_data != []">
                            <div v-for="(img, i) in item.reprint_data.file_data" :key="i">
                                <!--                <a class="file_style" :href="img.url" :download="img.filename" @click="downloadobj">{{ img.filename }}</a>-->
                                <a class="file_style" @click="download(img)">{{ img.filename }}</a>
                            </div>
                        </div>

                        <div v-if="item.reprint_data.file_audio != []" style="margin: 10px;">
                            <div v-for="(img, i) in item.reprint_data.file_audio" :key="i">
                                <audio controls style="width: 100%;">
                                    <source :src="img" type="audio/mp3">
                                </audio>
                            </div>
                        </div>
                    </div>


                    <div class="chatIcon_list_item_pj" @click.stop>
                    </div>
                    <div class="chatIcon_list_item_footer" @click.stop>
                        <div class="chatIcon_list_item_footer_left">
<!--                            <div class="chatIcon_list_item_footer_left_item" @click.stop="transport(item)" style="cursor: pointer">-->
<!--                                <img src="@/assets/zhuanzai.png" alt=""/>-->
<!--                                <span>转载</span>-->
<!--                            </div>-->
                            <div style="position: fixed;top: 40%;width: 630px;left: 50%;transform: translateX(-50%);background-color: #fff;padding: 10px;z-index: 9999" @click.stop v-if="item.transports">
                                <div class="item_pl_user" style="display: flex;justify-content: space-between;color: #000;">
                                    <span> 转载内容 </span>
                                    <span style="cursor: pointer" @click.stop="closetransport(item)">X</span>
                                </div>
                                <textareaComponent :typeView="channels_id" :channel="item.channel_id" :inputtype="inputtype"
                                                   :fuid="item.id" @result="sendMessageResult"/>
                                <!--                  <div class="chatIcon_list_item_info" v-html="item.post_content"></div>-->
                                <textarea class="item_pl_content_textarea" cols="30" rows="10" :value="item.post_content_text"
                                          id="myTextarea" @input="handleTextareaInput"></textarea>

                            </div>

<!--                            <div class="chatIcon_list_item_footer_left_item" @click.stop="collect(item.id, item.is_myfavorite, item)" style="cursor: pointer">-->
<!--                                <img :src="item.is_myfavorite == 1 ? require('@/assets/shouc.png') : require('@/assets/sc.png')" alt=""/>-->
<!--                                <span>收藏{{ item.post_favorites }}</span>-->
<!--                            </div>-->

<!--                            <el-popover-->
<!--                                    :width="150"-->
<!--                                    trigger="hover">-->
<!--                                <template #reference>-->
<!--                                    <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer">-->
<!--                                        <img src="@/assets/zf.png" alt=""/>-->
<!--                                        <span>转发{{ item.post_forward }}</span>-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                                <div style="width: 100%; display: flex; align-items: center;">-->
<!--                                    <div @click="shareWeChat"-->
<!--                                         style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                        <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt=""/>-->
<!--                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>-->
<!--                                    </div>-->
<!--                                    <div @click="shareQQ"-->
<!--                                         style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                        <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt=""/>-->
<!--                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>-->
<!--                                    </div>-->
<!--                                    <div @click="shareMicroBlog"-->
<!--                                         style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                        <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt=""/>-->
<!--                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>-->
<!--                                    </div>-->
<!--                                    <div @click="transport(item)"-->
<!--                                         style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                        <img style="width: 30px; height: 30px;" src="@/assets/zhuanzai1.png" alt=""/>-->
<!--                                        <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">转载</span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </el-popover>-->
                            <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer" @click="transport(item)">
                                <img alt="" src="@/assets/zf.png"/>
                                <span>转载</span>
                            </div>
                            <div class="chatIcon_list_item_footer_left_item" @click="jumpDetails(item)" style="cursor: pointer">
                                <img src="@/assets/pl.png" alt=""/>
                                <span>评论{{ item.comment_count }}</span>
                            </div>
                            <div class="chatIcon_list_item_footer_left_item" @click.stop="collect(item.id, item.is_myfavorite, item)" style="cursor: pointer">
                                <img :src="item?.is_myfavorite == 1 ? require('@/assets/dianz.png') : require('@/assets/zz.png')" alt="" />
                                <span>点赞{{ item.post_favorites }}</span>
                            </div>
                        </div>

                    </div>
                    <div class="chatIcon_list_item_pj_item_pl" @click.stop>
                        <div class="item_pl_content">
            <textarea class="item_pl_content_textarea pltextarea"
                      v-model="item.text"
                      rows="1" placeholder="请输入内容" @input="adjustTextareaHeight(item.id)"
                      :id="'aaaaaa'+item.id"></textarea>
                        </div>
                        <div class="item_pl_caozuo" style="cursor: pointer">
                            <el-popover
                                    placement="bottom"
                                    title="请选择表情"
                                    :width="300"
                                    @before-enter="beforeIconChild(index, i)"
                                    trigger="click">
                                <template #reference>
                                    <img class="item_pl_caozuo_img" src="@/assets/icon.png" alt=""/>
                                </template>
                                <chatIcon @changeText="getIcon2"/>
                            </el-popover>
                            <imageContent :content="item.text" :obj="item" :type="index"
                                          @result="imageContentResult2" @change="handleImageSelected"></imageContent>
                            <div class="item_pl_caozuo_btn" @click="sendCommentVal(item.id,item.text,item)">发送</div>
                        </div>
                    </div>
                    <div v-if="item.comment_image && item.comment_image.length>0" class="comment-image-box">
                        <div style="width: 80px;height: 80px;display:inline-block;margin: 5px;position: relative;" v-for="(imgitem, index) in item.comment_image">
                            <img style="width: 80px;height: 80px;" :src="imgitem">
                            <img style="width: 20px;height: 20px;position: absolute;top: -10px;right: -10px;" src="@/assets/X.png" @click="delimg(item.comment_image,index)">
                        </div>
                    </div>
                </div>


                <div style="width: 96%;background-color: #0D2E64;border-radius: 10px;margin-bottom: 10px;padding: 10px;" v-for="(item, index) in investmentList" :key="index">
                    <div v-if="item.is_show_file == 0 || item.is_show_file === null" :ref="el => setPostContentRef1(el, index)" :class="{ 'chatIcon_list_item_info': true, 'textinfo': true }">
                        <div style="padding-bottom: 10px;">发布时间:{{ item.content_time }}</div>
                        政策面:<span v-html="item.policy_content"></span>
                        市场新闻:<span v-html="item.news_content"></span>
                        宏观面:<span v-html="item.macro_content"></span>
                        资金面及预期:<span v-html="item.fund_content"></span>
                        市场预期/关注:<span v-html="item.expect_content"></span>
                        情绪面:<span v-html="item.emotion_content"></span>
                    </div>
                    <div style="width: 100%;text-align: center;margin: 5px;color: #e7d119;font-size: 10px;word-wrap: break-word;">
                        <text v-if="expandStates1[index]" style="cursor: pointer;line-height: 1.6;font-size: 14px;letter-spacing: 0.1em;font-weight: bold;"
                              @click="expand1(index, $event.target)">展开
                        </text>
                    </div>
                    <div v-if="item.is_show_file == 1">
                        <div v-for="(item1, index1) in item.file_list" :key="index1">
                            <div v-if="filetype(item1.url) == 1" style="margin-top: 10px;">
                                <audio controls style="width: 100%;">
                                    <source :src="item1.url" type="audio/mp3">
                                </audio>
                            </div>
                            <div v-if="filetype(item1.url) == 2" style="margin-top: 10px;">
                                <video controls style="width: 100%;height: 400px;">
                                    <source :src="item1.url" type="video/mp4">
                                </video>
                            </div>
                            <div v-if="filetype(item1.url) == 3" style="margin-top: 10px;">
                                <div style="height: 300px;">
                                    <img :src="item1.url" :style="{ width: '100%', height: '300px', opacity: getOpacity(i),objectFit: 'cover' }" alt="" @click="openPreview1(0,item1.url)">
                                </div>
                                <div v-if="showPreview1" class="modal" @click="closePreview1">
                                    <div class="modal-content">
                                        <span class="close" @click="closePreview1">&times;</span>
                                        <img :src="images11" alt="Preview" style="max-width: 100%; max-height: 100vh;"/>
                                    </div>
                                </div>
                            </div>
                            <div v-if="filetype(item1.url) == 4" style="margin-top: 10px;">
                                <a class="file_style" @click="download(item1.url)">{{ item1.name }}</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Loading v-if="loading"/>
            <!--    <notFind v-if="!loading && from.list.length === 0"/>-->
            <div class="info" v-if="!loading && from.list.length > 0 && noMore">
                <el-text class="mx-1" type="info">没有更多了~</el-text>
            </div>
        </div>

        <el-dialog v-model="dialogFormVisible3" title="开通会员" width="650">
            <div style="height: 200px;display: flex;z-index: 10;margin-top: 15px;margin-right: 20px;" v-if="paytype==1">
                <div style="width: 20%;height: 200px;border: 2px #0B3D6C solid;background-color: #d3dce6;margin-left: 10px;"
                     v-for="(item, index) in vipinfo" :key="index">
                    <div style="font-size: 22px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">{{
                        item.name
                        }}
                    </div>
                    <!--        <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">-->
                    <!--          <text style="color: red;font-size: 22px">{{ item.membership_price }}</text>￥-->
                    <!--        </div>-->
                    <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;"
                         v-if="pay_status === 1">
                        <text style="color: red;font-size: 22px">{{ item.membership_price }}</text>
                        ￥
                    </div>
                    <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-else>
                        <text style="color: red;font-size: 22px">{{ item.membership_score }}</text>
                    </div>
                    <div style="text-align: center;padding-top: 15px">
                        <!--          <el-button type="primary" @click="dredge(item.id)">开通</el-button>-->
                        <el-button type="primary" v-if="pay_status === 1" @click="dredge(item.id)">开通</el-button>
                        <el-button type="primary" v-else @click="dredge1(item.id)">积分开通</el-button>
                    </div>
                </div>
            </div>
            <div style="height: 200px;text-align: center;display: flex" v-else>
                <img :src="payimg" style="width: 200px;height: 200px" alt=""/>
            </div>

            <template #footer>
      <span class="dialog-footer" style="margin-right: 10px;">
          <text style="color: #000000;padding-right: 20px;" v-if="pay_status !== 1">现有积分:{{ userinfo.score }}</text>
        <el-button @click="cancellation()">取消</el-button>
      </span>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible4" title="开通会员" width="500">
            <div style="margin-top: 15px;margin-left: 20px;">
              <el-button @click="payment">付费</el-button>
              <el-button @click="exchange">积分兑换</el-button>
              <el-button @click="apply_for">审核申请</el-button>
            </div>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false">取消</el-button>
      </span>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible1" title="开通会员" width="500">
            <div style="margin-top: 15px;margin-left: 20px;">暂未开通会员,开通会员观看!</div>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取消</el-button>
        <el-button type="primary" @click="confirm">
          确认
        </el-button>
      </span>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible2" title="评价" width="300">
            <div style="display: flex;justify-content: center; align-items: center;">
                <el-rate size="large" @change="comment_star"/>
            </div>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible" title="">
            <textareaComponent :typeView="channels_id" :channel="channels_id" :inputtype="3" @result="getNewList3('rem')"/>
        </el-dialog>

<!--        <vue-easy-lightbox-->
<!--                :visible="visible"-->
<!--                :imgs="images"-->
<!--                :index="0"-->
<!--                @hide="handleHide"-->
<!--        ></vue-easy-lightbox>-->
    </div>
</template>

<script setup>
import chatIcon from '@/components/chatIcon.vue'
import imageContent from '@/components/imageContent.vue'
import {defineEmits, defineExpose, defineProps, onMounted, onUnmounted, reactive, ref, watchEffect, nextTick} from 'vue'
import {
    addComment,
    addFavorite,
    delFavorite,
    getComment,
    searchList,
    systemConfig,
    addRating,
    getVipList,
    getAlbumVideoList,
    createVip,
    payVip,
    Vipquery,
    delPost,
    scorePay,
    goodsShow,
    calendarTagList,
    mark, getUserDetails
} from '@/api/index'
import Loading from '@/components/Loading.vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {timestampToTime, toBase64,downloadobj} from '@/utils/utils'
import textareaComponent from '@/components/textareaComponent.vue'
import {marked} from 'marked';
import {ElMessage} from "element-plus";
import Player from "xgplayer";
import Video from "@/components/video.vue";

let channels_id = ref('')
const store = useStore()
const router = useRouter()
const props = defineProps({
    title: String,  // 栏目标题
    channel: Number,     // 栏目id
    timeVal: Number,   // 刷新时间
    create_time: String,     // 创建时间
    typeView: String | Number,    // 1 首页广场   5 新闻资讯    3 债市论坛
    typeInput: String | Number,    // 19 问答社区
    con: String | Number,    // 搜索关键词
    datetime: String | Number,
})
const emit = defineEmits(['finish', 'openPreview', 'updateContent'])
let userinfo = ref([])
let keyword_con = ref('')
let loading = ref(true)
let visible = ref(false)
let loading1 = ref(false)
let paytype = ref(1)
let comment = ref('')
const postContents = ref([]); // 存储 DOM 引用的数组
const postContents1 = ref([]); // 存储 DOM 引用的数组
let images = ref([])
let plateListplateList = ref([]);
let investmentList = ref([])
let albumList = ref([])
let comment_image = ref([])
let liveList = ref([])
let plateList = ref([])
let introduceList = ref([])
let vipinfo = ref([])
let videoList = ref([])
let inputtype = ref(4)
let searchtype = ref(1)
let player = ref({});
let zhibotype = ref(1);
let zhibourl = ref('');
let albumtype = ref(1);
let is_collection = ref(1);
let introducedata = ref([]);
let showPreview1 = ref(false)
let images11 = ref('')
let directorydata = ref([]);
let videowhichpage = ref(1);
let videopage = ref(1);
let images1 = ref([])
let showPreview = ref(false)
let currentImageIndex = ref(0)

let from = reactive({
    page: 1,
    total: 0,
    keyword: '',
    sortId: 'desc',
    index: 0,
    indexChild: 0,
    list: []
})
let noMore = ref(false)
let dialogFormVisible = ref(false)
let dialogFormVisible1 = ref(false)
let dialogFormVisible2 = ref(false)
let video_id = ref(1)
let commentdata = ref([])
let commentnum = ref([])
let whichpage = ref([])
let dialogFormVisible3 = ref(false)
let dialogFormVisible4 = ref(false)
let pay_status = ref(0)
let pay_al_type = ref(0)
let pay_alid = ref(0)
let order_exchange = ref(0)
let goods_type = ref('')
let table_name = ref('')
let goods_id = ref('')
let tan_type = ref(1)
const postContents_one = ref([]);
const expandStates = reactive(new Array(plateList.value.length).fill(false));
const expandStates1 = reactive(new Array(investmentList.value.length).fill(false));
const expandStates2 = reactive(new Array(introduceList.value.length).fill(false));
// 倒计时结束
const finish = () => {
    getNewList3('rem')
    emit('finish')
}

const adjustTextareaHeight = (id) => {
    // 动态调整textarea高度的方法，可以根据实际需求进行调整
    // 例如，可以根据内容的行数来动态设置高度
    const textarea = document.getElementById('aaaaaa' + id)
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
}
const adjustTextareaHeight1 = (id) => {
    // 动态调整textarea高度的方法，可以根据实际需求进行调整
    // 例如，可以根据内容的行数来动态设置高度
    const textarea = document.getElementById('aaaaaa')
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
}

const dredge = (id) => {
    createVip({
        goods_type: 'user_group',
        table_name: 'user_group',
        goods_id: id,
    }).then(res => {
        if (res.code === 1) {
            payVip({order_id: res.data.order_id}).then(res1 => {
                if (res1.code === 1) {
                    payimg.value = res1.data.code_url
                    paytype.value = 2
                    const intervalId = setInterval(() => {
                        Vipquery({order_id: res.data.order_id}).then(res2 => {
                            if (res2.code === 1) {
                                if (res2.data.pay_status === 1) {
                                    clearInterval(intervalId); // 停止轮询
                                    location.reload();
                                }
                            }
                        });
                    }, 2000);
                }
            })
        }

    })
}
const payment = (val) => {
    pay_status.value = 1
    dialogFormVisible4.value = false
    dialogFormVisible1.value = true
}

const confirm = (val) => {
    dialogFormVisible3.value = true
    dialogFormVisible1.value = false
    if (pay_status.value === 1) {
        getVipList().then(res => {
            if (res.code === 1) {
                vipinfo.value = res.data
            }
        })
    }
}

const apply_for = () => {
    if (tan_type.value === 2) {
        goods_type.value = 'album';
        table_name.value = 'album';
    } else {
        goods_type.value = 'articles';
        table_name.value = 'live';
    }
    if (pay_al_type.value === 2) {
        createVip({
            goods_type: goods_type.value,
            table_name: table_name.value,
            goods_id: pay_alid.value,
            pay_type: 3,
        }).then(res => {
            if (res.code === 1) {
                ElMessage({message: '提交成功,等待审核', type: 'success'})
                dialogFormVisible4.value = false
            }
        })
    } else {
        createVip({
            goods_type: goods_type.value,
            table_name: table_name.value,
            goods_id: props.channel,
            pay_type: 3,
        }).then(res => {
            if (res.code === 1) {
                ElMessage({message: '提交成功,等待审核', type: 'success'})
                dialogFormVisible4.value = false
            }
        })
    }
}
const exchange = (val) => {
    pay_status.value = 2
    dialogFormVisible4.value = false
    dialogFormVisible1.value = true
    getUserDetails().then(res => {
        userinfo.value = res.data
    });
    if (tan_type.value === 2) {
        goods_type.value = 'album';
        table_name.value = 'album';
    } else {
        goods_type.value = 'articles';
        table_name.value = 'live';
    }
    if (pay_al_type.value === 2) {
        goodsShow({
            goods_type: goods_type.value,
            table_name: table_name.value,
            goods_id: pay_alid.value,
        }).then(res => {
            if (res.code === 1) {
                vipinfo.value = res.data
            }
        })
    } else {
        goodsShow({
            goods_type: goods_type.value,
            table_name: table_name.value,
            goods_id: props.channel,
        }).then(res => {
            if (res.code === 1) {
                vipinfo.value = res.data
            }
        })
    }
}

const dredge1 = (id) => {
    //差一个积分支付的接口
    let aaaaa = '';
    if (tan_type.value === 2) {
        goods_type.value = vipinfo.value[0].goods_type;
        table_name.value = vipinfo.value[0].table_name;
        goods_id.value = vipinfo.value[0].goods_id;
    } else {
        goods_type.value = 'articles';
        table_name.value = 'live';
        goods_id.value = pay_alid.value;
    }
    pay_status.value = 2
    if (pay_al_type.value === 2) {
        createVip({
            goods_type: goods_type.value,
            table_name: table_name.value,
            goods_id: goods_id.value,
            pay_type: 2,
        }).then(res => {
            if (res.code === 1) {
                order_exchange.value = res.data.order_id
                dialogFormVisible4.value = false
                dialogFormVisible3.value = false
                scorePay({order_id: order_exchange.value}).then(res1 => {
                    if (res1.code === 1) {
                        ElMessage({message: res1.msg, type: 'success'})
                    }
                })
            }
        })
    } else {
        createVip({
            goods_type: goods_type.value,
            table_name: table_name.value,
            goods_id: props.channel,
            pay_type: 2,
        }).then(res => {
            if (res.code === 1) {
                order_exchange.value = res.data.order_id
                dialogFormVisible4.value = false
                dialogFormVisible3.value = false
                scorePay({order_id: order_exchange.value}).then(res1 => {
                    if (res1.code === 1) {
                        ElMessage({message: res1.msg, type: 'success'})
                    }
                })
            }
        })
    }
}

const comment_star = (e) => {
    addRating({
        table_name: 'video',
        object_id: video_id.value,
        score: e
    }).then(res => {
        if (res.code === 1) {
            dialogFormVisible2.value = false
            fun_getAlbumVideoList(introducedata.value.id, 1, 10)
        }
    })
}
const fun_getAlbumVideoList = (aaa, bbb, ccc) => {
    getAlbumVideoList({
        album_id: aaa,
        page: bbb,
        limit: ccc,
    }).then(res => {
        if (res.code === 1) {
            is_collection.value = res.data.album.is_favorite
            introducedata.value = res.data.album
            directorydata.value = res.data.video_list
            videowhichpage.value = res.data.total
            videopage.value = res.data.current_page
        }
    })
    get_getComment(aaa,ccc,bbb)
}
const cancellation = () => {
    dialogFormVisible3.value = false
    paytype.value = 1
}

const openPreview = (index, ima) => {
    emit('openPreview', {index, ima})
}

const closePreview = (index) => {
    showPreview.value = false;
}

const zhibo = (url, shou, item) => {
    if (shou !== 1) {
        zhibourl.value = url
        zhibotype.value = 2
    } else {
        dialogFormVisible4.value = true
        tan_type.value = 1
        pay_alid.value = item.id
        pay_al_type.value = 2
    }
}

const playlive = () => {
    if (zhibourl.value !== '') {
        player.value = new Player({
            id: 'mse',
            autoplay: true,
            fluid: true,
            url: zhibourl.value,
        })
        zhibotype.value = 3
    } else {
        ElMessage({message: '暂未开播', type: 'error'})
    }
}

const albumvideo = (item) => {
    if (item.is_member_expire !== 1) {
        albumtype.value = 2
        fun_getAlbumVideoList(item.id, 1, 10)
    } else {
        dialogFormVisible4.value = true
        tan_type.value = 2
        pay_alid.value = item.id
        pay_al_type.value = 2
    }
}

const video_info = (val) => {
    document.getElementById('image_mse').style.display = 'none';
    document.getElementById('mse_video').style.display = 'block';

    player.value = new Player({
        id: 'mse_video',
        autoplay: true,
        fluid: true,
        url: val,
    })
}

// const album_collection = (val) => {
//     addFavorite({
//         table_name: 'album',
//         object_id: val
//     }).then(res => {
//         if (res.code === 1) {
//             is_collection.value = 1
//         } else {
//             is_collection.value = 2
//         }
//     })
// }

const album_collection = (val,type,item,typestatus) => {
    addFavorite({
        table_name: typestatus,
        object_id: val
    }).then(res => {
        if (res.code === 1) {
            if(type === 2){
                item.count_like = 1
            }else{
                is_collection.value = 1
            }
        } else {
            if(type === 2){
                item.count_like = 2
            }else{
                is_collection.value = 2
            }
        }
    })
}

const del_collection = (val,type,item,typestatus) => {
    delFavorite({
        table_name: typestatus,
        object_id: val
    }).then(res => {
        if (res.code === 1) {
            if(type === 2){
                item.count_like = 2
            }else{
                is_collection.value = 2
            }
        } else {
            if(type === 2){
                item.count_like = 1
            }else{
                is_collection.value = 1
            }
        }
    })
}

const handleMouseEnter_video = (id) => {
    dialogFormVisible2.value = true
    video_id.value = id
}

// const del_collection = (val) => {
//     delFavorite({
//         table_name: 'album',
//         object_id: val
//     }).then(res => {
//         if (res.code === 1) {
//             is_collection.value = 2
//         } else {
//             is_collection.value = 1
//         }
//     })
// }

const introduce = (val) => {
    document.getElementById('introduce').style.display = 'block';
    document.getElementById('directory').style.display = 'none';
    document.getElementById('comment').style.display = 'none';
    document.getElementById('jieshao').style.background = '#0D67BB';
    document.getElementById('mulu').style.background = '#0B3D6C';
    document.getElementById('pinglun').style.background = '#0B3D6C';
}
const directory = (val) => {
    document.getElementById('introduce').style.display = 'none';
    document.getElementById('directory').style.display = 'block';
    document.getElementById('comment').style.display = 'none';
    document.getElementById('jieshao').style.background = '#0B3D6C';
    document.getElementById('mulu').style.background = '#0D67BB';
    document.getElementById('pinglun').style.background = '#0B3D6C';
}
const comment1 = (val) => {
    document.getElementById('introduce').style.display = 'none';
    document.getElementById('directory').style.display = 'none';
    document.getElementById('comment').style.display = 'block';
    document.getElementById('jieshao').style.background = '#0B3D6C';
    document.getElementById('mulu').style.background = '#0B3D6C';
    document.getElementById('pinglun').style.background = '#0D67BB';
}

const deletePost = (item) => {
    delPost({
        category_type: item.category_type,
        id: item.id
    }).then(res => {
        if (res.code === 1) {
            ElMessage({message: res.msg, type: 'success'})
            getNewList3('rem')
        }
    })
}
// 更新帖子
const updatePost = (item) => {
    let files = []
    for (let i = 0; i < item.file_data.length; i++) {
        files.push(item.file_data[i].url)
    }
    item.file_data = files
    emit('updateContent', item)
}

const expand = (index, el) => {

    if (postContents.value[index].classList.contains('textinfo')) {
        postContents.value[index].classList.remove('textinfo')
        el.textContent = "收起";
    } else {
        postContents.value[index].classList.add('textinfo')
        el.textContent = "展开";
    }
}
const showTextInfo = async (index) => {
    await nextTick();
    let el = postContents.value[index]
    expandStates[index] = el.scrollHeight > el.offsetHeight
}

const expand1 = (index, el) => {

    if (postContents1.value[index].classList.contains('textinfo')) {
        postContents1.value[index].classList.remove('textinfo')
        el.textContent = "收起";
    } else {
        postContents1.value[index].classList.add('textinfo')
        el.textContent = "展开";
    }
}
const showTextInfo1 = async (index) => {
    await nextTick();
    let el = postContents1.value[index]
    if (el) {
        expandStates1[index] = el.scrollHeight > el.offsetHeight
    }
}

const getOpacity = (index) => {
    return index === 2 ? 0.2 : 1;
}

const openPreview1 = (index,ima) => {
    // images11.value = ima
    // showPreview1.value = true;
    // emit('openPreview', ima)
    emit('openPreview', {index, ima})
}

const closePreview1 = () => {
    showPreview1.value = false;
}

const filetype = (fileName) => {
    const extension = getFileExtension(fileName);
    switch (extension.toLowerCase()) {
        case 'mp3':
            return 1;
        case 'mp4':
            return 2;
        case 'jpg':
            return 3;
        case 'png':
            return 3;
        case 'webp':
            return 3;
        case 'jpeg':
            return 3;
        case 'gif':
            return 3;
        default:
            return 4;
    }
}

const getFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex !== -1) {
        return fileName.slice(lastDotIndex + 1);
    } else {
        return '';
    }
}

const getCategoryLists1 = () => {
    systemConfig().then(res => {
        channels_id = res.data.web_qa_community_channels_id
    })
    keyword_con.value = props.con
}

const transport = (comment) => {
    inputtype.value = 4
    comment.transports = true
}

const closetransport = (comment) => {
    comment.transports = false
}

const sendMessageResult = (event) => {
    if (event) {
        getNewList3('rem')
    }
}

// 跳转详情
const jumpDetails = (item) => {
    const newObj = (({category_type, comment_type, id, table_name}) => ({
        category_type,
        comment_type,
        id,
        table_name
    }))(item);
    const content = toBase64(JSON.stringify(newObj))
    if (item.channel_pid == 5) {
        const routeUrl = router.resolve({
            path: "/home/newDetails",
            query: {id: item.id, type: props.typeView, content: content}
        });
        window.open(routeUrl.href, "_blank");
    } else {
        const routeUrl = router.resolve({
            path: "/home/forumDetails",
            // query: {id: item.id, comment_type:item.comment_type}
            query: {id: item.id, type: item.category_type, comment_type: item.comment_type,status: '1'}
        });
        window.open(routeUrl.href, "_blank");
    }
}
// 子级评论打开图标
const beforeIconChild = (a, b) => {
    from.index = a
    from.indexChild = b
}
// 子级评论选择表情

// 父级评论选择表情
const getIcon = (e) => {
    from.list[from.index].text += e
}
const getIcon2 = (e) => {
    if(plateList.value[from.index].text == undefined){
        plateList.value[from.index].text = e
    }else{
        plateList.value[from.index].text += e
    }
}
const getIcon1 = (e) => {
    comment.value += e
}
// 收藏
const collect = (id, status, item) => {
    if (status == 1) {
        delFavorite({table_name:item.table_name, object_id: id})
            .then(res => {
                if (res.code === 1) {
                    item.is_myfavorite = 0
                    ElMessage({message: '操作成功', type: 'success'})
                    getNewList3('rem',keyword_con.value)
                }
            })
    } else {
        addFavorite({table_name:item.table_name, object_id: id})
            .then(res => {
                if (res.code === 1) {
                    item.is_myfavorite = 1
                    ElMessage({message: '操作成功', type: 'success'})
                    getNewList3('rem',keyword_con.value)
                }
            })
    }
}

// 父级发送评论
const sendCommentVal = (id, text, item) => {
    let content = text
    // if(content.length > 140){
    //     ElMessage({message: '字数不能超过140', type: 'error'})
    //     return
    // }
    if (item.comment_image && item.comment_image.length > 0) {
        for (let i = 0; i < item.comment_image.length; i++) {
            content += `![Image Description](${item.comment_image[i]})`
        }
    }
    addComment({type:item.comment_type, aid: id, pid: 0, content, top_pid: 0})
        .then(res => {
            if (res.code === 1) {
                item.text = ''
                item.comment_image = ''
                res.data.isOpen = false
                res.data.text = false
                res.data.page = 1
                res.data.childernComment = []
                res.data.user.username = res.data.user.nickname
                item.commentList.push(res.data)
                ElMessage.success(res.msg)
            }
        })
}
const delimg = (item,e) => {
    item.splice(e, 1);
}

const sendCommentVal1 = (id, text) => {
    let type = 'album'
    let content = text
    if(content.length > 140){
        ElMessage({message: '字数不能超过140', type: 'error'})
        return
    }
    if (comment_image.value && comment_image.value.length > 0) {
        for (let i = 0; i < comment_image.value.length; i++) {
            content += `![Image Description](${comment_image.value[i]})`
        }
    }
    addComment({type, aid: id, pid: 0, content: content, top_pid: 0})
        .then(res => {
            if (res.code === 1) {
                comment = ''
                res.data.isOpen = false
                res.data.text = false
                res.data.page = 1
                res.data.childernComment = []
                res.data.user.username = res.data.user.nickname
                get_getComment(id,10,1)
            }
        })
}

const get_getComment = (aaa, bbb, ccc) => {
    getComment({
        aid: aaa,
        type: 'album',
        limit: bbb,
        page: ccc
    }).then(res => {
        if (res.code === 1) {
            let data = res.data.list
            commentdata.value = data
            commentnum.value = res.data.total
            whichpage.value = res.data.current_page
        }
    })
}

// 关键字搜索
const getNewList3 = (type,keny) => {
    if (type === 'rem') {
        from.list = []
        from.page = 1
        noMore.value = false
    }
    if (noMore.value) return
    loading.value = true
    zhibotype.value = 1
    albumtype.value = 1
    searchtype.value = 2
    if(keny != ''){
        searchList({
            page: from.page,
            limit: 20,
            keyword: keny,
            time: props.create_time ? props.create_time : 0,
        }).then(res => {
            if (res.code === 1) {
                albumList.value = res.data.albumList
                investmentList.value = res.data.investmentList
                introduceList.value = res.data.introduceList
                liveList.value = res.data.liveList
                videoList.value = res.data.videoList
                let data = res.data.plateList
                const regex = new RegExp('img', 'gi')
                for (var i = 0; i < res.data.plateList.length; i++) {
                    data[i].post_content = res.data.plateList[i].post_content
                        .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
                    data[i].post_content = res.data.plateList[i].post_content
                        .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
                }
                plateList.value = data
            }
        }).finally(() => {
            loading.value = false
        })
    }else{
        calendarTagList({
            page: from.page,
            limit: 20,
            year_month: props.datetime,
        }).then(res => {
            if (res.code === 1) {
                props.datetime = ''
                albumList.value = res.data.albumList
                investmentList.value = res.data.investmentList
                liveList.value = res.data.liveList
                videoList.value = res.data.videoList
                let data = res.data.plateList
                const regex = new RegExp('img', 'gi')
                if(res.data.plateList != []) {
                    for (var i = 0; i < res.data.plateList.length; i++) {
                        data[i].post_content = res.data.plateList[i].post_content
                            .replaceAll(new RegExp('style="[^"]*"', 'gi'), '')
                        data[i].post_content = res.data.plateList[i].post_content
                            .replace(regex, `img style="max-width:200px;float: left;"`) // 加上自己所需要的样式
                    }
                }
                plateList.value = data
            }
        }).finally(() => {
            loading.value = false
        })
    }

}
// 分享到QQ
const shareQQ = () => {
    var url = window.location.href;
    var encodedUrl = encodeURIComponent(url);
    var shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?url=' + encodedUrl;
    window.open(shareUrl);
}

// 分享到微博
const shareMicroBlog = () => {
    let message = String(location.href)
    var url = 'https://service.weibo.com/share/share.php?url=' + encodeURIComponent(message) + '&appkey=MrShi&title=测试标题&language=zh_cn'
    window.open(url)
}

//分享到微信
const shareWeChat = () => {
    var url = window.location.href;
    var encodedUrl = encodeURIComponent(url);
    var qrcodeUrl = 'https://login.weixin.qq.com?url=' + encodedUrl;
    window.open(qrcodeUrl)
};

const handleTextareaInput = (event) => {
    // Log changes when the textarea value changes
    if (event.target.value.includes('【') && event.target.value.includes('】')) {
    } else {
        event.target.value = '';
        inputtype.value = 3
    }
};
const imageContentResult2 = (e) => {
    loading.value = false;
    let str = e.type;
    plateList.value[str].comment_image = plateList.value[str].comment_image ?? [];
    if(plateList.value[str].comment_image.length < 3){
        plateList.value[str].comment_image.push(e.response);
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}
const imageContentResult = (e) => {
    loading.value = false;
    let str = e.type;
    if(comment_image.value.length < 3){
        comment_image.value.push(e.response)
    }else{
        ElMessage({message: '最多上传三张图片', type: 'error'})
    }
}

const handleImageSelected = (e) => {
    loading.value = true;
}

const handleMktoHtml = (e) => {
    return marked(e)
}
// const handleHide = () => {
//     images.value = []
//     visible.value = false
// }
const setPostContentRef = (el, index) => {
    if (el) {
        // 确保数组大小与 items 一致
        postContents.value[index] = el;
    }
};

const setPostContentRef1 = (el, index) => {
    if (el) {
        // 确保数组大小与 items 一致
        postContents1.value[index] = el;
    }
};

onMounted(() => {
    getCategoryLists1()
    getNewList3('rem',keyword_con.value)
})

onUnmounted(() => {
})

const setPostContentRef_one = (el, index) => {
    if (el) {
        // 确保数组大小与 items 一致
        postContents_one.value[index] = el;
    }
};
const expand_one = (index, el) => {
    if (postContents_one.value[index].classList.contains('textinfo')) {
        postContents_one.value[index].classList.remove('textinfo')
        el.textContent = "收起";
    } else {
        postContents_one.value[index].classList.add('textinfo')
        el.textContent = "展开";
    }
}
const showTextInfo_one = async (index) => {
    await nextTick();
    let el = postContents_one.value[index]
    if (el) {
        expandStates2[index] = el.scrollHeight > el.offsetHeight
    }
}

watchEffect(() => {
    // 每次 items 改变时，重新设置引用
    if (plateList.value.length > 0) {
        for (let i = 0; i < plateList.value.length; i++) {
            showTextInfo(i)
        }
    }
    if (investmentList.value.length > 0) {
        for (let i = 0; i < investmentList.value.length; i++) {
            showTextInfo1(i)
        }
    }

    if (introduceList.value.length > 0) {
        for (let i = 0; i < introduceList.value.length; i++) {
            showTextInfo_one(i)
        }
    }
});
const download = (e) => {
  downloadobj(e)
}
</script>
<style lang="scss" scoped>
#rich_text{
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.1em;
}
.file_style{
  text-decoration: none;
  color: #26c3dd;
  cursor: pointer;
}

.video-right-option {
  display: flex;
  position: absolute;
  top: 15px;
  right: 10px;
}

.deletePost {
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(0deg, #085EAF, #2791F4);
  border-radius: 4px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  cursor: pointer;
  width: 45px;
  margin-left: 10px;
}
.updatePost {
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(0deg, #085EAF, #2791F4);
  border-radius: 4px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  cursor: pointer;
  width: 45px;
}
.textinfo {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatIcons {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  margin-top: 18px;
  padding: 18px;
  box-sizing: border-box;

  .info {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .chatIcon_head {
    width: 100%;
    height: 30px;
    position: sticky;
    z-index: 9;
    background: #02183F;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chatIcon_head_left {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }

    .chatIcon_head_right {
      display: flex;
      align-items: center;

      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;

            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }

      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .chatIcon_operate {
    width: 100%;
    height: 30px;
    margin: 5px 0 17px 0;
    display: flex;
    align-items: center;
    position: sticky;
    background: #02183F;
    top: 30px;
    z-index: 9;

    .chatIcon_operate_w {
      width: 56px;
      height: 28px;
      font-size: 16px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
    }

    .chatIcon_operate_cate {
      flex: 1;
      margin-left: 30px;
      display: flex;
      align-items: center;

      .active {
        background: linear-gradient(0deg, #085EAF, #2791F4) !important;
      }

      .chatIcon_operate_cate_item {
        width: 36px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        background: #0B3D6C;
        border-radius: 3px;
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 9px;
        cursor: pointer;
      }
    }

    .layout_content_picture_item_head_sele {
      flex-shrink: 0;
      width: 98px;
      height: 23px;
      background: rgba(13, 46, 100, 0.6);
      border-radius: 3px;
      border: 1px solid rgba(119, 198, 253, 0.3);
      padding: 0 11px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      &:hover {
        .sele_menu {
          height: 90px;
        }
      }

      .sele_menu::-webkit-scrollbar {
        width: 5px;
      }

      .sele_menu::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #5D6B82;
      }

      .sele_menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        overflow-y: scroll;
        border-radius: 5px;
        transition: .5s;
        z-index: 9;
        background-color: rgba(13, 46, 100, 0.6);

        .sele_menu_item {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;

          &:hover {
            background-color: rgba(2, 24, 63, 0.5);
          }
        }
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }

      img {
        width: 13px;
        height: 7px;
      }
    }
  }

  .chatIcon_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
}

.chatIcon_list_item {
  padding: 14px;
  background: rgba(13, 46, 100, 1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
  //cursor: pointer;

  &:last-child {
    margin-bottom: 0 !important;
  }

  .chatIcon_list_item_head {
    width: 100%;
    display: flex;
    align-items: center;

    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    .head_name {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      padding-right: 9px;
      margin-right: 9px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    .head_time {
      font-size: 11px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .right-option {
    display: flex;
    position: absolute;
    top: 15px;
    right: 10px;

    .deletePost {
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 4px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      cursor: pointer;
      width: 45px;
      margin-left: 10px;
    }
    .zhuanicon {
      font-family: Source Han Sans CN;
      width: 23px;
      height: 23px;
      margin-left: 10px;
      cursor: pointer;
      background: #6ca9f1;
      color: #FFFFFF;
      text-align: center;
      border-radius: 3px;
    }
  }

  .chatIcon_list_item_info::v-deep {
    width: 100%;
    margin-top: 10px;

    img {
      width: 100%;
    }

    video {
      width: 100%;
    }
  }

  .chatIcon_list_item_pj {
    width: 100%;
    margin-top: 10px;
    cursor: auto;

    .chatIcon_list_item_pj_item {
      width: 100%;
      padding: 8px 14px;
      box-sizing: border-box;
      background: #094074;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 9px;
      position: relative;

      &:last-child {
        margin-bottom: 0 !important;
      }


      .chatIcon_list_item_pj_item_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pj_item_top_user {
          display: flex;
          align-items: center;

          img {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            margin-right: 6px;
          }

          span {
            font-size: 9px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }

        .pj_item_top_time {
          display: flex;
          align-items: center;

          .pj_item_top_time_a {
            display: flex;
            align-items: center;
            padding-right: 6px;
            margin-right: 6px;
            cursor: pointer;
            border-right: 2px solid rgba(255, 255, 255, 0.3);

            img {
              width: 9px;
              height: 8px;
              margin-right: 3px;
            }

            span {
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FCB407;
            }
          }

          .pj_item_top_time_b {
            font-size: 9px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }

      .chatIcon_list_item_pj_item_bottom {
        margin-top: 6px;

        span {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 16px;

          &:first-child {
            color: #FFFFFF;
          }
        }
      }

      .chatIcon_list_item_pj_more {
        margin-top: 10px;
        font-size: 9px;
        color: #fff;
        cursor: pointer;
      }

      .chatIcon_list_item_pj_item_list {
        width: 100%;
        margin-top: 10px;
        padding-left: 20px;
        box-sizing: border-box;

        .chatIcon_list_item_pj_item_list_item {
          width: 100%;
          padding: 8px 0;
          box-sizing: border-box;
          background: #094074;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          position: relative;

          &:last-child {
            margin-bottom: 0 !important;
          }

          .chatIcon_list_item_pj_item_top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .pj_item_top_user {
              display: flex;
              align-items: center;

              img {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                margin-right: 6px;
              }

              span {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }

            .pj_item_top_time {
              display: flex;
              align-items: center;

              .pj_item_top_time_a {
                display: flex;
                align-items: center;
                padding-right: 6px;
                margin-right: 6px;
                cursor: pointer;
                border-right: 2px solid rgba(255, 255, 255, 0.3);

                img {
                  width: 9px;
                  height: 8px;
                  margin-right: 3px;
                }

                span {
                  font-size: 9px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FCB407;
                }
              }

              .pj_item_top_time_b {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }
          }

          .chatIcon_list_item_pj_item_bottom {
            margin-top: 6px;

            span {
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 16px;

              &:first-child {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
  .comment-image-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .chatIcon_list_item_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 17px;
    cursor: auto;

    .chatIcon_list_item_footer_left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .chatIcon_list_item_footer_left_item {
        flex: 1 1 calc(25% - 10px); /* 调整最后的减数（10px）以适应间隙 */
        margin: 5px; /* 可选，为元素添加一些外边距 */
        display: flex;
        justify-content: center;

        img {
          width: 15px;
          height: 14px;
          margin-right: 4px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }

    .chatIcon_list_item_footer_right {
      width: 195px;
      height: 23px;
      padding: 0 1px 0 13px;
      box-sizing: border-box;
      background: #EAF5FF;
      border-radius: 11px;
      margin-left: 21px;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .inputText {
        flex: 1;
        height: 100%;

        input::-webkit-input-placeholder {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #0361BA;
        }

        input {
          font-size: 9px;
          color: #222222;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0);
          border: none;
          outline: none;
        }
      }

      .caozuo {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        img {
          width: 13px;
          height: 13px;
          margin-right: 8px;
          cursor: pointer;
        }

        .caozuo_btn {
          width: 42px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background: linear-gradient(0deg, #085EAF, #2791F4);
          border-radius: 10px;
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.chatIcon_list_item_pj_item_pl {
  width: 100%;
  position: relative;

  .item_pl_content {
    width: 100%;

    textarea {
      width: 95%;
      border-radius: 30px;
      height: 15px;
      margin-top: 20px;
      padding: 6px 10px;
      resize: none;
      background: #ebf4fd;
      color: #709cd2;
    }

    textarea:focus {
      outline: none;
    }
  }

  .item_pl_caozuo {
    display: flex;
    position: absolute;
    bottom: 5%;
    right: 3%;

    .item_pl_caozuo_img {
      width: 21px;
      height: 21px;
    }

    .item_pl_caozuo_btn {
      width: 42px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 10px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

</style>
